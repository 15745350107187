@import "modules/_var";
@import "modules/_useful";



/* TABLE OF CONTENTS
-----------------------------------------------------------------------------*/

/*
	
	4. PAGE SPECIFIC
		4.1. PAGE SPECIFIC — ITEM CARD
		4.2. PAGE SPECIFIC — CART
			4.2.1. PAGE SPECIFIC — EMPTY CART
		4.3. PAGE SPECIFIC — ORDER
			4.3.1. PAGE SPECIFIC — ORDER BY CODE
			4.3.2. PAGE SPECIFIC — ORDER FORM
			4.3.3. PAGE SPECIFIC — ORDERS COMPLETED
			4.3.4. PAGE SPECIFIC — ORDER COMPLETED VIEWER
		4.4. PAGE SPECIFIC — FEEDBACK
		4.5. PAGE SPECIFIC — PRINT FORMS
		4.6. PAGE SPECIFIC — SEARCH RESULT
		4.7. PAGE SPECIFIC — REGISTRATION FORM
		4.8. PAGE SPECIFIC — FAQ
		4.9. PAGE SPECIFIC — COMPARE
			4.9.1. PAGE SPECIFIC — COMPARE PANEL
		4.10. PAGE SPECIFIC — AUTHORIZATION
		4.11. PAGE SPECIFIC — ALPHABETICAL CATALOG
		4.12. PAGE SPECIFIC — COLLECTIONS
		4.13. PAGE SPECIFIC — ACCOUNT SETTINGS
		4.14. PAGE SPECIFIC — CARTRIDGE
		4.15. PAGE SPECIFIC — CERTIFICATES DOWNLOAD
		4.16. PAGE SPECIFIC — CONTACTS
		4.17. PAGE SPECIFIC — ABOUT OM
		4.18. PAGE SPECIFIC — SECTORAL PROPOSALS
		4.19. PAGE SPECIFIC — SHOP ADRESSES
		4.20. PAGE SPECIFIC — CATALOG ORDER
		4.21. PAGE SPECIFIC — SPECIAL OFFERS
		4.22. PAGE SPECIFIC — MY CABINET
		4.23. PAGE SPECIFIC — INNER PAGES LINKS
		4.24. PAGE SPECIFIC — JUNCTION PAGES
		4.25. PAGE SPECIFIC — NOVELTIES
		4.26. PAGE SPECIFIC - BONUSFORM
		4.27. PAGE SPECIFIC - UNSUBSCRIBE
		4.28. PAGE SPECIFIC - PROMO ICON
		4.29. PAGE SPECIFIC - CUP
		4.30. PAGE SPECIFIC - ACTIONS
			4.30.1. PAGE SPECIFIC — ACTIONS — CART
			4.30.2. PAGE SPECIFIC — ACTIONS — ITEMS LIST
			4.30.3. PAGE SPECIFIC — ACTIONS — ITEM CARD
			4.30.4. PAGE SPECIFIC — ACTIONS — LIST
			4.30.5. PAGE SPECIFIC — ACTIONS — ITEM
			4.30.6. PAGE SPECIFIC — ACTIONS — COMPARING
			4.30.7. PAGE SPECIFIC — ACTIONS — ORDER BY CODE
			4.30.8. PAGE SPECIFIC — ACTIONS — NOVELTIES
		4.31. PAGE SPECIFIC - GUIDES
			4.31.1. PAGE SPECIFIC - GUIDE BLOCK — SUGGEST
			4.31.2. PAGE SPECIFIC - GUIDE BLOCK — GUIDES LIST SUPERCATEGORY
			4.31.3. PAGE SPECIFIC - GUIDE BLOCK — GUIDES LIST CATEGORY
			4.31.4. PAGE SPECIFIC - GUIDE BLOCK — ITEM CARD
			4.31.5. PAGE SPECIFIC - GUIDE BLOCK — ITEM LIST
		4.32 PAGE SPECIFIC - HOW TO BE OFFICEMAG CLIENT
		4.33. PAGE SPECIFIC - UNSUBSCRIBING
		4.34. PAGE SPECIFIC - EAIST
		4.35. PAGE SPECIFIC — SALE
		4.36. PAGE SPECIFIC — CONSIGNEE
		4.37. PAGE SPECIFIC - BONUS
		4.38. PAGE SPECIFIC — EMPLOYEES
			4.38.1. PAGE SPECIFIC — EMPLOYEES LIST
			4.38.2. PAGE SPECIFIC — EMPLOYEES ADD
		4.39. PAGE SPECIFIC — FAVORITES
			4.39.1. PAGE SPECIFIC — FAVORITES LISTS
			4.39.2. PAGE SPECIFIC — FAVORITES POPUP
		4.40. PAGE SPECIFIC - FURNITURE PLACEMENT DESIGN-PROJECT
		4.41. PAGE SPECIFIC - BUSINESSCLASS
		4.42. PAGE SPECIFIC - FURNITURE LIST
		4.43. PAGE SPECIFIC - SIDE EVENT
		4.44. PAGE SPECIFIC - LOGO EVENT
		4.45. PAGE SPECIFIC - INVITE TENDER
		4.46. ADDED TO CART MODAL
		4.47. BRAND RUBRICS

	5. PRINT STYLES
		5.1. PRINT STYLES — CART

	6. HELPERS

*/



/* 4. PAGE SPECIFIC
------------------------------------------------------------------------------*/

/* 4.1. PAGE SPECIFIC — ITEM CARD
------------------------------------------------------------------------------*/

.mainContentWide .contentWrapper {
	margin: 10px 0 0;
	position: relative;
}

.contentWrapper .relatedItems {
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
}

.itemInfo {
	margin: 0 calc($SIDE_WIDTH + $SIDE_MARGIN) 0 0;
}

.itemInfoPhotos {
	width: $SIDE_WIDTH;
	float: left;
	margin: 0 0 33px;
}
.itemInfoPhotos .main {
	position: relative;
	display: block;
	height: $SIDE_WIDTH;
	width: $SIDE_WIDTH;
}

.itemInfoPhotos .main #photosListLink:after {
	content: '';
	cursor: pointer;
	background-image: resolve('interface/play-button.png');
	height: height('interface/play-button.png');
	width: width('interface/play-button.png');
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: calc(- width('interface/play-button.png') / 2);
	margin-top: calc(- height('interface/play-button.png') / 2);
}

.itemInfoPhotos .main img {
	height: $SIDE_WIDTH;
	width: $SIDE_WIDTH;
	vertical-align: middle;
	object-fit: cover;
	object-position: 50% 50%;
}
.itemInfoPhotos .promoIcon {
	display: block;
	overflow: hidden;
	position: absolute;
	right: 0;
	text-indent: 100%;
	top: 0;
	white-space: nowrap;
}
.itemInfoPhotos .promoIcon.bestPrice { /* ^ DELETE THIS BLOCK (not used) */
	/*background: url('/img/misc/promo_bestPrice.png') no-repeat;*/
	height: 63px;
	width: 63px;
}

.itemInfoPhotos .thumbs {
	display: block;
	margin: 7px -10px 0 0;
}
.itemInfoPhotos .thumbs li {
	border: 1px solid $B_ACTION;
	display: inline-block;
	cursor: pointer;
	width: 47px;
	height: 47px;
	margin: 8px 8px 0 0;
	position: relative;
	vertical-align: top;
}
.itemInfoPhotos .thumbs li.active,
.itemInfoPhotos .thumbs li.active:hover {
	box-shadow: 0 0 5px #ffe293;
	border: 1px solid $B_ACTION__A;
	cursor: default;
}
.itemInfoPhotos .thumbs li:hover {
	border-color: $B_ACTION__A;
}
.itemInfoPhotos .thumbs li.last {
	margin-right: 0;
	width: 44px;
}
.itemInfoPhotos .play-video.png li.video:after { /* ^ DELETE THIS BLOCK (not used) */
	/*background: url('/img/play-video.png') 50% 50% no-repeat;*/
	content: '';
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.itemInfoPhotos .thumbs li .item {
	height: 47px;
	width: 47px;
}

.itemPhotoAttention {
	margin-top: 15px;
}
.itemPhotoAttention__info {
	padding: 5px 0;
}
.itemPhotoAttention .BubbleTrigger,
.itemPhotoAttention .pseudoLink {
	cursor: help;
}
.itemPhotoAttention .BubbleTrigger {
	white-space: nowrap;
}
.itemPhotoAttention .pseudoLink {
	color: $C_ACTION;
	font-size: 11px;
}
.itemPhotoAttention .BubbleContent {
	cursor: default;
	padding: 10px;
	width: 100%;
}
.itemPhotoAttention.fancy {
	color: #777;
	padding-left: 30px;
	padding-right: 10px;
	text-align: left;
	max-width: 500px;
}
.itemPhotoAttention.fancy .icon {
	margin-right: 7px;
	margin-left: -20px;
}

.itemInfoDetails {
	margin: 0 0 33px calc($SIDE_WIDTH + $SIDE_MARGIN);
}
.itemInfoDetails .Product__name {
	font-size: 21px;
	line-height: 25px;
	margin: 0 0 4px;
}
.itemInfoDetails .promoIconWrapper {
	margin: 10px 0;
}
.itemInfoDetails .order {
	float: left;
	width: 188px;
}
.itemInfoDetails .order .deliveryNotyficationContainer {
	margin-top: 3px;
}
.itemInfoDetails .info {
	font-size: 13px;
	margin: 0 0 0 200px;
}
.itemInfoDetails .info .itemActions {
	/*background-image: url(/img/icons/green_red_present_big.png);*/
	background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAUCAMAAABcfiZ7AAAAGFBMVEUAAAD8dXqQ0lv6io5ktyL/PEROmhLuHCXv/jldAAAAAXRSTlMAQObYZgAAAFBJREFUeNqljkEKgEAMxOpuZ+f/P3apNIJ4EXMoNLSQKKb20IwbW4ds9ilfcCU3ildWkYPfbYrsX8wKfzHZhoCRGCKfkEEYGYT5T5gII4OwE8z8BjIeLz4UAAAAAElFTkSuQmCC') no-repeat 0 0;
	cursor: pointer;
	margin: 0 0 5px;
	padding: 5px 0 0 22px;
}
.itemInfoDetails .info .collectionLink {
	background: url('/img/misc/tmp_brauberg.png') 0 50% no-repeat;
	display: block;
	margin: 0;
	padding: 10px 10px 10px 24px;
}
.itemInfoDetails .info p {
	line-height: 18px;
}

.itemInfoDetails .info .File {
	margin-bottom: 7px;
	padding-left: calc(width('interface/file--zipBig.png') + 7px);
}
.itemInfoDetails .info .File__icon {
	margin-top: 0;
	margin-right: 7px;
	position: absolute;
}
.itemInfoDetails .info .File__icon.zip {
	margin-left: calc(- width('interface/file--zipBig.png') - 7px);
}
.itemInfoDetails .info .File__info {
	color: $C_ADD;
	font-size: 11px;
	margin-top: 3px;
}

.itemInfo .productComingsoon {
	font-size: 14px;
}
.itemInfoDetails .price {
	clear: both;
	display: block;
	font-size: 18px;
	font-weight: bold;
	line-height: 22px;
	margin: 0 0 2px 0;
}
.itemInfoDetails .price .Price {
	font-size: 25px;
}
.itemInfoDetails .priceDiscount {
	background-color: #fcf9d1;
	padding: 5px 8px;
}
.itemInfoDetails .priceOld {
	display: block;
	font-weight: normal;
}
.itemInfoDetails .priceOld .Price {
	color: #777;
	font-size: 14px;
	text-decoration: line-through;
}
.itemInfoDetails .priceDiscountDescription {
	color: #c00;
	font-size: 11px;
}
.itemInfoDetails .priceDiscountDescription strong {
	display: block;
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 -7px;
}

.itemInfoDetails .Product__price .Price {
	font-size: 25px;
}
.itemInfoDetails .Product__price--initial .Price {
	font-size: 20px;
}

.itemInfoDetails .additional {
	color: #777;
	font-size: 11px;
}

.itemInfoDetails .additional .minOrder {
	margin-top: 7px;
	margin-bottom: 3px;
}

.itemInfoDetails .inStock, .itemInfoDetails .pzk, .itemInfoDetails .outOfStock {
	font-size: 11px;
}
.itemInfoDetails .specialBar {
	display: block;
	margin-bottom: 10px;
}
.itemInfoDetails .specialBar .code {
	margin: 0 8px 0 0;
	font-size: 11px;
	display: inline-block;
	line-height: 19px;
	vertical-align: baseline;
}

.itemInfoDetails .itemInteract {
	vertical-align: baseline;
	display: inline-block;
}

.itemInfoDetails .printLink {
	float: right;
	margin-top: 3px;
}

.itemInfoDetails .basket {
	margin: 15px 0 0 0;
	background-color: $B_LIGHT;
	border-radius: $BD_RAD;
	padding: 6px 10px 8px;
}
.itemInfoDetails .basket > em {
	color: $C_ADD;
	margin: 4px 0 0;
}
.itemInfoDetails .basket .furnitureKitNotice {
	color: #000;
	display: block;
	font-size: 11px;
	line-height: 15px;
	margin: 7px 0 0;
}
.itemInfoDetails .basketAlready {
	display: block;
	font-size: 11px;
	margin: 0 0 3px;
}

.itemInfoDetails .basket > em {
	display: block;
	font-size: 10px;
	margin: 2px 0 0;
}
.itemInfoDetails .info a {
	display: inline-block;
	margin: 10px 0 0;
}

.itemInfoDetails .basket strong {
	margin: 0 7px 0 0;
	font-size: 12px;
	color: #666;
}
.itemInfoDetails .basket .textCounter {
	margin: 0 5px 0 0;
}
.itemInfoDetails .basket .buyByPackages {
	margin: 5px 0 0 0;
	display: block;
	font-size: 11px;
	color: #333;
	line-height: 14px;
}
.itemInfoDetails .basket .buyByPackages strong {
	color: #333;
	display: inline;
	font-size: 11px;
	font-weight: bold;
	margin: 0;
}

.itemInfoDetails .basketSpecialOffer {
	background: #ffeab1;
}
.itemInfoDetails .basket--special {
	background: #ffeab1;
}
.itemInfoDetails .basket .specialOffer {
	font-size: 11px;
	margin-top: 7px;
}
.itemInfoDetails .basket .specialOffer__header {
	color: #e0141d;
	display: block;
	font-weight: bold;
	margin-bottom: 1px;
}
.itemInfoDetails .specialOffer__content {
	display: block;
	overflow: hidden;
}
.itemInfoDetails .specialOffer__item + .specialOffer__item {
	margin-top: 3px;
}
.itemInfoDetails .basket .specialOffer .Price {
	font-size: inherit;
	font-weight: normal;
}

/*
 * Устаревший функционал акций, предлагается удаление
 * 24.05.2013
 */
/*
.itemInfoDetails .itemActionsIcons {
	height: 24px;
}
.itemInfoDetails .itemActionsIcons li {
	height: 24px;
	width: 24px;
}
.itemInfoDetails .itemActionsIcons li.itemActionsHeader {
	line-height: 24px;
	margin: 0;
	width: auto;
}
.itemInfoDetails .itemActionsIcons li.printer {
	background: url(/img/misc/actions_icon1.png) 50% 50% no-repeat;
}
.itemInfoDetails .itemActionsIcons li.paper {
	background: url(/img/misc/actions_icon2.png) 50% 50% no-repeat;
}
*/
.itemInfoDetails .retailLink {
	color: #666;
	font-size: 11px;
}
.itemInfoDetails .retailLink .pseudoLink {
	color: $C_ACTION;

	&:hover {
		color: $BRAND__A;
	}
}

.itemInfoLinks {
	margin-top: 7px;
}
.itemInfoLinks__item {
	display: inline-block;
}
.itemInfoLinks__item + .itemInfoLinks__item {
	margin-left: 7px;
}

.itemInfoDetails .itemInfoLinks__item a {
	background-position: 0 50%;
	background-repeat: no-repeat;
	font-size: 13px;
	margin-top: 0;
	padding-top: 2px;
}

.itemInfoDetails .itemMountingLink a {
	background-image: resolve('interface/instrument_action_main.png');
	padding-left: 20px;

	&:hover {
		background-image: resolve('interface/instrument_brand_a.png');
	}
}

.itemInfoDetails .item3DDesignLink a {
	background-image: resolve('interface/3D_blue.png');
	padding-left: 20px;

	&:hover {
		background-image: resolve('interface/3D_red.png');
	}
}

.itemInfoDetails .countSelector {
	display: block;
	font-size: 12px;
	margin: 4px 0;
}
.itemInfoDetails .countSelector .fakeLink {
	border-bottom: 1px dotted $ACTION_MAIN;
	cursor: pointer;
	color: $ACTION_MAIN;
}
.itemInfoDetails .countSelector .fakeLink strong {
	margin: 0;
	font-size: 12px;
	color: inherit;
	font-weight: bold;
}

.itemInfoDetails .citySelector {
	background: #fcf9d1;
	padding: 7px 9px;
}
.itemInfoDetails .citySelector .citySelectInfo {
	display: block;
	color: #555;
	font-size: 13px;
	margin: 3px 0 0 0;
}
.itemInfoDetails .citySelector .pseudoLink {
	font-size: 18px;
}

.itemInfoDetailsRemoved span {
	color: #e10000;
	font-size: 14px;
	font-weight: bold;
}

.itemInfo .tabsContainer {
	clear: both;
}
.tabsContent .promo ul, .tabsContent .promo ul li {
	display: block;
	overflow: hidden;
}
.tabsContent .promo ul li {
	margin: 0 0 20px 0;
}
.tabsContent .promo .promoPhoto {
	float: left;
}
.tabsContent .promo h2 {
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 7px 0;
}
.tabsContent .promo p {
	line-height: 17px;
	margin: 0 0 6px 0;
}
.tabsContent .promo h2 a {
	color: #000;
}
.tabsContent .promo .showMore {
	display: block;
	margin: 5px 0 0 0;
}
.tabsContent .promo .promoDetails {
	margin-left: 145px;
}

.tabsContent .certificates {
	padding: 15px 0 0;
}
.tabsContent .certificates .download {
	margin: 0 0 15px;
}
.tabsContent .certificates .download span {
	font-weight: bold;
	margin: 0 0 0 -92px;
}
.tabsContent .certificates .download a {
	background: url('/img/icons/zip.png') no-repeat;
	display: block;
	float: left;
	font-size: 11px;
	padding: 19px 0 0 35px;
}
.tabsContent .certificates img {
	box-shadow: 0 3px 8px rgba(0,0,0,.3);
}

.tabsContent .kit ul {
	display: block;
	margin: 0;
	overflow: hidden;
}
.tabsContent .kit li {
	border-top: 1px solid #d8d8d8;
	display: block;
	font-size: 13px;
	line-height: 17px;
	padding: 10px 0;
	overflow: hidden;
}
.tabsContent .kit li:first-child {
	border-top: none;
}
.tabsContent .kit .listItemPhoto {
	float: left;
}
.tabsContent .kit .listItemPhoto img {
	height: 60px;
	width: 60px;
}
.tabsContent .kit .listItemInfo .code {
	font-size: 11px;
	margin: 3px 0 0;
}
.tabsContent .kit .listItemInfo {
	float: left;
	margin: 0 0 0 15px;
}
.tabsContent .kit .notice {
	background: #ededed;
	padding: 10px;
}
.tabsContent .kit .listItemAmount {
	float: right;
	font-weight: bold;
	margin: 0 0 0 15px;
	width: 60px;
}



/* 4.2. PAGE SPECIFIC — CART
------------------------------------------------------------------------------*/

.cartInfo {
	margin: 0 calc($SIDE_WIDTH + $SIDE_MARGIN) 0 0;
}

.cartInfo .ddTip {
	margin: 4px 0 20px;
}

.cartInfo + .relatedItems h2 {
	margin: 15px 0 17px;
}

.cartBonuses {
	font-size: 12px;
	margin: 0 0 30px;
}
.cartBonusesAvailable {
	background-color: #f9f9f9;
	border-top: 1px solid #e7e7e7;
	box-shadow: inset 0 5px 5px -5px rgba(0,0,0,.1);
	margin: 0 0 -1px;
}
.cartBonusesAvailable li {
	border: 1px solid #e5e5e5;
	border-top-color: #fff;
	padding: 10px;
	position: relative;
}
.cartBonusesAvailable li:first-child {
	border-top: none;
}
.cartBonusesAvailable .bonus {
	margin: 0 200px 0 0;
}
.cartBonusesAvailable .progress {
	color: #777;
	font-size: 11px;
	position: absolute;
	right: 10px;
	top: 10px;
}
.cartBonusesAvailable strong {
	font-weight: bold;
}
.cartBonusesObtained {
	background-color: #fcf9d1;
	border: 1px solid #e7e4bb;
	border-bottom: none;
	box-shadow: inset 0 5px 5px -5px rgba(0,0,0,.1);
	position: relative;
	z-index: 5;
}
.cartBonusesObtained .header {
	font-size: 14px;
	font-weight: bold;
	padding: 15px 10px 3px;
}
.cartBonusesObtained li {
	border-top: 1px solid #fff;
	border-bottom: 1px solid #e7e4bb;
	padding: 10px;
	white-space: nowrap;
}
.cartBonusesObtained li:first-child {
	border-top: none;
}
.cartBonusesObtained .description {
	display: inline-block;
	margin: 0 46px 0 0;
	vertical-align: top;
	white-space: normal;
}
.cartBonusesObtained .bonusIcon {
	border: 1px solid #ddd;
	display: inline-block;
	height: 36px;
	margin: 0 10px 0 0;
	vertical-align: top;
	width: 36px;
}
.cartBonusesObtained .bonus {
	display: block;
	margin: 0 0 3px;
}
.cartBonusesObtained .action {
	display: block;
	font-weight: bold;
}


.cartInfo .tabsContainer {
	font-size: 13px;
	margin-bottom: 0;
}

.listCartContainer .tabsList {
	margin-right: 70px;
}
.tabsContainer .printLink {
	display: block;
	float: right;
	margin: 10px 0;
}
.printLink a {
	background-image: resolve('interface/print_action_main.png');
	background-position: 0 50%;
	background-repeat: no-repeat;
	display: inline-block;
	min-height: height('interface/print_action_main.png');
	padding: 0 0 0 20px;

	&:hover {
		background-image: resolve('interface/print_brand_a.png');
	}
}


.listCartLabels {
	color: $C_ADD;
	margin: 0 0 5px;
	overflow: hidden;
}
.listCartLabels li {
	display: inline-block;
	float: right;
	width: 100px;
}
.listCartLabels li.item {
	float: left;
	padding: 0 0 0 35px;
	width: 100px;
}

.cartInfo .listCartLabels {
	border: 1px solid $B_ACTION;
	border-top-width: 0;
	border-bottom-width: 0;
	margin: 0;
	padding: 12px 0;
}
.cartInfo .listCartLabels li {
	box-sizing: border-box;
}
.cartInfo .listCartLabels li.item {
	padding-left: 15px;
}
.cartInfo .listCartLabels li.summ {
	padding-right: 15px;
	text-align: right;
	width: 95px;
}


.listCart > li {
	border: 1px solid $B_ACTION;
	border-bottom-width: 0;
	padding: 12px 15px 7px;
	position: relative;
	white-space: nowrap;
}

/*
.listCart > li:hover {
	background: #ffffef;
}
*/

.listCart > li:after {
	clear: both;
	content: '';
	display: block;
}
ul.listCart > li.choosed {
	background-color: $BG;
}
.listCart > li.itemProblem,
.listCart > li.notInCatalog,
.listCart > li.outOfStocked {
	background-color: #ffebeb;
}

.listCart .kit {
	margin: 12px 0 0 80px;
}
.listCart .kit h3 {
	font-size: 12px;
	font-weight: bold;
	margin: 0;
}
.listCart .kit ul {
	display: block;
	font-size: 12px;
	margin: 0;
	overflow: hidden;
}
.listCart .kit li {
	border-top: 1px solid #e5e5e5;
	display: block;
	overflow: hidden;
	padding: 8px 0;
}
.listCart .kit li:first-child {
	border-top: none;
}
.listCart .kit .kitInfo {
	float: left;
}
.listCart .kit .kitAmount {
	float: right;
	margin: 0 0 0 15px;
}
.listCart .kit .kitInfo strong {
	color: #777;
	display: block;
	font-size: 10px;
	margin: 2px 0 0;
}

.listCartItem {
	display: inline-block;
	margin: 0 300px 0 0;
	vertical-align: top;
	white-space: normal;
}
.listCartPrice {
	display: inline-block;
	float: right;
	margin: 0 0 0 -295px;
	position: relative;
	vertical-align: top;
	white-space: normal;
	width: 290px;
}
.listCartPrice .price {
	display: inline-block;
	margin: 0 7px 0 0;
	padding: 5px 0 0;
	vertical-align: top;
	width: 100px;
}
.listCartPrice .Price {
	font-size: 15px;
	font-weight: bold;
}
.listCartPrice .price .Price {
	font-weight: normal;
}
.listCartPrice .quantity {
	display: inline-block;
	vertical-align: top;
	width: 170px;
}
.listCartPrice .quantity .textCounter .Form__input {
	width: 4em;
}
.listCartPrice .quantity strong {
	color: #666;
	font-size: 12px;
	font-weight: normal;
}
.listCartPrice .additional {
	color: #777;
	font-size: 11px;
	margin: 3px 0 0;
}

.listCartPrice .additional .pzk {
	display: inline-block;
	font-size: 12px;
}

.listCartPrice .additional .pzk strong.pseudoLink:hover {
	color: #e30086;
	border-color: #f995d0;
}
.listCartPrice .additional .pzk strong.pseudoLink {
	color: #a7126a;
	cursor: pointer;
	font-weight: normal;
	border-bottom: 1px dotted #a7126a;
}

.listCartPrice .availability {
	font-size: 11px;
	margin-top: .6em;
	position: absolute;
	right: 0;
	top: 0;
	text-align: center;
	width: 95px;
}


.listItemBubble {
	background-color: #fff;
	border: 1px solid;
	border-radius: 5px;
	box-shadow: 0 5px 18px rgba(57,54,3,.55);
	color: #000;
	display: none;
	font-size: 12px;
	margin: -34px 0 0;
	padding: 10px;
	position: absolute;
	right: -225px;
	text-align: left;
	top: 20px;
	width: 201px;
	white-space: normal;
	z-index: 99;
}
.listItemBubble:before {
	content: '';
	display: block;
	position: absolute;
}

.listItemBubble.pzk {
	border-color: #d964a9;
	border-width: 2px;
	margin-top: -39px;
	z-index: 99;
}
.listCartPrice .availability span.pzk {
	cursor: help;
	display: block;
}
.listCartPrice .availability .pzk:hover ~ .listItemBubble.pzk,
.listCartPrice .availability .listItemBubble.pzk:hover {
	display: block;
}
.listItemBubble.pzk:before {
	/*background: url(/img/arrow_left_purple.gif) no-repeat;*/
	background: url('data:image/gif;base64,R0lGODlhCgAQAKIEAPjk8OKIvdlkqf///////wAAAAAAAAAAACH5BAEAAAQALAAAAAAKABAAAAMlSLokEky5AOIEg92R5cOcR3HhRg7mmZLNd3brkmpuJ7tRS+V6AgA7') no-repeat;
	height: 16px;
	left: -10px;
	margin: -8px 0 0;
	top: 50%;
	width: 10px;
}



.listItemBubble.relatedItem {
	background-color: #ffffef;
	border-color: #a6a26b;
	left: 100%;
	margin-top: 0;
	margin-left: 21px;
	top: 0;
	width: 198px;
}

.listItemBubble.relatedItem:before {
	/* background: url(/img/bubble_related_arrow_15_9.png) no-repeat; */
	background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAPCAMAAAABFhU/AAAAElBMVEUAAADLyKTLyKWno3L+/uz+/u2dq+zEAAAAAXRSTlMAQObYZgAAACxJREFUeNpdyLENACAMxMCH4P1XBiLkAlcn51VTIBAIBILG/w7HukVGRkZGbllLASyEjiDSAAAAAElFTkSuQmCC') 100% 20px no-repeat;
	height: 100px;
	right: 100%;
	top: 0;
	width: 21px;
}

.listItemBubble.relatedItem.loading {
	background-image: url('/img/loader_000_f9f8e7_48.gif');
	background-position: 50% 50%;
	background-repeat: no-repeat;
	min-height: 60px;
}

.listItemBubble.relatedItem .basketAlready {
	display: none;
}

.listItemBubble .relatedItemsHeader {
	color: #000;
}


.listCartPrice .summ {
	padding: 5px 0 0;
	position: absolute;
	right: 0;
	top: 0;
}
.listCartPrice .summ strong {
	font-weight: bold;
}
.listCartPrice .listItemRemoved {
	margin-top: 4px;
	white-space: nowrap;
}
.listCartPrice .productComingsoon {
	margin-top: 2px;
	white-space: nowrap;
}
.listCartPrice .itemAlternative {
	display: inline-block;
	font-size: 12px;
}
.listCartItemChoose {
	float: left;
}
.listCartItemChoose .choose {
	margin: 0 5px 0 0;
	vertical-align: middle;
}
.listCartItemChoose img {
	background-color: #fff;
	height: 40px;
	vertical-align: middle;
	width: 40px;
}
.listCartItemInfo {
	margin: 0 0 0 73px;
}
.listCartItemInfo .name {
	color: $C_ACTION;
	font-size: 13px;
	line-height: 18px;
	padding: .3em 0;
}
.listCartItemInfo .name:hover {
	color: $BRAND__A;
}
.listCartItemInfo .code__wrapper {
	font-size: 11px;
	margin-top: 5px;
	margin-bottom: 5px;
}
.listCartItemInfo .promoIconWrapper {
	margin-top: -5px;
	margin-bottom: 3px;
}
.listCartItemInfo .code {
	margin-right: .7em;
}


.listCartControl .change,
.ControlBar {
	background-color: $BG;
	padding: 15px;
}

.listCartControl .change {
	border: 1px solid $B_ACTION;
	border-bottom: none;
}

.listCartControl .change label {
	color: $C_ACTION;
	display: inline-block;
	line-height: 12px;
	margin: 0px 15px 0px 0px;
	vertical-align: middle;
}

.listCartControl .change label input {
	vertical-align: top;
}

.listCartControl .change label span {
	display: inline-block;
	margin: 0 0 0 5px;
}

.listCartControl .change .btn {
	margin: 0 12px 0 0;
}
.listCartControl .change .recalculate {
	float: right;
	margin: 0;
}
.listCartControl .change .addFromCart {
	float: right;
	margin-right: 10px;
}
.listCartControl .addToFavoritesList {
	display: inline-block;
	position: relative;
	vertical-align: top;
}
.listCartControl .addToFavoritesList .favoritesPopup {
	color: #000;
	left: 0;
	top: 29px;
}
.listCartControl .favoritesPopup h4,
.listCartControl .favoritesPopup .closePopup {
	display: none;
}
.listCartControl .pay {
	background-color: $BG;
	border: 1px solid $B_ACTION;
	box-sizing: border-box;
	display: inline-block;
	padding: 15px;
	padding-bottom: 12px;
	width: 100%;
}

.cartDelivering {
	display: inline-block;
	margin-right: 20px;
	padding-right: 20px;
	text-align: left;
	vertical-align: middle;
}
.cartDeliveringText {
}
.cartDeliveringText.free {
	color: #3e791e;
}
.cartDeliveringText.paid {
	color: #b42323;
}

.Bubble.deliveringInfo {
	display: inline-block;
}
.Bubble.deliveringInfo .BubbleContent {
	color: #000;
	font-size: 12px;
	left: 50%;
	margin-left: -110px;
	padding: 10px;
	width: 220px;
}
.deliveringInfo .header {
	color: #b42323;
	font-weight: bold;
}
.deliveringInfo .description {
	display: block;
	margin-top: .5em;
	margin-bottom: .5em;
}

.listCartControl .total {
	display: inline-block;
	float: right;
	margin-top: -5px;
	margin-bottom: -5px;
	text-align: left;
	vertical-align: middle;
	text-align: right;
}

.listCartControl .total > span {
	font-size: 18px;
	font-weight: bold;
}
.listCartControl .total span.price {
	font-size: 16px;
}
.listCartControl .price strong {
	font-size: 18px;
	font-weight: bold;
}
.listCartControl .pay em {
	color: $C_ADD;
	display: block;
	font-size: 11px;
	margin-top: 1px;
}
.listCartControl .purchase {
	background-color: $BG__A;
	border: 1px solid $BG__A;
	border-radius: 0 0 $BD_RAD $BD_RAD;
	text-align: right;

	&__box {
		box-sizing: border-box;
		padding: 14px;
		width: 100%;
	}
}
.listCartControl .purchase__box + .purchase__box {
	border-top: 1px solid #e2ddd9;
}
.listCartControl .purchase__box---submit .btn {
	font-size: 15px;
	font-weight: bold;
	height: 36px;
}
.listCartControl .purchaseProposal__info {
	color: #404040;
	margin-right: 1em;
}
.listCartControl .purchaseProposal__count {
	color: #c22119;
	font-weight: bold;
	margin-left: .3em;
}
.listCartControl .furnitureSetsWarning {
	background-color: $BG;
	border: 1px solid $B_ACTION;
	border-top-width: 0;
	border-bottom-width: 0;
	padding: 15px;
	text-align: right;
}

.listCartControl .toShowRelated {
	color: #555;
	float: left;
}
.listCartControl .toShowRelated input[type="checkbox"] {
	margin-top: -.2em;
	vertical-align: middle;
}

.listCartControl .linkOrderCompleted {
	float: left;
	font-size: 12px;
	text-align: left;
	width: 290px;
	white-space: nowrap;
}
.listCartControl .linkOrderCompleted .icon {
	float: left;
	margin-top: -.5em;
	margin-right: .5em;
}
.listCartControl .linkOrderCompleted .text {
	display: block;
	margin-left: 33px;
}



/* 4.2.1. PAGE SPECIFIC — EMPTY CART
------------------------------------------------------------------------------*/

.emptyCartInfo {
	text-align:center;
	margin:60px 0 30px;
}
.emptyCartInfo h1 {
	color:#777;
	font-size:30px;
	margin:0 0 11px;
}



/* 4.3. PAGE SPECIFIC — ORDER
------------------------------------------------------------------------------*/

/* 4.3.1. PAGE SPECIFIC — ORDER BY CODE
------------------------------------------------------------------------------*/

.orderByCodeContainer {
	margin-top: .5em;
}
.orderByCodeContainer .pseudoLink {
	font-size: 12px;
}

.orderByCodeList {
	box-sizing: border-box;
	display: inline-block;
	margin: 1em 0;
	width: 100%;
}
.orderByCodeList li {
	clear: left;
	min-height: 33px;
}
.orderByCodeList li + li {
	padding-top: 14px;
}

.orderByCodeList .errorMessage,
.orderByCodeList .item .title {
	display: block;
	margin-left: 202px;
}

.orderByCodeList .item .title {
	color: #222;
	font-size: 12px;
	line-height: 16px;
}

.orderByCode__titleCode,
.orderByCodeList li .codeInput {
	margin-right: 3px;
	width: 75px;
}
.orderByCode__titleCode {
	display: inline-block;
}

.orderByCodeList .item {}
.orderByCodeList .itemIcon {
	background: url('/img/order-by-code-status.png') 50% 8px no-repeat;
	border: 1px solid transparent;
	border-radius: $BD_RAD;
	box-sizing: border-box;
	display: block;
	float: left;
	height: 32px;
	margin: 0 10px;
	width: 32px;
}
.orderByCodeList .item .iconError {
	background-position: 50% -24px;
	border-color: $ERROR;
}
.orderByCodeList .item .iconLoading {
	background: url('/img/loader.gif') no-repeat;
	margin-top: -2px;
}
.orderByCodeList .item .photo {
	position: relative;
}
.orderByCodeList .item .photo img {
	display: block;
	height: 32px;
	width: 32px;
}
.orderByCodeList .item .status {
	display: block;
}
.orderByCodeList .item .price__wrapper {
	display: block;
	font-size: 13px;
}
.orderByCodeList .item .price {
	font-weight: bold;
	margin-right: .4em;
}
.orderByCodeList .Product__price--initial,
.orderByCodeList .Product__specialCondition {
	font-weight: normal;
}

/*
 * Не используется,
 * нужно использовать .title вместо этого
 */
/*
.orderByCodeList .item a {
	color: #222;
	display: block;
	font-size: 12px;
	line-height: 16px;
	margin: 6px 0 0 202px;
}
*/

.orderByCodeList .errorMessage .errorMessage__header {
	font-weight: bold;
}
.orderByCodeList .errorMessage .errorMessage__info {
	color: #666;
	display: block;
	font-size: 11px;
}
.orderByCodeList .errorMessage .errorMessage__info a {
	color: $ACTION_MAIN;
	display: inline;
	font-size: inherit;
	line-height: normal;
	margin: 0;
}
.orderByCodeList .errorMessage.furniture {
	clear: both;
	display: block;
	margin-top: 7px;
}

.orderByCodeList .basket strong {
	color: #666;
	font-size: 12px;
	margin: 0 0 0 4px;
}
.orderByCodeList .basket {
	float: left;
}



/* 4.3.2. PAGE SPECIFIC — ORDER FORM
------------------------------------------------------------------------------*/

.orderForm {
	position: relative;
}
.orderForm fieldset {
	background-color: #f3f3f3;
	font-size: 14px;
	padding: 15px 18px;
	margin: 0 0 10px;
	width: 56%;
}
.orderForm .submitForm {
	background: none;
	height: 49px;
	margin: -10px 0 0;
	padding: 0 18px;
	position: relative;
}
.orderForm fieldset .labelContainer {
	text-align: right;
}
.orderForm fieldset h1 {
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 17px;
}
.orderForm fieldset h1 span {
	color: #888;
	font-size: 12px;
}

.orderForm fieldset .charsLeft {
	display: block;
	color: #777;
	font-size: 13px;
	margin: 5px 0 0;
}
.orderForm fieldset .charsLeft.colorError {
	color: #ee1c25;
}

.orderForm .labelContainer label {
	display: block;
	margin: 6px 0 0;
	width: 518px;
}
.orderForm .labelContainer label:first-child {
	margin: 0;
}
.orderForm label .errorMessage {
	color: #EE1C25;
	display: block;
	font-size: 11px;
	margin: 0 0 0 168px;
	text-align: left;
}
.orderForm .errorInput {
	border: 1px solid #EE1C25;
	width: 353px;
}
.orderForm .errorInput .errorMessage {
	display: block;
	color: #EE1C25;
	font-size: 11px;
	left: 167px;
	position: relative;
	text-align: left;
	top:1px;
}
.orderForm span.errorMessage{
	color: #EE1C25;
	display: block;
	font-size: 11px;
	margin: 0 0 0 168px;
	text-align: left;
}
.orderForm .labelContainer input {
	margin: 0 0 0 4px;
	width: 350px;
}
.orderForm fieldset select {
	font-size: 16px;
	width: 100%;
}
.orderForm .linear h1 {
	display: inline-block;
	margin: 0;
}

.orderForm .linear label {
	margin: 0 0 0 10px;
}
.orderForm fieldset .m-b-05,
.orderForm .linear .m-b-05 {
	margin-bottom: .75em;
}

.orderForm .linear input[type="radio"] {
	position: relative;
	top: 1px;
}
.orderForm strong {
	font-weight: bold;
}
.orderForm textarea {
	height: 75px;
	width: 100%;
}
.orderForm .orderBack, .orderForm .orderAccept {
	bottom: 0;
	position: absolute;
}
.orderForm .orderBack {
	color: #000;
	left: 0;
}
.orderForm .orderAccept {
	font-size: 14px;
	font-weight: bold;
	right: 0;
}

.orderForm div.orderInfo {
	height: 100%;
	position: absolute;
	right: 0;
	width: 39%;
	margin-top: 0;
}
.orderInfo .block {
	margin: 0 0 15px;
}
.orderInfo .block h1 {
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 4px;
}
.orderInfo .block span {
	display: block;
	line-height: 1.5em;
}

.orderInfo p {
	color: #777;
	font-size: 12px;
	padding: 2px 0 0;
}

.orderForm .propsField {
	border-bottom: 1px solid #e2e2e2;
	margin: 0;
	position: relative;
}

.orderForm .propsField .loader {
	background: url('/img/loader.gif') 50% 50% no-repeat rgba(0, 0, 0, 0.1);
	display: none;
	height: 100%;
	margin: 0;
	left: 0px;
	position: absolute;
	top: 0px;
	width: 100%;
}

.orderForm .propsField .labelContainer {
	margin-bottom: 10px;
}
.orderForm .propsField.header {
	padding: 13px 18px 8px;
}
.orderForm .propsCheck {
	color: #777777;
	display: block;
	font-size: 18px;
	left: -5px;
	margin: -5px 0 15px;
	position: relative;
}
.orderForm fieldset.checked {
	background-color: #fcf9d1;
}

.orderForm .oldProps li {
	margin: 0 0 5px;
}
.orderForm .oldProps span {
	display: inline-block;
	margin: 0 5px 0 0;
	text-align: right;
	width: 154px;
}

.orderForm .labelsBlock {
	display: inline-block;
	margin-top: 0;
	vertical-align: top;
	width: auto;
}
.orderForm .labelsBlock label {
	display: block;
	margin-top: .75em;
	width: auto;
}
.orderForm .labelsBlock label:first-child {
	margin-top: 0;
}

.orderForm div {
	margin: 6px 0 0;
}
.orderForm .m-t-0 {
	margin-top: 0;
}
.orderForm div.phone label {
	width: auto;
	display: inline-block;
}

.orderForm div.phone {
	margin: 6px 0 0;
	text-align: left;
}
.orderForm div.phone label {
	display: inline-block;
}

.orderForm div.phone label span {
	display: inline-block;
	margin: 0 7px 0 0;
	text-align: right;
	width: 160px;
}

.orderForm .phone .registrationPhoneNumber {
	width: 100px;
}
.orderForm .phone .registrationPhoneCode {
	width: 45px;
}
.orderForm .phone .registrationPhoneNumber.mixed {
	margin-left: 0;
	width: 169px;
}


/* проблема со специфичностью стилей */
.orderForm .Bubble,
.orderForm .Bubble div {
	margin-top: 0;
}

.orderForm .informerWrapper {
	position: relative;
}
.orderForm .informer {
	box-sizing: border-box;
	background-color: #fcf9d1;
	display: block;
	font-size: 13px;
	padding: .6em;
}
.orderForm .informerWrapper .informer {
	left: 102%;
	position: absolute;
	top: 0;
	width: 60%;
}


.orderForm .OldProps__item + .OldProps__item {
	margin-top: 12px;
}
.orderForm .OldProps__caption {
	display: inline-block;
	float: left;
	margin: 0 5px 0 0;
	text-align: right;
	width: 154px;
}
.orderForm .OldProps__caption + .OldProps__valueContainer .OldProps__field {
	margin-top: -4px;
}
.orderForm .OldProps__valueContainer {
	display: block;
	margin-left: 160px;
}
.OldProps__value {
	font-weight: bold;
}
.OldProps__contract {
	display: block;
	color: #777;
	font-size: 12px;
	margin-top: 3px;
}
.OldProps__changeLink {
	user-select: none;
}

.orderForm .address label {
	display: block;
	margin-top: 15px;
	margin: 10px 0 0;
	white-space: nowrap;
	width: 518px;
}
.orderForm .address .variousInputs {
	display: inline-block;
	width: auto;
}
.orderForm .address span,
.orderForm .address input {
	vertical-align: middle;
	width: auto;
}
.orderForm .address .full {
	width: 500px;
}
/* SHAME каскад каскадирует */
.orderForm label .BubbleContent,
.orderForm label .BubbleContent span {
	text-align: left;
}
.orderForm .address label span {
	text-align: left;
	white-space: normal;
}

.orderForm .important {
	color: #e0141d;
}

.orderForm .delivery label span {
	display: inline-block;
	margin: 0 7px 0 0;
	text-align: right;
	width: 160px;
}
.orderForm .delivery .labelsBlock span,
.orderForm .delivery .labelsBlock input {
	vertical-align: middle;
	width: auto;
}
.orderForm .delivery label.date span {
	vertical-align: top;
}


.thanksForBuy {
	margin: 0 auto 58px;
	text-align: center;
	width: 70%;
}
.thanksForBuy h1 {
	font-size: 29px;
	line-height: 1.4em;
}
.thanksForBuy p {
	color: #666;
	margin: 0 0 4px;
}
.thanksForBuy strong {
	font-weight: bold;
}
.thanksForBuy span {
	color: #000;
	display: block;
	font-size: 16px;
	line-height: 20px;
	margin: 5px 0 10px;
}

#order_form_div .fieldsNotice em {
	color: #e0141d;
}


.Interview.onCheckout {
	margin: -30px auto 58px;
}


.checkoutPoll{
	background-color: #f7f5f3;
	width: 70%;
	padding: 25px 0;
	margin: -30px auto 58px;
}

.checkoutPoll h2 {
	font-size: 20px;
	color: #000;
	padding: 0 75px;
	text-align: center;
	padding-bottom: 20px;
	border-bottom: 1px solid #e0d5ca;
}

.checkoutPoll span {
	box-shadow: 0 1px 0 #f8f8f8;
	font-size: 12px;
	display: block;
	line-height: 17px;
	padding: 0;
	margin: 0;
}

.checkoutPoll dl{
	display: block;
}
.checkoutPoll .vote-form {
	margin: 0 120px;
}

.checkoutPoll dt{
	border-bottom: 0;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 1em;
	margin-top: 2em;
	padding-bottom: 0;
}

.checkoutPoll dd{
	display: block;
	margin: .5em 0 0 0;
}

.checkoutPoll dd ul{
	display: block;
}

.checkoutPoll dd ul li{
	display: block;
	margin: .5em 0 0;
}

.checkoutPoll dd ul li input[type="text"],
.checkoutPoll select,
.checkoutPoll textarea {
	margin-top: .3em;
	background-color: #fff;
	border: 1px solid #e2ddd9;
	border-radius: $BD_RAD;
	box-sizing: border-box;
	font: inherit;
	font-size: 13px;
	height: 32px;
	line-height: 1.3;
	padding: .5em 15px;
	width: 360px;
}
.checkoutPoll textarea {
	height: auto;
}

.checkoutPoll input[type="text"] {
	width: 300px;
}
.checkoutPoll input[type="radio"] {
	vertical-align: middle;
	margin-top: -.2em;
}

.checkoutPoll button {
	margin-top: 26px;
}

.checkoutPoll select {
	width: auto;
	min-width: 160px;
	max-width: 360px;
}



/* После перехода на блок Form */

.Order {
	display: table;
	position: relative;
	table-layout: fixed;
	width: 100%;
}
.Order__box {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	display: table-cell;
	vertical-align: top;
}
.Order__box.order__form {
	width: $FORM_WIDTH;
}
.Order__box.order__info {
	padding-left: 3%;
}

.order__info .block {
	margin: 0 0 15px;
}
.order__info .block h1 {
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 4px;
}
.order__info .block p {
	line-height: 1.5em;
}
.order__info .info {
	color: #777;
	font-size: 12px;
	padding: 2px 0 0;
}
.order__info .block strong,
.order__info .block .Price {
	font-weight: bold;
}

.Col.order__info .attention {
	background-color: #fcf9d1;
	bottom: 50px;
	display: block;
	font-size: 13px;
	left: 0;
	line-height: 1.3em;
	padding: 8px;
	position: absolute;
}

.Order .Form__description { /* ^ added from z.1257 */
	font-size: 12px;
}



/* 4.3.3. PAGE SPECIFIC — ORDERS COMPLETED
------------------------------------------------------------------------------*/

.ordersCompletedInfo {
	margin: -15px 0 0;
}

.ordersCompletedInfo .listItemsPageControl {
	float: none;
	margin-top: 10px;
}
.ordersCompletedInfo .ordersProducedList + .listItemsPageControl {
	border-top: 1px solid #e2ddd9;
	border-bottom: 0;
	margin-top: 0;
	padding-top: 15px;
}
.ordersCompletedInfo .tabsContent {
	display: none;
}
.ordersCompletedInfo .tabsContent.active {
	display: block;
}
.ordersCompletedInfo .listItemsContainer .listItemsPageControl.bottom {
	border-bottom: medium none;
}
.ordersListType {
	font-size: 15px;
	margin-bottom: 40px;
}
.ordersListType li {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	border-radius: 2px 2px 0 0;
	border-image: none;
	border-style: solid;
	border-width: 1px 1px 0 1px;
	box-sizing: border-box;
}
.ordersListType li + li {
	margin-left: 5px;
}
.ordersListType li:not(.active) {
	border-color: #e2ddd9;
}

.ordersProducedList .approvalConsignee strong,
.ordersProducedList .approvalConsignee span {
	font-size: 12px;
	display: block;
	margin: 3px 0 0;
}
.ordersProducedList .approvalConsignee span {
	color: #777;
}

.ordersProducedList .approvalConsignee {
	.Contract {
		margin-top: 2px;

		.pseudoLink {
			display: inline;
		}
	}
}
.ordersProducedList .approvalConsignee .Bubble {
	display: inline-block;
}
.ordersProducedList .approvalConsignee .Bubble span,
.ordersProducedList .approvalConsignee .Bubble strong {
	display: inline;
}

.ordersProducedList table {
	width: 100%;
	font-size: 14px;
}
.ordersCompletedInfo .ordersProducedList table {
	font-size: 13px;
}
.ordersProducedList th,
.ordersProducedList td {
	padding: 12px;
	text-align: left;
}
.ordersProducedList th {
	color: #777;
	font-size: 12px;
}
.ordersProducedList td {
	border-top: 1px solid $B_ACTION;
}
.ordersProducedList .last td {
	border-bottom-color: #fff;
}
.ordersProducedList.blank h2 {
	color: #777;
	font-size: 22px;
	margin: 50px 0 150px;
	text-align: center;
}
.ordersCompletedInfo .ordersProducedList.blank h2 {
	color: #000;
	font-size: 22px;
	margin: 50px 0 0;
	text-align: center;
}
.ordersCompletedInfo .ordersProducedList.blank p {
	color: #777;
	font-size: 14px;
	margin: 10px 0 150px;
	text-align: center;
}

.ordersProducedList .approvalNums { /* ^ changed by z.1032 (previous class name: approvalNum) */
	font-size: 12px;
	color: #777;
	width: 60px; /* ^ changed by z.1032 (previous value: 42px) */
}

.ordersProducedList .approvalNum + .approvalNum { /* ^ added from z.1032 */
	margin-top: .3em;
}
.ordersProducedList .approvalNum .btnInfo { /* ^ added from z.1032 */
	position: absolute;
	vertical-align: top;
	margin-top: 0.03em;
	margin-left: 4px;
}

.ordersProducedList .approvalCheckbox {
	width: 10px;
}

.ordersProducedList .approvalSum {
	padding-right: 26px;
	text-align: right;
	white-space: nowrap;
	width: 110px;
}

.ordersProducedList td.approvalSum {
	font-weight: bold;
}

.ordersProducedList .approvalReceived {
	width: 140px;
}

.ordersProducedList td.approvalStatus {
	font-weight: bold;
}
.ordersProducedList .approvalStatus span {
	color: #777;
	display: block;
	font-weight: normal;
	margin: 3px 0 0;
	white-space: nowrap;
}

.ordersProducedList .approvalUser {
	width: 180px;
}

.ordersApprovalInfo .approvalUser__date {
	font-size: 12px;
	color: #777;
	display: block;
	margin-top: 5px
}

.ordersProducedList .selected {
	background: #f2ffe8;
	border: 1px solid #e5e5e5;
	border-bottom: none;
	border-top: none;
}


.ordersProducedList .paginatorContainer {
	border-top: 1px solid #ccc;
	font-size: 12px;
	margin: 10px 0 0;
	overflow: hidden;
}
.ordersProducedList .paginatorContainer .info {
	color: #777;
	cursor: default;
	display: block;
	float: left;
	margin: 8px 0 0;
}
.ordersProducedList .paginatorContainer strong {
	font-weight: bold;
}
.ordersProducedList .paginator {
	display: block;
	font-size: 15px;
	float: right;
	margin: 8px 0 0;
}


.ordersApprovalInfo .ToolBar .btnGreen {
	font-weight: bold;
}

.ordersApprovalInfo .ToolBar .refuse {
	margin-left: 15px;
}


/*.OrderUnite {}*/
.OrderUnite__item {
	display: block;
	font-size: 15px;
	line-height: 1em;
	padding-left: 17px;
}
.OrderUnite__item + .OrderUnite__item {
	margin-top: .5em;
}
.OrderUnite__item.new .OrderUnite__radio {
	vertical-align: baseline;
}
/* проблемы со специфичностью */
input.OrderUnite__checkbox,
input.OrderUnite__radio {
	margin: 1px 0 0 -18px;
	vertical-align: top;
}
.OrderUnite__caption {
	display: inline-block;
	margin-left: 1px;
}
.OrderUnite__name {
	font-size: 14px;
}
.OrderUnite__info {
	color: #666;
	display: block;
	font-size: 12px;
	margin-top: .4em;
}

.OrderUnite--fancy {
	width: 450px;

	.Comment {
		/* ^ del by z.1257 */
		/*border-top: 1px solid $B_ACTION;
		padding-top: 1.5em;*/

		&--first { /* ^ added from z.1257 */
			border-top: 1px solid $B_ACTION;
			padding-top: 2em;
		}

		&__label {
			font-size: 14px;
			font-weight: bold;
		}

		&__content {
			height: 80px;
		}

		&__info { /* ^ added from z.1257 */
			display: block;
			color: #777;
			font-size: 12px;
			margin-bottom: 1em;
		}
	}
}



/* 4.3.4. PAGE SPECIFIC — ORDER COMPLETED VIEWER
------------------------------------------------------------------------------*/

.orderCompletedDate {
	color: #777;
	display: inline-block;
	font-size: 14px;
	margin: 0 0 0 30px;
}

.orderCompletedStatus {
	margin: 0 0 20px;
}
.orderCompletedStatus .status {
	background-color: #f8f8f8;
	border: 1px solid #898989;
	color: #898989;
	display: inline-block;
	font-weight: bold;
	padding: 5px 10px;
}
.orderCompletedStatus .status.inProgress {
	border-color: #c4b215;
	color: #c4b215;
}
.orderCompletedStatus .status.done {
	border-color: #488f11;
	color: #488f11;
}


.listContentDescriptionTip {
	margin: 0 200px 10px 0;
}
.listContentDescriptionTip .text {
	background-color: #fcf9d1;
	border: 1px solid #ece8ac;
	box-shadow: inset 0 1px 0 #fff;
	color: #666;
	display: inline-block;
	padding: 7px 13px;
}


.listOrderStockContainer {
	clear: left;
}

.listOrderStockLabels {
	border-bottom: 1px solid $B_LIGHT;
	color: $C_ADD;
	margin: 0 0 5px;
	padding-bottom: 15px;
	overflow: hidden;
}
.listOrderStockLabels > li {
	display: block;
	float: left;
	margin-right: 1%;
}

.listOrderStockContainer .colItem {
	margin-left: 1%;
	width: 39%;
}
.listOrderStockContainer .colItem:first-child {
	margin-left: 0;
}
.listOrderStockContainer .colPrice {
	text-align: center;
	width: 12%;
}
.listOrderStockContainer .colQuantity {
	text-align: center;
	width: 9%;
}
.listOrderStockContainer .colSumm {
	text-align: center;
	width: 15%;
}
.listOrderStock .colSumm {
	font-weight: bold;
}
.listOrderStockContainer .colMore {
	float: right;
	width: 170px;
}
.listOrderStockLabels .colMore {
	color: #000;
}
.listOrderStockContainer .colMore .additional {
	margin-top: 0;
}

.listOrderStock > li {
	border-top: 1px solid $B_LIGHT;
	float: left;
	padding: 14px 0;
	width: 100%;
}
.listOrderStock > li:first-child {
	border-top: none;
}
.listOrderStockWrapper > div {
	float: left;
	font-size: 14px;
	margin-right: 1%;
}
.listOrderStock .colItem {
	line-height: 18px;
}
.listOrderStock .item {
	color: #000;
	font-size: 13px;
}
.listOrderStock a.item:hover {
	color: $BRAND__A;
}
.listOrderStock .itemImg {
	display: block;
	float: left;
	height: 40px;
	width: 40px;
}
.listOrderStock .itemName {
	display: block;
	margin: 0 0 0 50px;
}
.listOrderStock .itemInfoWrapper {
	margin: 0 0 0 50px;
}
.listOrderStock .itemCode {
	font-size: 11px;
}
.listOrderStock .itemInteract {
	display: inline-block;
}
.listOrderStock .colSumm strong {
	font-weight: bold;
}
.listOrderStock .additional {
	color: #777;
	font-size: 11px;
	margin: 5px 0;
	text-align: left;
}
.listOrderStock .basket {
	text-align: left;
}
.listOrderStock .basket .textCounter {
	margin: 0 5px 0 0;
	text-align: left;
}
.listOrderStock .basket strong {
	font-size: 13px;
	margin: 0 5px 0 0;
}

.listOrderStock .kit {
	margin: 12px 0 0 61px;
	width: 71%;
}
.listOrderStock .kit h3 {
	font-size: 12px;
	font-weight: bold;
	margin: 0;
}
.listOrderStock .kit ul {
	display: block;
	font-size: 12px;
	margin: 0;
	overflow: hidden;
}
.listOrderStock .kit li {
	border-top: 1px solid #e5e5e5;
	display: block;
	padding: 10px 0;
	overflow: hidden;
}
.listOrderStock .kit li:first-child {
	border-top: none;
}
.listOrderStock .kit .kitInfo {
	float: left;
}
.listOrderStock .kit .kitAmount {
	float: right;
	margin: 0 0 0 15px;
}
.listOrderStock .kit .kitInfo strong {
	color: #777;
	display: block;
	font-size: 10px;
	margin: 2px 0 0;
}

.listOrderStockControl .pay {
	background-color: $BG;
	border-radius: $BD_RAD;
	box-sizing: border-box;
	display: table;
	margin: 0 0 15px;
	padding: 9px 14px;
	width: 100%;
}
.listOrderStockControl .delivery,
.listOrderStockControl .total {
	display: table-cell;
	vertical-align: top;
}

.listOrderStockControl .pay em {
	color: #666;
	display: block;
	font-size: 11px;
	margin: 3px 0 0;
}

.listOrderStockControl .delivery {
	padding-top: 4px;
	padding-right: 40px;
	text-align: right;
}
.listOrderStockControl .delivery {
	font-size: 14px;
}
.listOrderStockControl .delivery .paid {
	color: #b42323;
}
.listOrderStockControl .delivery strong {
	font-weight: bold;
}
.listOrderStockControl .delivery .free {
	color: #3e791e;
}
.listOrderStockControl .delivery em {
	margin-top: 4px;
}

.listOrderStockControl .total {
	text-align: right;
	white-space: nowrap;
	width: 1px;
}
.listOrderStockControl .total span {
	font-size: 18px;
}
.listOrderStockControl .total > span {
	display: inline-block;
	vertical-align: middle;
}
.listOrderStockControl .total span.price {
	font-size: 16px;
}
.listOrderStockControl .price strong {
	font-size: 18px;
	font-weight: bold;
}

.listOrderStockControl .purchase {
	padding-left: 2em;
	text-align: right;
}

.orderCompletedInfo .delivery {
	margin-left: 43%;
}



.orderApprovalInfo {
	margin: 0 calc($SIDE_WIDTH + $SIDE_MARGIN) 23px 0;
}

.orderItem .listCartItemInfo {
	margin-left: 60px;
}

.orderApprovalDescription {
	max-width: 660px;

	&__field {
		height: 67px;
	}
}

.orderApprovalDescription > dl,
.orderApprovalDescription > dl + .cartActions {
	margin-top: 25px;
}

.orderApprovalDescription > dl {
	background: $BG;
	border-radius: $BD_RAD;
	display: block;
	padding-top: 20px;
	padding-bottom: 10px;
	overflow: hidden;
}
.orderApprovalDescription > dl > dt,
.orderApprovalDescription > dl > dd {
	box-sizing: border-box;
	margin: 0 0 10px;
}
.orderApprovalDescription > dl > dt {
	display: block;
	float: left;
	clear: left;
	padding: 0 7px 0 16px;
	text-align: right;
	font-weight: bold;
	width: 180px;
}
.orderApprovalDescription > dl > dd {
	clear: right;
	display: block;
	float: left;
	padding-right: 16px;
	width: 475px;
}
.orderApprovalDescription dl .contractInfo {
	position: relative;
	text-align: left;
}
.orderApprovalDescription .contractInfo .Bubble {
	display: inline-block;
}
.orderApprovalDescription .contractInfo .Contract .BubbleTrigger {
	color: $ACTION_MAIN;
}
.orderApprovalDescription .contractInfo .name {
	border-bottom-color: #0dabd3;
	color: #0dabd3;
}
.orderApprovalDescription .contractInfo .description {
	left: 0;
	right: auto;
}
.orderApprovalDescription .contractInfo .description:before {
	left: 20px;
	right: auto;
}
.orderApprovalDescription .splitter {
	border-top: 1px solid #dcdcdc;
	margin-top: 12px;
	padding-top: 20px;
}

.orderApprovalDescription .scoreInfo { /* ^ added from z.1257 */
	display: block;
	color: #777;
	font-size: 11px;
	margin-top: 5px;
}

.contractDescription {
	background-color: $ATTENTION;
	border-radius: $BD_RAD;
	margin: 0 0 23px;
	padding: 15px;
}
.contractDescription .header {
	font-size: 13px;
	font-weight: bold;
	margin: 0 0 3px;
}
.contractDescription.noContract .header {
	color: #cd0000;
}
.contractDescription strong {
	font-weight: bold;
}

.orderApprovalInfo .listCartContainer {
	margin: 25px 0 0 0;
}
.orderApprovalInfo .listCartLabels {
	margin: 0 0 12px;
}
.orderApprovalInfo .listCart > li:first-child {
	border-top-color: #e5e5e5;
}

.orderApprovalInfo .listCartControl .approving {
	display: table-cell;
	vertical-align: middle;
}
.orderApprovalInfo .approving .btnGreen {
	margin-right: 10px;
}
.listCartControl.listOrderStockControl .change + .pay {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.listCartControl.listOrderStockControl .total {
	float: none;
	margin-top: 0;
}
.listCartControl.listOrderStockControl .total > span {
	font-weight: normal;
}

.orderApprovalInfo .listCartControl .total {
	margin-top: 10px;
}

.orderApprovalInfo .total em .Price,
.orderApprovalInfo .total em .Price span {
	font-size: inherit;
	font-weight: normal;
}



/* 4.4. PAGE SPECIFIC — FEEDBACK
------------------------------------------------------------------------------*/

.Feedback__panel {
	bottom: 0;
	left: 0;
	opacity: 1;
	position: fixed;
	width: 100%;
	z-index: 600;
	transition: all 1.3s ease;
}
.Feedback__panel.hiden {
	bottom: -100%;
	opacity: 0;
}

.Feedback__panel .Page__wrapper {
	position: relative;
}

.Feedback__container {
	border-radius: 4px 4px 0 0;
	bottom: 0;
	position: absolute;
	right: $PAGE_WRAPPER_PADDING;
}

.FeedbackMenu {
	display: inline-block;
	position: relative;
}
.FeedbackMenu__trigger {
	background-color: #d6171e;
	background: linear-gradient(#d6171e, #8c080c);
	border-radius: 0 $BD_RAD 0 0;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	padding: 7px 10px;
	position: relative;
	width: $SIDE_WIDTH;
	z-index: 50;
}
.FeedbackMenu__trigger:hover {
	background-color: #f0666c;
	background: linear-gradient(#f0666c, #a51116);
}

.Feedback__suggestion {
	background: #FFF;
	background: rgba(255,255,255,.9);
	border-radius: $BD_RAD 0 0;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	padding: 7px 1.5em 7px 1.4em;
}

a.Feedback__suggestion:hover {
	text-decoration: none;
}

.FeedbackMenu__list {
	background: #FFF4E3;
	bottom: 100%;
	box-shadow: 0 0 10px rgba(0,0,0,.15);
	display: none;
	left: 0;
	margin-bottom: -4px;
	padding-bottom: 4px;
	position: absolute;
	z-index: 40;
}

.FeedbackMenu__item {
	.Overlay {
		top: 1px;
		right: 1px;
		bottom: 1px;
		left: 1px;
		width: auto;
		height: auto;
	}
	.SpinnerContainer.centered {
		margin-top: -15px;
		visibility: hidden;
	}
	&.loading .SpinnerContainer.centered {
		visibility: visible;
	}
	.Spinner {
		height: 30px;
		margin-top: 0;
		width: 30px;
	}
}

.FeedbackMenu__link {
	background-color: #fff4e3;
	border: 1px solid #e0d5c5;
	border-bottom: 0;
	display: block;
	padding: 13px 10px 13px 28px;
}
.FeedbackMenu__link:hover {
	background-color: #fffbf4;
	text-decoration: none;
}
.FeedbackMenu__header {
	color: #cf2025;
	position: relative;
}
.FeedbackMenu__link:hover .FeedbackMenu__header {
	color: #f21319;
}

.FeedbackMenu__icon {
	display: inline-block;
	line-height: 1;
	margin-top: -.2em;
	margin-right: 5px;
	margin-left: -21px;
	vertical-align: middle;
	width: 16px;
}
.FeedbackMenu__icon .icon {
	margin-top: 0;
}
.FeedbackMenu__icon .icon.fb-q {
	margin-top: -3px;
}

.FeedbackMenu__info {
	color: #646464;
	display: block;
	line-height: 1.4;
}

.Feedback__suggestion {
	cursor: pointer;
}

.fancyBoxFeedbackUnregistred label span {
	box-sizing: border-box;
	padding-right: 5px;
	position: relative;
}
.fancyBoxFeedbackUnregistred label sup {
	right: -2px;
	position: absolute;
	top: 0;
}
.fancyBoxFeedbackUnregistred input {
	width: auto;
}
.fancyBoxFeedbackUnregistred h1 {
	margin: 0 0 6px;
}
.fancyBoxFeedbackUnregistred select {
	vertical-align: baseline;
}
.fancyBoxFeedbackUnregistred .fieldWide {
	width: 355px;
}
.fancyBoxFeedbackUnregistred .Select {
	width: 100%;
}
.fancyBoxFeedbackUnregistred .descriptionInfo {
	font-size: 12px;
	margin-bottom: 20px;
}
.fancyBoxFeedbackUnregistred .fieldFullInside {
	padding-left: 158px;
}
.fancyBoxFeedbackUnregistred .fieldFullInside span {
	margin-left: -160px;
}
.fancyBoxFeedbackUnregistred .fieldFullInside .field {
	width: 100%;
}

.feedbackChat {
	margin-top: 30px;
	width: 100%;

	&__box {
		border: 1px solid #e2ddd9;
		display: inline-block;
		width: 79.5%;
		vertical-align: top;

		& + .feedbackChat__box {
			box-sizing: border-box;
			float: right;
			width: 18%;
		}
	}

	&CloseDate {
		color: #666;
		text-align: center;
		margin-top: 15px;

		.bold {
			color: #000;
			font-weight: bold;
		}
	}
}

.feedbackChatEmployee {

	&__box {
		padding: 15px 17px;
	}

	.EmployeeAbout {
		&__name {
			font-weight: bold;
			margin-bottom: 5px;
		}

		&__position {
			color: #666;
			font-size: 11px;
		}
	}

	.EmployeeRating {
		background-color: #f2eeea;
		padding-bottom: 0;

		&__result {
			transition: all .15s ease;
			margin-bottom: 15px;

			&.smallFontSize {
				font-size: 11px;
			}

			span {
				color: #666;
			}
		}

		&__other {
			padding-bottom: 15px;
		}

		&__stay {
			border-bottom: 1px dotted #006cb5;
			display: inline;
			margin-bottom: 15px;

			&:hover {
				text-decoration: none;
				border-bottom-color: #ff0030;
			}
		}

		.btnOutline {
			margin-top: 12px;
			width: 95px;
			padding-left: 12px;
			padding-right: 12px;
			text-align: left;
		}
	}

	.EmployeeRating__text {
		&.bad { color: #c22119; }

		&.good { color: #379014; }
	}
}

.ResultRating__reason {
	margin-top: 3px;
}

.feedbackChatHistory {
	box-sizing: border-box;
	padding: 28px 16px;
	font-size: 13px;
}

.Reply {
	background-repeat: no-repeat;
	max-width: 80%;
	padding-bottom: 8px;

	&__container {
		display: inline-block;
	}

	& + .Reply {
		margin-top: 20px;
	}


	&--employee {
		padding-left: 25%;
		text-align: right;

		.ReplyText {
			background-color: #ffeab1;

			&:before {
				background-image: resolve('interface/feedback__chat--tail.png');
				right: 5px;
			}
		}
	}

	&--you {
		padding-right: 25%;

		.ReplyText {
			background-color: #f2eeea;

			&:before {
				background-image: resolve('interface/feedback__chat--tail2.png');
				left: 5px;
			}
		}
	}
}
.ReplyText {
	border-radius: 4px;
	box-sizing: border-box;
	display: inline-block;
	padding: 15px 18px;
	position: relative;
	text-align: left;

	&:before {
		content: '';
		display: block;
		height: height('interface/feedback__chat--tail.png');
		width: width('interface/feedback__chat--tail.png');
		position: absolute;
		top: 100%;
	}
}
.ReplyInfo {
	padding-left: 15px;
	margin-bottom: 5px;
	text-align: left;
	color: #666;

	&__author {
		color: #000;
		font-weight: bold;
	}

	&__date {
		display: inline-block;
	}
}

.feedbackMail {
	text-align: center;
	min-height: 100%;
	position: relative;

	&__head {
		background-repeat: no-repeat;
		background-color: #e2ddd9;
		background-position: 50% 50%;
		background-image: url('/img/brand/logo_main.png?v=1');
		height: 110px;
		margin-bottom: 120px;
	}

	&__main {
		padding-bottom: 75px;
	}

	&__foot {
		font-size: 11px;
	}
}

.mailAnswer {

	&__box {
		line-height: 1em;

		&--title {
			font-size: 29px;
			line-height: 1em;
			margin-bottom: 14px;
		}

		&--content {
			font-size: 16px;
			line-height: 1em;
			margin-bottom: 17px;
		}
	}
}

.BadRatingInfo {
	padding: 10px 5px;

	.BadRatingTitle {
		line-height: 17px;
		font-weight: bold;
	}

	a {
		display: block;
		padding: 9px 15px;
	}

	li {
		display: block;
		font-size: 12px;
		margin: 0 -15px;

		&:hover {
			background-color: #f6f3f0;
			color: #ff0030;

			a {
				text-decoration: none;
			}
		}
	}
}

.mailAnswerBtn {
	background-color: transparent;
	border: 1px solid;
	border-radius: 2px;
	display: block;
	margin: 0 auto;
	width: 145px;
	height: 30px;

	&:hover {
		border-radius: 3px;
		color: #fff;
	}

	& + .mailAnswerBtn {
		margin-top: 12px;
	}

	&--bad {
		color: #c22119;

		&:hover {
			background: linear-gradient(to top, #b51e17, #c32119);
		}
	}

	&--nocomment {
		color: #404040;

		&:hover {
			background: linear-gradient(to top, #555, #727272);
		}
	}
}

.feedbackInfo .fancyBoxFeedbackUnregistred .fieldWide {
	width: 445px;
}
.feedbackInfo .fancyBoxFeedbackUnregistred .send {
	margin-bottom: 0;
}

.feedbackInfo {
	width: 80%;

	.fancyBoxFeedbackUnregistred {
		margin-bottom: 40px;
		width: 100%;

		.buttonSet {
			background-color: #f2eeea;
			padding-top: 12px;
			padding-bottom: 12px;
		}
	}
}

.feedbackType {
	display: block;

	&__box {
		box-sizing: border-box;
		display: inline-block;
		vertical-align: top;
	}

	.feedbackType__box + .feedbackType__box {
		padding-left: 17px;
	}
}

.feedbackTheme {
	width: 89%;

	&__title {
		font-size: 17px;
		font-weight: bold;
		margin: -3px 0 3px;
	}

	&__info {
		border-radius: 2px;
		box-sizing: border-box;
		color: #000;
		font-size: 13px;
		padding: 5px 0 7px;

		&--open {
			background-color: #ffeab1;
			margin-left: -7px;
			padding-left: 6px;
		}

		span {
			font-weight: bold;
		}
	}
}

.feedbackStatus {
	width: 11%;
	font-size: 12px;

	&__date {
		color: #666;
	}

	&__info {
		border-radius: 2px;
		background: #d7d5d2;
		color: #999;
		font-weight: bold;
		text-align: center;
		padding: 2px 0;
		margin-bottom: 8px;

		&--open,
		&--wait {
			color: #fff
		}
		&--open {
			background-color: #55a615;
		}

		&--wait {
			background-color: #a7126a;
		}
	}
}

.feedbackInfo .tip {
	display: block;
	margin: -23px 0 30px;
}

.feedbackForm {
	margin: 0 0 35px;
}
.feedbackForm input, .feedbackForm textarea {
	display: block;
	font-size: 14px;
	margin: 0 0 10px;
	width: 38%;
}
.feedbackForm textarea {
	height: 26px;
}
.feedbackForm .btn {
	display: none;
}
.feedbackForm .active input, .feedbackForm .active textarea {
	width: 100%;
}
.feedbackForm .active textarea {
	height: 130px;
}
.feedbackForm .active .btn {
	display: block;
}

.feedbackHistory {
	margin: 0 0 30px;
	clear: both;
}
.feedbackHistory__header {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 8px;
}
.feedbackHistory li {
	border-bottom: 1px solid #cbcbcb;
	padding: 10px 0 12px;
}

.feedbackHistory .info {
	display: block;
}
.feedbackHistory .status {
	color: #666;
	display: block;
	font-size: 14px;
	margin: 0 0 0 75px;
}
.feedbackHistory .status.getAnswer {
	background: url('/img/icons/feedback_answer.png') no-repeat 0 50%;
	color: #222;
	margin: 0 0 0 75px;
	padding: 0 0 0 22px;
}
.feedbackHistory .status.getAnswer strong {
	color: #666;
}


.feedbackComments {
	margin: 0 0 30px;
}
.feedBackCommentsList li {
	border-bottom: 1px solid #cbcbcb;
	border-top: 1px solid #cbcbcb;
	margin: 0 0 15px;
	padding: 11px 0 14px;
}
.feedBackCommentsList .header em {
	display: inline-block;
	color: #999;
	font-size: 11px;
	margin: 0 15px 3px 0;
}
.feedBackCommentsList p {
	font-size: 14px;
}
.feedBackCommentsList .answer {
	background-color: #fcf9d1;
	box-shadow: inset 0 1px 0 #fff;
	border: 1px solid #ece8ac;
	margin: 0 0 15px 35px;
	padding: 12px 15px 13px;
	position: relative;
}
.feedBackCommentsList .answer p {
	font-size: 14px;
}
.feedBackCommentsList .answer:before {
	display: block;
	content: '';
	background: url('/img/answer_arrow.png') no-repeat;
	height: 9px;
	position: absolute;
	top: -8px;
	width: 13px;
}
.feedbackAddComment {
	background-color: #F8F8F8;
	border-color: #E5E5E5;
	border-style: solid;
	border-width: 1px;
	box-shadow: inset 0 5px 5px -5px rgba(0,0,0,.1);
	padding: 17px 14px 14px;
	position: relative;
}
.feedbackAddComment form {
	display: inline-block;
	vertical-align: top;
}
.feedbackAddComment .addComment {
	margin: 0 25px 0 0;
	width: 48%;
}
.feedbackAddComment textarea {
	font-size: 14px;
	height: 26px;
	margin: 0 0 10px;
	width: 100%;
	max-width: 570px;
}
.feedbackAddComment .active textarea {
	height: 130px;
}
.feedbackAddComment .btnGreen {
	display:none;
}
.feedbackAddComment .active .btnGreen {
	display:block;
}
.feedbackAddComment em {
	display: inline-block;
	color: #999;
	font-size: 11px;
	margin: 0 25px 3px 0;
}

.feedbackSubmit:before {
	background: url('/img/icons/loader.gif');
}



/* 4.5. PAGE SPECIFIC — PRINT FORMS
------------------------------------------------------------------------------*/

#cartPrintVersion {
	background: #FFFFFF;
	font-size: 12px;
}

#cartPrintVersion p {
	font-size: 12px;
	margin: 1.3em 0 1.3em 5px;
}

#cartPrintVersion p:first-child {
	margin: 0;
}

#cartPrintVersion p.header {
	font-size: 18px;
	margin: 15px 0 25px 10px;
}

#cartPrintVersion #summ {
	font-size: 14px;
	font-weight: bold;
	margin: 10px 0;
	overflow: hidden;
}
#cartPrintVersion #summ dt, #cartPrintVersion #summ dd {
	float: left;
	line-height: 1.5em;
	width: 350px;
}
#cartPrintVersion #summ dt.delivery, #cartPrintVersion #summ dd.delivery {
	margin: 10px 5px 0 0;
}
#cartPrintVersion #summ dt {
	clear: left;
	width: 470px;
}
#cartPrintVersion #summ dd {
	font-weight: bold;
	margin: 0;
	text-align: right;
	width: 150px;
}

#cartPrintVersion p.agreed span {
	border-top: 1px solid #000;
	font-size: 10px;
	margin: 0 10px;
	padding: 0 40px;
	position: relative;
	top: 12px;
}

#cartPrintVersion p.agreed span.fio {
	padding: 0 110px;
}

#cartPrintVersion h3 {
	font-size: 16px;
	font-weight: bold;
	margin: 1em 0 0 5px;
}

#cartPrintVersion img.logo {
	display: inline;
	float: left;
	margin: 0 60px 0 0;
	position: relative;
	top: 5px;
}

#cartPrintVersion p span {
	font-size: 12px;
	margin: 0 0 10px 0;
}

#cartPrintVersion dl {
	line-height: 1.5em;
	margin: 0 0 1.5em 10px;
}

#cartPrintVersion dt {
	float: left;
	font-weight: bold;
	margin: 0 5px 0 0;
	text-align: right;
	width: auto;
}

#cartPrintVersion dd {
	font-weight: normal;
	margin: 0 5px 0 0;
	width: auto;
}

#cartPrintVersion table {
	border-collapse: collapse;
	margin: 0 0 0 10px;
	width: 630px;
}

#cartPrintVersion th,
#cartPrintVersion td {
	background: #fff;
	border: 1px solid #000;
	border-collapse: collapse;
	padding: 3px;
	text-align: center;
	vertical-align: top;
}
#cartPrintVersion th {
	font-weight: bold;
}

#cartPrintVersion td.itemName {
	text-align: left;
}

#cartPrintVersion td.itemName ul {
	display: block;
	font-size: 11px;
	margin: 0 0 0 10px;
}
#cartPrintVersion td.itemName li {
	display: block;
	margin: 8px 0 0;
}
#cartPrintVersion td.itemName li:first-child {
	margin-top: 0;
}
#cartPrintVersion td.itemName strong {
	display: block;
	font-weight: normal;
	margin: 12px 0 5px;
}

#cartPrintVersion .itemQty {
	text-align: center;
}

#cartPrintVersion p#summ {
	font-size: 16px;
	font-weight: bold;
	margin: 0;
	padding-top: 15px;
	text-align: right;
	width: 640px;
}

#cartPrintVersion #summ span {
	color: #555;
	font-size: 16px;
}

#cartPrintVersion button {
	color: #555;
	font-size: 18px;
	margin: 10px 0 0 50px;
	padding: 5px;
}

#cartPrintVersion #print {
	color: #d20000;
	margin: 10px 0 0 10px;
}



/* 4.6. PAGE SPECIFIC — SEARCH RESULT
------------------------------------------------------------------------------*/

.searchPageForm {
	background-color: $BG;
	border-radius: $BD_RAD;
	margin: 0 0 25px;
	padding: 10px;
	position: relative;
	width: 55%;
	z-index: 1;
}

.SideRubrics {
	margin-bottom: 20px;
	border: 1px solid #e6e1de;
	border-radius: $BD_RAD;
	padding: 7px 5px;
	
	&__header {
		font-size: 12px;
		font-weight: bold;
		padding-left: 8px;
		margin-bottom: 10px;
	}

	&__count {
		color: $C_INFO;
	}

	&__link:hover {
		text-decoration: none;
		
		.SideRubrics__linkWrapper {
			text-decoration: underline;
		}
	}	
}

.SideRubrics--all {
	columns: 4;
	padding-bottom: 0;

	@media screen and (max-width: 1140px) {
		columns: 3;
	}

	.SideRubrics__list {
		display: inline-block;
		width: 100%;
		margin-bottom: 15px;
	}

	.SideRubrics__link {
		color: #000;
		
		&:hover {
			color: #ff0030;
		}
	}
}

.SideRubrics--single,
.SideRubrics--multiple {
	.SideRubrics__link.active {
		box-sizing: border-box;
		width: 100%;
	}
}

.SearchRubrics--rich {
	.SearchRubrics {
		border-width: 0;
		padding: 0;
		padding-left: 5px;
		display: none;
	}

	.SideRubrics__item {
		border-top-width: 0;
		padding: 0 0 0 5px;

		&--disable {
			display: none;
			padding-left: 0;
		}

		&--showAll {
			font-size: 11px;
			padding-left: 8px;
			margin-top: 15px;
		}

		&--mismatch {
			opacity: .5;
			position: relative;
		
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	.SideRubrics__link {
		padding: 3px;
		
		&.active {
			background: $ATTENTION;
		}
	}

	.SideRubrics__count {
		color: $C_INFO;
		font-weight: normal;
	}
}


#hiddenRubrics{
	display: none;
}

#showMoreRubrics {
	font-size: 12px;
	color: #777;
	border-bottom: 1px dotted #777;
	cursor: pointer;
	display: inline-block;
	margin: 4px 0 0 12px;
}

#showMoreRubrics:hover {
	color: $ACTION_MAIN;
	border-color: $ACTION_MAIN;
}

.searchRecomendations {
	margin: 0 0 40px;
}
.searchRecomendations h3 {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 1em;
}
.searchRecomendations ol {
	color: #478c11;
	font-size: 18px;
	font-weight: bold;
	list-style-type: decimal;
	padding: 0 0 0 23px;
}
.searchRecomendations li {
	color: #000;
	display: block;
	font-size: 14px;
	font-weight: normal;
	margin: 0 0 8px;
}
.searchFormTip {
	color: #626262;
	margin: 0 0 9px;
}


.searchReplacement {
	margin: 0 0 15px;
	width: 55%;
}
.searchReplacement__header {
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 10px;
}

.searchReplacement__item {
	display: table;
	width: 100%;
}

.searchReplacement__imageWrapper,
.searchReplacement__information {
	display: table-cell;
	vertical-align: top;
}

.searchReplacement__information {
	padding-left: 20px;
}

.searchReplacement__image {
	height: 80px;
	width: 80px;
}

.searchReplacement__name {
	display: block;
}
.searchReplacement__button {
	margin: 10px 0 0;
}

.furnitureSearch {
	overflow: hidden;
}

.furnitureSearch h3 {
	font-size: 14px;
	font-weight: bold;
}
.furnitureSearch .imgWrapper img {
	width: 80px;
	height: 80px;
}
.furnitureSearch .imgWrapper {
	float: left;
}
.furnitureSearch .textWrapper {
	float: left;
	font-size: 14px;
	margin: 0 0 0 15px;
	line-height: 19px;
}
.furnitureSearch .textWrapper span {
	color: #777;
	display: block;
	font-size: 12px;
}
.furnitureSearch .item {
	margin: 15px 0;
	overflow: hidden;
}
.furnitureSearch .notice {
	background: #fcf9d1;
	padding: 10px;
	width: 62%;
}



/* 4.7. PAGE SPECIFIC — REGISTRATION FORM
------------------------------------------------------------------------------*/

.registrationForm {
	position: relative;
}

.registrationForm > form {
	float: left;
	width: $FORM_WIDTH;
}
.registrationForm .registrationReglament {
	clear: both;
	color: $C_ADD;
	font-size: 11px;
	margin-top: 15px;
}
.registrationForm .passwordRestore {
	background-color: #FCF9D1;
	color: #C80F17;
	margin: 0 0 10px;
	padding: 13px 18px 10px;
	width: 56%;
}

.registrationForm h5 {
	color: #666;
	font-size: 12px;
	margin: 0 0 6px;
}
.registrationForm .fieldset__header {
	margin-top: 3em;
}

.registrationForm .accountInformation {
	display: none;
	margin: 37px 0 0;
	position: relative;
}
.registrationForm .accountInformation:before {
	background: url('/img/misc/woman_with_folder.png') no-repeat;
	content: '';
	height: 608px;
	margin-top: 40px;
	margin-left: 80px;
	position: absolute;
	left: 100%;
	top: 0;
	width: 152px;
}

.findCartridge__form {
	width: $FORM_WIDTH;
}

.registrationForm__info,
.findCartridge__info {
	font-size: 13px;
	line-height: 1.4;
	margin-bottom: 1em;
}
.Form__set + .registrationForm__info {
	margin-top: 2em;
}

.registrationForm.phisFace .phisFaceInformation {
	display: block;
}

.registrationForm .phisFaceInformation {
	background-color: $BG;
	display: none;
	font-size: 13px;
	line-height: 1.4em;
	margin: 37px 0 0;
	width: 900px;
}

.registrationForm .accountType .Form__field .Form__field + .Form__field {
	margin-top: .75em;
}
.registrationForm .accountType .Form__label {
	color: $C_ACTION;
	font-size: 13px;
}
.registrationForm .accountType .Form__label .accountType__info {
	color: $C_ADD;
	display: block;
	font-size: 11px;
	padding-left: 17px;
}

.registrationForm div .registrationPhoneCode,
.registrationForm--major .registrationPhone .registrationPhoneCode {
	width: 45px;
}
.registrationForm div .registrationPhoneNumber,
.registrationForm--major .registrationPhone .registrationPhoneNumber {
	width: 101px;
}
.registrationForm div .registrationPhoneNumber.mixed,
.registrationForm--major .registrationPhone .registrationPhoneNumber.mixed {
	width: 175px;
}
.registrationForm .optionalLabel {
	padding-bottom: 15px;
}
.registrationForm .optionalLabelText {
	position: relative;
}
.registrationForm div .optionalField {
	color: #888;
	display: block;
	font-size: 12px;
	left: 0;
	margin-top: 4px;
	position: absolute;
	top: 100%;
}
.registrationForm .submitForm {
	background: none;
	height: 49px;
	margin: -10px 0 0;
	padding: 0 18px;
	position: relative;
}
.registrationForm .orderAccept {
	bottom: 0;
	font-size: 14px;
	font-weight: bold;
	position: absolute;
	right: 0;
}
.registrationForm .submitForm span {
	bottom: 9px;
	color: #888;
	font-size: 12px;
	left: 0;
	position: absolute;
}

.registrationForm .errorInput {
	border: 1px solid #EE1C25;
	color: #e10000;
}
.registrationForm span.errorMessage,
.registrationForm .accountInformation span.errorMessage {
	color: #EE1C25;
	display: block;
	font-size: 11px;
	margin-top: 5px;
	margin-left: 193px;
	text-align: left;
	top: 1px;
	width: auto;
}


.registrationForm .narrowCity {
	display: none;
}

.registrationForm.jurFace .registrationDescription {
	display: block;
}

.registrationForm.jurFace .accountInformation {
	display: block;
}

.registrationForm .registrationDescription {
	display: none;
	line-height: 1.4em;
	margin: 0 0 0 745px;
	padding: 180px 0 0;
	position: relative;
}
.registrationForm .registrationDescription > strong {
	display: inline-block;
}

.registrationDescription .popupSelect {
	display: inline-block;
	line-height: 1.3;
	margin: 0;
	padding: 0;
	position: relative;
}
.registrationDescription strong {
	font-weight: bold;
}
.registrationDescription .popupSelect.active .citySelect {
	display: block;
}
.registrationDescription .citySelect.arrow.top:before {
	left: auto;
	right: 20px;
}


.phisFaceInformation .photo {
	background: url('/img/misc/registering.png?v=1') no-repeat;
	float: left;
	height: 125px;
	width: 282px;
}
.phisFaceInformation .photo.delivery {
	background-position: 0 0;
}
.phisFaceInformation .photo.market {
	background-position: 0 -132px;
}
.phisFaceInformation .section {
	border-top: 1px solid $B_ACTION;
	clear: left;
	display: inline-block;
	padding: 1.5em;
}
.phisFaceInformation .section:first-child {
	border-top: 0;
}
.phisFaceInformation .description {
	margin-left: 292px;
}


.registrationForm--major {
	width: $FORM_WIDTH;
}
.registrationForm--major .accountInformation {
	margin-top: 0;
}
.registrationForm--major > form {
	width: 76%;
}
.registrationForm--major .accountInformation .vat {
	width: 100px;
}
.registrationForm .accountInformation .registrationAddress__index {
	width: 60px;
}
.registrationForm .accountInformation .registrationAddress__path {
	width: 416px;
}



/* 4.8. PAGE SPECIFIC — FAQ
------------------------------------------------------------------------------*/

.questionsInfo {}

.questionsList {
	width: 70%;
}
.questionsList li {
	margin: 20px 0 0;
}
.questionsList li:first-child {
	margin: 0;
}

.questionsList .question {
	margin: 0 0 10px;
}
.questionsList .answer {
	border-bottom: 1px solid #e9e8e5;
	display: none;
	overflow: hidden;
}

.questionsList .question {
	font-size: 18px;
}
.questionsList .answer p {
	margin: 0 0 10px;
}
.questionsList .answer b,
.questionsList .answer strong {
	font-weight: bold;
}


/* 4.9. PAGE SPECIFIC — COMPARE
------------------------------------------------------------------------------*/

.compareItemsControl {
	display: table;
	margin-bottom: 3em;
	width: 100%;
}
.compareItemsControl__box {
	display: table-cell;
}
.compareItemsControl__box.remove {
	text-align: right;
}


.compareItems {
	border: 1px solid $B_ACTION;
	border-collapse: collapse;
	table-layout: fixed;
	width: 100%;
}

.compareItems .item {
	background: #fff;
	border-right: 1px solid #dadada;
	padding: 15px;
	width: 25%;
}
.compareItems tr:first-child .item {
	padding-bottom: 0;
}
.compareItems .priceRow .item {
	padding-top: 0;
	vertical-align: bottom;
}

.compareItems .item .btn {
	vertical-align: top;
}

.compareItems .item .imgWrapper {
	clear: right;
	position: relative;
}

.compareItems .item .imgWrapper img {
	height: auto;
	padding-bottom: 5px;
	width: 100%;
	max-width: 240px;
}

.compareItems .item .itemCode {
	line-height: 13px;
	text-align: left;
}

.compareItems.overlay {
	box-shadow: 0 3px 5px rgba(0,0,0,.3);
	width: 940px;
}

.compareItems .item .imgWrapper .newItem {
	background: #509f00;
	color: #fff;
	display: block;
	float: left;
	font-size: 11px;
	height: 17px;
	left: 24px;
	line-height: 1.2em;
	padding: 3px 0 0;
	overflow: hidden;
	position: absolute;
	text-align: center;
	text-decoration: none;
	top: 0px;
	width: 56px;
}

.compareItems .item .removeLink {
	background-image: resolve('interface/remove.png');
	background-position: 100% 1px;
	background-repeat: no-repeat;
	color: #555;
	cursor: pointer;
	display: block;
	float: right;
	font-size: 11px;
	min-height: height('interface/remove.png');
	margin-bottom: 10px;
	padding-right: 16px;
	width: 37px;
}
.compareItems .item .removeLink:hover {
	background-image: resolve('interface/remove_brand_a.png');
	color: #db0d15;
}

.compareItems .item h3 {
	clear: both;
	font-size: 13px;
	font-weight: normal;
	margin-bottom: 5px;
	width: 100%;
}

.compareItems .item h3 a {
	color: $C_ACTION;
	line-height: 17px;
	padding: .2em 0;

	&:hover {
		color: $BRAND__A;
	}
}


.compareItems .item .itemPrice {
	font-size: 14px;
	font-weight: bold;
	padding-bottom: 5px;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 0px;
	text-align: left;
}

.compareItems .item .addToOrder {
	font-weight: normal;
	margin: 4px 0 2px;
	width: 160px;
}

.compareItems .item .rightCardBlock {
	clear: both;
}

.compareItems td.last {
	border: none;
}

.compareItems td.highlight {
	background: #fdfdf3;
}

.compareItems tr.rowFeatures {
	border-top: 1px solid #dadada;
}
.compareItems tr.rowFeatures ~ tr.rowFeatures {
	border-top: none;
}

.compareItems tr.odd td.highlight {
	background: #f1f1e8 !important;
}

.compareItems tr.rowFeatures:hover td {
	background: #fcf9d1 !important;
}

.compareItems td {
	border-right: 1px solid #dadada;
	line-height: 17px;
	padding: 15px;
	width: 25%;
}

.compareItems tr.odd td {
	background: #f1f1f1;
}

.compareItems td dl {
	color: #111;
	font-size: 11px;
	line-height: 14px;
	margin: 0px;
	overflow: hidden;
	padding: 0;
	text-align: left;
}

.compareItems td dt {
	clear: both;
	float: left;
	padding: 0px;
	width: 90px;
}

.compareItems td dl.packageInfo {
	color: #666;
	font-size: 10px;
	margin: 5px 0 0;
}

.compareItems .basket strong {
	color: #666;
	font-size: 12px;
	margin: 0 7px 0 0;
}
.compareItems .basket .textCounter {
	margin: 0 5px 0 0;
}

.compareItems .listItemBuy {
	clear: both;
}

.compareItems .listItemBuy .price {
	left: 0;
	margin-bottom: 5px;
}
.compareItems .listItemBuy .Price {
	font-size: 21px;
	font-weight: bold;
}

.compareItems .listItemBuy .ProductSpecial__item .Price {
	font-size: 11px;
	font-weight: normal;
}

.compareItems .listItemBuy .additional {
	color: #777;
	font-size: 11px;
}
.compareItems .listItemBuy .minOrder {
	margin-top: 5px;
}

.compareItems .listItemBuy .basketAlready,
.compareItems .basket .basketAlready {
	display: block;
	font-size: 11px;
	margin: 0 0 3px;
}

.compareItems .item .code {
	clear: both;
}

.compareItems .basket {
	vertical-align: bottom;
	padding-top: 0;
}
.compareItems .basket .ProductSpecial {
	margin-bottom: .5em;
}

.compareItems .ourOffer {
	vertical-align: top;
	padding-bottom: 0;
}


/* 4.9.1. PAGE SPECIFIC — COMPARE PANEL
------------------------------------------------------------------------------*/

.panelCompare__wrapper {
	bottom: 0;
	left: 0;
	opacity: 1;
	position: fixed;
	width: 100%;
	z-index: 610;
}
.panelCompare__wrapper .Page__wrapper {
	position: relative;
}

.panelCompare {
	background: #fff;
	border-radius: $BD_RAD $BD_RAD 0 0;
	box-shadow: $BX_SH;
	padding: 12px;
	width: 570px;
	position: absolute;
	bottom: 0;
	left: $PAGE_WRAPPER_PADDING;
}

.panelCompare ul {
	display: block;
	float: left;
}

.panelCompare ul li {
	background: url('/img/compare-no-photo.png') no-repeat;
	display: block;
	float: left;
	height: 40px;
	margin: 0 5px 0 0;
	position: relative;
	width: 40px;
}

.panelCompare ul li.loading {
	background: url('/img/loader.gif') 50% 50% no-repeat;
}

.panelCompare ul li:hover .delete {
	display: block;
}

.panelCompare ul li img {
	border: 1px solid #ccc;
	width: 38px;
	height: 38px;
}

.panelCompareDescription {
	display: block;
	float: left;
	margin: 5px 10px 0 0;
}

.panelCompare .delete {
	background-image: resolve('interface/remove.png');
	height: height('interface/remove.png');
	width: width('interface/remove.png');

	background-repeat: no-repeat;
	display: none;
	right: 0;
	margin: -4px -5px 0 0;
	position: absolute;
	cursor: pointer;
}

.panelCompare .delete:hover {
	background-image: resolve('interface/remove_brand_a.png');
}

.panelCompare .btn {
	margin: 5px 0 0 5px;
}

.panelCompareClear {
	background-image: resolve('interface/remove.png');
	background-repeat: no-repeat;
	cursor: pointer;
	display: block;
	float: right;
	min-height: height('interface/remove.png');
	margin-top: 14px;
	padding-left: 18px;
	font-size: 11px;
	color: #555;
}

.panelCompareClear:hover {
	background-image: resolve('interface/remove_brand_a.png');
	color: #d50a12;
}



/* 4.10. PAGE SPECIFIC — AUTHORIZATION
------------------------------------------------------------------------------*/

.authorizationField,
.registrationField {
	box-sizing: border-box;
	display: inline-block;
	position: relative;
	text-align: left;
	vertical-align: top;
	white-space: normal;
	width: 440px;
}
.registrationHint {
	background: $ATTENTION;
	border-radius: $BD_RAD;
	color: $C_ACTION;
	font-size: 13px;
	line-height: 18px;
	padding: 15px;
}
.registrationHintDescription {
	margin-bottom: 1em;
}

.authorizationField + .registrationField {
	margin-left: 30px;
}

.authorizationRestore {
	background-image: resolve('interface/mail_success.png');
	background-position: 0 1px;
	background-repeat: no-repeat;
	min-height: height('interface/mail_success.png');
	padding-left: calc(width('interface/mail_success.png') + 10px);
	text-align: left;
}

.authorization__userName {
	font-weight: bold;
}

.authorizationInfo {
	border: 1px solid #fff;
	border-color: transparent;
	border-color: rgba(255,255,255,0);
	text-align: center;
	white-space: nowrap;
}

.authorizationInfo .authorization__header,
.authorizationInfo .registrationField__header {
	font-size: 29px;
	line-height: 1.2;
	margin: 0 0 20px;
	text-align: left;
}

.authorizationField .forgotPassword {
	display: block;
	float: right;
	margin-top: 11px;
}

.authorizationField .authTrouble {
	background-color: #fff;
	border-radius: $BD_RAD;
	box-shadow: $BX_SH;
	box-sizing: border-box;
	color: #e0141d;
	cursor: default;
	display: block;
	font-size: 14px;
	margin: -10px 10px 0;
	padding: 12px 15px;
	position: relative;
	max-width: 440px;
}

.ErrorLoginPass.authTrouble,
.AccountDisable.authTrouble {
	background-color: #fff;
	border-radius: $BD_RAD;
	box-sizing: border-box;
	color: #e0141d;
	cursor: default;
	font-size: 14px;
	padding: 7px 5px;
	position: relative;
	max-width: 440px;
}
.ErrorLoginPass.authTrouble .header,
.AccountDisable.authTrouble .header {
	font-size: 13px;
	font-weight: bold;
	margin-bottom: .4em;
}
.ErrorLoginPass.authTrouble .info,
.AccountDisable.authTrouble .info {
	color: #000;
	font-size: 13px;
}
.AccountDisable.authTrouble .info strong {
	color: #000;
	cursor: text;
	font-weight: bold;
}

.authorizationField .authTrouble.simple,
.authorizationField .authTrouble .header,
.SendMailError.authTrouble.simple {
	color: #e0141d;
}
.authorizationField .authTrouble.simple,
.SendMailError.authTrouble.simple {
	padding: 15px 10px;
	text-align: center;
	min-width: 0;
	display: block;
	font-size: 14px;
}
.authorizationField .authTrouble .header {
	font-size: 13px;
	font-weight: bold;
	margin-bottom: .4em;
}

.authorizationField .authTrouble .info {
	color: #000;
	font-size: 13px;
}
.authorizationField .authTrouble .info strong {
	color: #000;
	cursor: text;
	font-weight: bold;
}

.authorizationField .authTrouble:before {
	background-image: resolve('interface/bubble_arrow_to-top.png');
	height: height('interface/bubble_arrow_to-top.png');
	background-repeat: no-repeat;
	background-position: 32px 100%;
	bottom: 100%;
	content: '';
	display: block;
	left: 0;
	position: absolute;
	width: 100%;
}

.newPassword .authorizationField,
.recoverPassword .authorizationField {
	border: none;
	margin-right: 0;
	margin-left: 0;
	padding-right: 0;
	width: 420px;
}
.recoverPassword .authorizationField h1 {
	margin: 0 0 5px;
}
.recoverPassword .description {
	color: #666;
	font-size: 13px;
	margin: 0 0 20px;
	display: block;
	line-height: 16px;
}
.recoverPassword .authorizationField label span {
	width: 60px;
}
.recoverPassword .authorizationField .btn {
	margin: 3px 0 0;
}
.recoverPassword .authTrouble:before {
	background-position: 41px 100%;
}

.recoverPassword .sent {
	background: url('/img/mail-sent.png') no-repeat;
	display: block;
	font-size: 14px;
	line-height: 18px;
	margin: 10px 0 20px;
	padding: 0 0 0 63px;
}

.authorizationField .btn {
	margin-left: 0;
}
.authorizationField .rememberMe {
	font-size: 11px;
	margin-bottom: 12px;
}

.authorizationField .rememberMe input {
	margin-bottom: 0;
	vertical-align: middle;
	width: auto;
}

.authorizationField .rememberMe span {
	display: inline;
	float: none;
	margin: 0;
	text-align: left;
	vertical-align: middle;
	width: auto;
}

.newPassword .authorizationField {
	width: 470px;
}
.newPassword .authorizationField h1 {
	margin: 0 0 5px;
}
.newPassword .authorizationField label.login {
	margin: 12px 0;
}
.newPassword .authorizationField label strong {
	font-weight: bold;
}
.newPassword .authorizationField label span {
	display: inline;
	float: none;
	margin: 0 4px 0 0;
}
.newPassword .authorizationField label .errorMessage {
	color: #ee1c25;
	font-size: 11px;
	text-align: left;
	width: 350px;
}
.newPassword .authorizationField label .errorInput {
	border: 1px solid #ee1c25;
}
.newPassword .authorizationField .btn {
	margin: 3px 0 0;
}

.newPasswordFail .authorizationField {
	width: 550px;
}
.newPasswordFail .authorizationField .description {
	margin-top: 1em;
}
.newPasswordFail .authorizationField .description strong {
	font-weight: bold;
}
.newPasswordFail .authorizationField .description p {
	margin-top: .5em;
}
.newPasswordFail .authorizationField .description p:first-child {
	margin-top: 0;
}



/* 4.11. PAGE SPECIFIC — ALPHABETICAL CATALOG
------------------------------------------------------------------------------*/

.catalogAlphabetLabels {
	background: #f9f9f9;
	border: 1px solid #e5e5e5;
	display: block;
	overflow: hidden;
	padding: 10px;
}
.catalogAlphabetLabels.britain {
	border-bottom: none;
}
.catalogAlphabetLabels li {
	display: block;
	float: left;
	font-size: 18px;
	font-weight: bold;
	margin-right: 12px;
}
.catalogAlphabetLabels li.last {
	margin-right: 0;
}
.catalogAlphabetLabels.brands li {
	margin: 0 22px 0 0;
}
.catalogAlphabetLabels.britain li.flag {
	background: url('/img/britain.gif') no-repeat;
}
.catalogAlphabetLabels li.flag {
	background: url('/img/russian.gif') no-repeat;
	display: block;
	height: 21px;
	margin-left: 6px;
	width: 32px;
}

.catalogFixedPanel {
	box-shadow: 0 0 20px rgba(0,0,0,.5);
	position: fixed;
	top: 0;
	z-index: 99;
}

.catalogAlphabetList .letter {
	display: block;
	font-size: 24px;
	font-weight: bold;
	margin: 0 0 5px;
}
.catalogAlphabetList .anchor {
	display: block;
	height: 1px;
	position: relative;
	top: -135px;
}
.catalogAlphabetList > li {
	border-bottom: 1px solid #ddd;
	display: block;
	padding: 20px 0 20px 10px;
	position: relative;
}
.catalogAlphabetList > li ul {
	color: #aaa;
	display: inline-block;
	list-style: disc inside;
	margin-right: 6%;
	vertical-align: top;
	width: 46%;
}
.catalogAlphabetList.threeColumns > li ul {
	margin-right: 5%;
	width: 30%;
}
.catalogAlphabetList > li ul.last {
	margin-right: 0;
}
.catalogAlphabetList > li li {
	list-style: disc inside;
	white-space: nowrap;
}
.catalogAlphabetList > li a {
	color: #000;
	display: inline-block;
	font-size: 12px;
	line-height: 15px;
	padding: 0 0 5px;
	vertical-align: top;
	white-space: normal;
}



/* 4.12. PAGE SPECIFIC — COLLECTIONS
------------------------------------------------------------------------------*/

.collectionsInfo {}

.collectionsInfo .collectionsList {
	margin:0 -30px 0 0;
}
.collectionsInfo .collectionsList li {
	display: inline-block;
	margin: 0 35px 50px 0;
	vertical-align: top;
	width: 30%;
	transition: all 0.3s ease;
}
@media screen and (max-width: 1265px) {
	.collectionsInfo .collectionsList li {
		min-width: auto;
		width: 46%;
	}
}
.collectionsInfo .collectionDescription {
	margin: 30px 0 0 175px;
}
.collectionsInfo .collectionsList img {
	float: left;
	width: 160px;
}
.collectionsInfo .collectionsList h3 {
	font-size: 14px;
	font-weight: bold;
	line-height: 1.4em;
	margin: 0 0 8px;
}
.collectionsInfo .collectionsList p {
	font-size: 12px;
	line-height: 1.4em;
}

.itemsNotFound {
	color: #444;
	font-size: 13px;
}
.itemsNotFound strong, .itemsNotFound span {
	display: block;
	text-align: center;
}
.itemsNotFound strong {
	font-size: 29px;
	font-weight: normal;
	margin: 0 0 10px;
}



/* 4.13. PAGE SPECIFIC — ACCOUNT SETTINGS
------------------------------------------------------------------------------*/

.accountSettingsInfo {
	margin: 0 0 30px;
}
.accountSettingsInfo .tabsContainer {
	margin:0 0 25px;
}
.accountSettingsInfo .tabsList li {
	color: #666;
	padding: 7px 10px 6px;
}
.accountSettingsInfo .tabsList li.active {
	padding: 7px 10px 7px;
}

.accountSettingsInfo .infoFields {
	margin: 0 0 20px;
	min-width: 440px;
	width: 50%;
}
.accountSettingsInfo .deliveryAddresses .infoFields {
	border-bottom: 1px solid #c2c2c2;
	margin: 0 0 10px;
	padding: 0 0 20px;
}
.accountSettingsInfo .unActiveField {
	display: inline-block;
	margin: 0 0 10px;
}
.accountSettingsInfo .unActiveField .label {
	color: #666;
	display: inline-block;
	margin: 0 10px 0 0;
	text-align: right;
	width: 130px;
}
.accountSettingsInfo .unActiveField strong {
	font-weight: bold;
}
.accountSettingsInfo .infoFields label {
	display: block;
	font-size: 13px;
	margin: 0 0 10px;
	padding: 0 0 0 140px;
	position: relative;
}
.accountSettingsInfo .infoFields label span {
	color: #666;
	display: inline-block;
	left: 0;
	margin: 0 10px 0 0;
	position: absolute;
	text-align: right;
	top: 5px;
	width: 130px;
}
.accountSettingsInfo .infoFields label em {
	color: #888;
	font-size: 11px;
	left: 5px;
	position: absolute;
	text-align: right;
	top: 20px;
}
.accountSettingsInfo .infoFields label input {
	width: 100%;
}
.accountSettingsInfo .infoFields label input[type="checkbox"],
.accountSettingsInfo .infoFields label input[type="radio"] {
	width: auto;
}
.accountSettingsInfo .personalInformation .infoFields .pseudoLink {
	margin: 0 0 0 140px;
}
.accountSettingsInfo .deliveryAddresses .infoFields button {
	margin-right: 8px;
}
.accountSettingsInfo .infoFields label input.unactive {
	background: #ebebeb;
	border: 0;
	padding: 8px 4px 4px;
}
.accountSettingsInfo .infoFields .nameProfile {
	font-size: 18px;
	margin: 0 0 15px;
	padding: 2px 4px;
	width: 100%;
}
.accountSettingsInfo .actionsNews {
	border-top: 1px solid #c2c2c2;
	border-bottom: 1px solid #c2c2c2;
	margin: 0 0 10px 140px;
	padding: 20px 0;
}
.accountSettingsInfo .actionsNews h3 {
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 5px;
}
.accountSettingsInfo .subscriptionVariants {
	margin: 0 0 0 -5px;
}
.accountSettingsInfo .subscriptionVariants label {
	margin: 0 20px 0 0;
}
.accountSettingsInfo button {
	margin: 0 0 0 140px;
}
.accountSettingsInfo .profiles {
	margin: 0 0 13px;
}
.accountSettingsInfo .profiles li {
	margin: 20px 0 0;
}
.accountSettingsInfo .profiles li:first-child {
	margin: 0;
}
.accountSettingsInfo .profiles .pseudoLink {
	font-size: 18px;
}
.accountSettingsInfo .profiles .remove{
	font-size: 13px;
	margin-left: 15px;
}
.accountSettingsInfo .profiles p {
	display: block;
	margin: 3px 0 0;
}
.accountSettingsInfo .infoFields .errorInput{
	border: 1px solid #EE1C25;
	color: #ee1c25;
}
.accountSettingsInfo .infoFields .errorMessage {
	color: #EE1C25;
	display: block;
	font-size: 11px;
	left: 0;
	position: relative;
	text-align: left;
	top: 1px;
	width: 328px;
}
.accountSettingsInfo .infoFields .phonesNumbers{
	margin: 0 0 10px;
	position:relative;
}
.accountSettingsInfo .infoFields .phonesNumbers label {
	display: inline-block;
	margin:0;
}
.accountSettingsInfo .infoFields .phonesNumbers label em{
	font-size: 13px;
	margin: 0 4px 0 0;
	position: relative;
	left: 0;
	top: 0;
}

.accountSettingsInfo .infoFields .registrationPhoneCode {
	width: 45px;
}
.accountSettingsInfo .infoFields .registrationPhoneNumber {
	width: 100px;
}
.accountSettingsInfo .phonesNumbers .errorMessage {
	left: 140px;
}
.accountSettingsInfo .changePasBlock {
	display: none;
}
.accountSettingsInfo .phonesNumbers .optionalField {
	color: #aaa;
	font-size: 11px;
	left: 0;
	position: absolute;
	text-align: right;
	top: 18px;
	width: 130px;
}

.accountSettingsInfo .newProfile {
	display: none;
}

.accountSettingsInfo .btn.cancel {
	margin-left: 0;
}


.accountSettingsInfo .subscribeInfo div {
	background: #f3f3f3;
	padding: 10px 15px 15px;
}
.accountSettingsInfo .subscribeInfo label {
	display: block;
	margin: 0 0 15px;
	padding: 0 0 0 20px;
}
.accountSettingsInfo .subscribeInfo span {
	display: block;
	font-size: 14px;
	font-weight: bold;
	margin: 3px 0 0;
}
.accountSettingsInfo .subscribeInfo em {
	color: #666;
	font-size: 12px;
}
.accountSettingsInfo .subscribeInfo input {
	margin: 0 0 0 -20px;
}
.accountSettingsInfo .subscribeInfo button {
	margin: -3px 0 0 18px;
}
.accountSettingsInfo .subscribeInfo p {
	margin: 0 0 12px;
}



/* 4.14. PAGE SPECIFIC — ACCOUNT CARTRIDGE
------------------------------------------------------------------------------*/

.cartridgeInfo {
	margin: 23px 0 0;
}
.cartridgeForms {
	position: relative;
	width: 80%;
}
.cartridgeForms .Form {
	display: inline-block;
	position: relative;
	vertical-align: top;
	width: 250px;
}
.Form.cartridgeSearch {
	width: 305px;
}
.cartridgeForms .Select {
	width: 100%;
}
.cartridgeForms .orPad {
	display: inline-block;
	font-size: 13px;
	font-weight: bold;
	line-height: 1;
	margin-right: 1em;
	margin-left: 1em;
	padding-top: 30px;
}
.cartridgeInfo .notFound {
	background-color: #fcf9d1;
	font-size: 18px;
	margin: 15px 0 30px;
	padding: 15px 13px;
}
.cartridgeInfo .Advice {
	width: 80%;
}

.cartridgeHelp {

	&.actionGoodsListHeader {
		padding: 12px 23px 13px;
	}

	.cartridgeHelp__title {
		font: bold 14px Arial;
	}

	&__href {
		margin-left: 15px;
	}
}

.cartrigesBox {
	margin: 37px 0 30px;

	&__header {
		font-size: 18px;
		margin-bottom: 15px;
	}

	&--devices &__content {
		background-color: $BG;
		padding: 10px;
	}
}
.cartrigesDevice {
	box-sizing: border-box;
	display: inline-block;
	width: 33.33%;
	padding: 4px;
	vertical-align: top;

	&__link {
		color: #000;
		font-size: 12px;
		padding-top: 5px;
		padding-bottom: 5px;
	}
}



/* 4.15. PAGE SPECIFIC — CERTIFICATES DOWNLOAD
------------------------------------------------------------------------------*/

.certificateInfo {
	margin: 0 0 30px;
	width: 70%;
}

.certificateInfo .description {
	margin: 0 0 20px;
}

.certificateInfo .uploadFile {
	background-color: $BG;
	margin: 0 0 30px;
	overflow: hidden;
	padding: 17px 15px 16px;
	position: relative;
}

.certificateInfo .uploadFile a.btn {
	border-left: none;
	border-radius: 0 4px 4px 0;
	float: right;
	font-weight: bold;
	line-height: 16px;
	margin: 0 20px 0 0;
	padding: 3px 12px 8px;
}

.certificateInfo .uploadFile .wrapperFile {
	display: block;
	margin: 0 127px 0 0;
	overflow: hidden;
}

.certificateInfo .uploadFile input {
	margin: 0;
	padding: 5px 0 6px;
	width: 100%;
}

.certificateInfo .uploadFile .btnGreen {
	float: right;
	margin: 0;
}

.certificateInfo .codesFileExample h3 {
	font-weight: bold;
	margin: 0 0 5px;
}

.certificateInfo .codesFileExample img {
	left: -15px;
	position: relative;
}
.certificateInfo .uploadFile .uploadExelFile {
	display: block;
	height: 33px;
	overflow: hidden;
	position: absolute;
	right: 107px;
	width: 100%;
	z-index: 20;
}
.uploadFile #upload {
	cursor: pointer;
	font-size: 30em;
	left: -4560px;
	opacity: 0;
	position: absolute;
	width: 6000px;
}
.uploadFile .Form__input {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.certificateInfo .uploadFile form {
	margin: 2px 0 1px;
}

.certificateInfo .downloadFile {
	background: url('/img/icons/zip.png') 3px 0 no-repeat;
	padding: 0 0 0 45px;
}
.certificateInfo .downloadFile h4 {
	font-size: 13px;
}
.certificateInfo .downloadFile a {
	font-size: 11px;
}
.certificateInfo .downloadFile a strong {
	font-weight: bold
}
.certificateInfo .downloadFile em {
	color: #666;
	font-size: 11px;
}
.certificateInfo .downloadFile .pseudoLink {
	color: #666;
	border-color: #666;
	float: right;
	font-size: 11px;
	vertical-align: bottom;
}

.certificateInfo .errorFile {
	background: url('/img/icons/error.png') no-repeat;
	padding: 0 0 0 45px;
}
.certificateInfo .errorFile .pseudoLink {
	font-size: 11px;
}
.certificateInfo .loadingFile {
	background: url('/img/loader.gif') no-repeat;
	padding: 9px 0 7px 45px;
}
.certificateInfo .loadingFile h4 {
	font-weight: bold;
	vertical-align: middle;
}

.certificateInfo .uploadFile form,
.certificateInfo .uploadFile .downloadFile,
.certificateInfo .uploadFile .errorFile,
.certificateInfo .uploadFile .loadingFile {
	display: none;
}
.certificateInfo .upload form {
	display: block;
}
.certificateInfo .download .downloadFile {
	display: block;
}
.certificateInfo .error .errorFile {
	display: block;
}
.certificateInfo .loading .loadingFile {
	display: block;
}



/* 4.16. PAGE SPECIFIC — CONTACTS
------------------------------------------------------------------------------*/

.ContactsSuggest {
	background-image: resolve('interface/footer-major.png');
	padding: 20px 20px 20px calc(width('interface/footer-major.png') + 10px);
	background-color: $BG;
	background-repeat: no-repeat;
	background-position: 5px 10px;
	margin-top: 30px;
	margin-bottom: 30px;

	&__header {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: .5em;
	}
}

.Contacts .ContactsList {
	margin: 0;
}

.ConatactsItem:first-child {
	border-top: 0 none;
	margin: 0;
}
.ConatactsItem {
	border-top: 1px solid $B_ACTION;
	margin: 20px 0;

	.InviteTender {
		margin-top: 15px;
	}
}
.Contacts .ContactsItem__header {
	color: $BRAND;
	margin: 20px 0 6px;
}
.textNode .ContactsItem__header:first-child {
	margin-top: 20px;
}
.Contacts p {
	margin: 0 0 4px;
	vertical-align: top;
}

.textNode p + .ConatactsEmployeeList {
	margin-top: 17px;
}

.ConatactsEmployeeList {
	margin: 0;
	padding: 0;
}

.ConatactsEmployeeList__item:first-child {
	margin-top: 0;
}
.ConatactsEmployeeList__item {
	margin-top: 17px;
}

.Conatacts__employeeName {
	font-size: 14px;
	font-weight: bold;
}
.Conatacts__employeeAppointment {
	display: block;
	font-style: italic;
	margin: -2px 0 0;
}

.ContactsTownChoose {
	background: $BG;
	border-radius: $BD_RAD;
	display: inline-block;
	font-size: 15px;
	line-height: 1em;
	margin-bottom: 20px;
	padding: 13px 11px;
}

.ContactsTownChoose__selector {
	display: inline-block;
}

.ContactsTownChoose__selector .citySelect {
	left: -31px;
}

.ContactsTownChoose__selector .citySelect .btnClose {
	right: 7px;
	top: 7px;
}

.Bubble.ContactsTownChoose__selector .BubbleContent:before {
	left: 61px;
	width: 16px;
}

.ContactsTownChoose__trigger {
	font-weight: bold;
}



/* 4.17. PAGE SPECIFIC — ABOUT OM
------------------------------------------------------------------------------*/

.aboutOMInfo .goodsCat {
	font-style: italic;
}
.aboutOMInfo .factsList > li {
	display: inline-block;
	vertical-align: top;
	width: 46%;
}
.aboutOMInfo .factsList > li:first-child {
	margin: 0 7% 0 0;
}
.aboutOMInfo .categories {
	margin: 0 0 20px;
}
.aboutOMInfo .categories p {
	margin: 0 0 10px;
}



/* 4.18. PAGE SPECIFIC — SECTORAL PROPOSALS
------------------------------------------------------------------------------*/

.sectoralProposalsInfo .textNode {
	margin:0 0 40px;
}



/* 4.19. PAGE SPECIFIC — SHOP ADRESSES
------------------------------------------------------------------------------*/

.officeMagShopsInfo .thinContainer {
	min-width: 940px;
	width: 70%;
}

.officeMagShopsInfo .schemeMap {
	margin: 40px 0 20px;
}

.officeMagShopsInfo .shopContacts {
	float: left;
	margin: 0 1% 0 0;
	width: 17%;
}
.officeMagShopsInfo .shopContacts p,
.officeMagShopsInfo .shopContacts .printLink {
	margin: 0 0 7px;
}
.officeMagShopsInfo .shopContacts em {
	color: #666;
	font-size: 12px;
	position: relative;
	top: -2px;
}

.officeMagShopsInfo .advantages {
	margin: 0 0 20px;
	padding: 0;
}
.officeMagShopsInfo .advantages > li {
	display: inline-block;
	margin: 0 0 0 6%;
	vertical-align: top;
	width: 46%;
}
.officeMagShopsInfo .advantages > li:first-child {
	margin: 0;
}

.officeMagShopsInfo .advantages ol {
	font-style: italic;
	list-style: decimal outside;
	padding: 0 0 0 17px;
}
.officeMagShopsInfo .advantages ol li {
	margin: 0 0 15px;
}

.officeMagShopsInfo .interierPhotos {
	padding: 0;
	margin: 25px -20px 15px 0;
}
.officeMagShopsInfo .interierPhotos li {
	display: inline-block;
	margin: 0 20px 10px 0;
}
.officeMagShopsInfo .interierPhotos img {
	border: 1px solid #999;
	margin: 0;
}
.officeMagShopsInfo .interierPhotos img:hover {
	border: 1px solid #EE1C25;
	margin: 0;
}



/* SHOPS LIST
------------------------------------------------------------------*/

.officeMagShopsInfo .interierPhotos li {
	margin-right: 11px;
	margin-bottom: 5px;
}
.officeMagShopsInfo .interierPhotos img {
	height: 60px;
	padding: 3px;
	width: 100px;
}

.officeMagShopsInfo .factsList > li {
	display: inline-block;
	vertical-align: top;
	width: 46%;
}
.officeMagShopsInfo .factsList > li:first-child {
	margin: 0 7% 0 0;
}


.officeMagShopsInfo .shopCityList,
.officeMagShopsInfo .shopCityList ul,
.officeMagShopsInfo .shopCityList li {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.officeMagShopsInfo .shopCityList {
	border-bottom: 1px solid #ccc;
	margin-bottom: 30px;
}
.officeMagShopsInfo .shopCityList > li {
	border-top: 1px solid #ccc;
	padding: 0 0 30px;
}

.officeMagShopsInfo .shopCityList .city,
.officeMagShopsInfo .shopCityList .shops > li {
	padding: 15px 0;
}

.officeMagShopsInfo .shopCityList .city {
	float: left;
	width: 150px;
}
.officeMagShopsInfo .shopCityList .shops {
	margin-left: 160px;
}

.shopCityList .shops > li {
	border-top: 1px solid #e5e5e5;
}
.shopCityList .shops > li:first-child {
	border-top: none;
}

.shopCityList .col {
	box-sizing: border-box;
	display: inline-block;
	padding: 0 10px;
	vertical-align: top;
}
.shopCityList .shops .col:first-child {
	padding-left: 0;
}
.shopCityList .shops .col:last-child {
	padding-right: 0;
}
.shopCityList .shops .col.contacts { width: 50%; }
/*.shopCityList .shops .col.actions { width: 25%; }*/
.shopCityList .shops .col.copycenter { width: 20%; }
.shopCityList .shops .col.onDuty { width: 30%; }


/*.shopCityList .shops .col.actions,*/
.shopCityList .shops .col.copycenter {
	font-size: 11px;
	text-align: center;
}

.shopCityList .shops .col.onDuty table {
	margin-left: auto;
}

.shopCityList .schemeLinkWrapper {
	margin-bottom: .5em;
}
.shopCityList .schemeLinkWrapper .trigger {
	cursor: pointer;
}
.shopCityList .schemeLinkWrapper .pseudoLink {
	color: #000;
}

.shopCityList .contactsItem {
	display: block;
	line-height: 20px;
}


.officeMagShopsInfo .shopServicesLink a {
	color: #000;
	text-decoration: none;
}
.officeMagShopsInfo .shopServicesLink span {
	color: $ACTION_MAIN;
}
.officeMagShopsInfo .shopServicesLink a:hover span {
	text-decoration: underline;
}
.officeMagShopsInfo .shopServicesLink em {
	font-style: normal;
}

.officeMagShopsInfo .icon.star,
.officeMagShopsInfo .icon.copy {
	background: url('/img/icons/shopServices.png') no-repeat;
}

.officeMagShopsInfo .shopCityList .icon.star,
.officeMagShopsInfo .shopCityList .icon.copy {
	margin-right: .4em;
}
.officeMagShopsInfo .shopCityList .icon.star {
	background-position: 0 0;
	height: 20px;
	width: 21px;
}
.officeMagShopsInfo .shopCityList .icon.copy {
	background-position: -21px 0;
	height: 23px;
	width: 22px;
}



/* SHOP CARD
------------------------------------------------------------------*/

.officeMagShopsInfo.textNode {
	overflow: visible;
}

.officeMagShopsInfo .schemeMap {
	margin-top: 0;
	padding-right: 240px;
	position: relative;
}

.officeMagShopsInfo .shopContacts {
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 220px;
}
.officeMagShopsInfo .shopContacts .printLink {
	display: block;
}

.officeMagShopsInfo .shopLocation {
	height: 350px;
	margin-right: 220px;
	width: 100%;
}


.officeMagShopsInfo .shopServices {
	border: 1px solid #cecece;
	display: inline-block;
	padding: 17px;
}

.officeMagShopsInfo .shopServices .icon.star,
.officeMagShopsInfo .shopServices .icon.copy {
	float: left;
	margin-right: 10px;
	height: 105px;
	width: 93px;
}
.officeMagShopsInfo .shopServices .icon.star {
	background-position: 0 -23px;
}
.officeMagShopsInfo .shopServices .icon.copy {
	background-position: -93px -23px;
}

.officeMagShopsInfo .shopServices .shopServicesLink {
	display: inline-block;
	vertical-align: top;
	width: 300px;
}
.officeMagShopsInfo .shopServices .shopServicesLink span,
.officeMagShopsInfo .shopServices .shopServicesLink em {
	display: block;
	margin-left: 80px;
}
.officeMagShopsInfo .shopServices .shopServicesLink span {
	font-size: 16px;
	margin-bottom: 5px;
}



/* COPY CENTER
------------------------------------------------------------------*/

.officeMagShopsInfo .copyCenterFeatureList {
	border-bottom: 1px solid #E5E5E5;
	border-top: 1px solid #E5E5E5;
	list-style-type: none;
	margin: 0;
	padding: 40px 0 0;
}
.officeMagShopsInfo .copyCenterFeatureList > li {
	display: inline-block;
	margin: 0 9% 50px 0;
	position: relative;
	vertical-align: top;
	width: 180px;
}

.officeMagShopsInfo .copyCenterFeatureList .active .img,
.officeMagShopsInfo .copyCenterFeatureList .active .trigger {
	position: relative;
	z-index: 3;
}

.officeMagShopsInfo .copyCenterFeatureList .trigger {
	border-bottom: 1px dashed #000;
	cursor: pointer;
}
.officeMagShopsInfo .copyCenterFeatureList .trigger:hover {
	border-color: #e10000;
	color: #e10000;
}
.officeMagShopsInfo .copyCenterFeatureList .description {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 0 15px rgba(0,0,0,.3);
	display: none;
	font-style: italic;
	left: -20px;
	margin: 0;
	padding: 0;
	padding-top: 100%;
	padding-bottom: 10px;
	position: absolute;
	right: -20px;
	top: -20px;
	width: 300px;
	z-index: 2;
}
.ie8 .officeMagShopsInfo .copyCenterFeatureList .description {
	border: 1px solid #ccc;
}
.officeMagShopsInfo .copyCenterFeatureList .description ul {
	margin: 20px 20px 0 40px;
	padding: 0;
}
.officeMagShopsInfo .copyCenterFeatureList .active .description {
	display: block;
}

.officeMagShopsInfo .copyCenterFeatureList .description .close {
	background: url('/img/icons/shopServices.png') -43px 0;
	cursor: pointer;
	height: 17px;
	position: absolute;
	right: 16px;
	top: 16px;
	width: 17px;
}
.officeMagShopsInfo .copyCenterFeatureList .description .close:hover {
	background-position: -60px 0;
	height: 17px;
	right: 16px;
	top: 16px;
	width: 17px;
}

.officeMagShopsInfo .copyCenterFeatureList .img {
	background: url('/img/icons/copycenter_icons.jpg') no-repeat;
	display: block;
	height: 130px;
	margin-bottom: 20px;
}
.officeMagShopsInfo .copyCenterFeatureList .img.docs {
	background-position: 0 0;
	width: 90px;
}
.officeMagShopsInfo .copyCenterFeatureList .img.style {
	background-position: -90px 0;
	width: 130px;
}
.officeMagShopsInfo .copyCenterFeatureList .img.poly {
	background-position: -220px 0;
	width: 117px;
}
.officeMagShopsInfo .copyCenterFeatureList .img.stamps {
	background-position: -337px 0;
	width: 108px;
}
.officeMagShopsInfo .copyCenterFeatureList .img.laser {
	background-position: -445px 0;
	width: 120px;
}
.officeMagShopsInfo .copyCenterFeatureList .img.wide {
	background-position: -565px 0;
	width: 122px;
}
.officeMagShopsInfo .copyCenterFeatureList .img.prize {
	background-position: -687px 0;
	width: 78px;
}

.officeMagShopsInfo .shopServicesLink.copyInfo {
	text-align: left;
}
.officeMagShopsInfo .shopServicesLink.copyInfo .icon {
	float: left;
}
.officeMagShopsInfo .shopServicesLink.copyInfo strong,
.officeMagShopsInfo .shopServicesLink.copyInfo em {
	display: block;
	line-height: 1;
	margin-left: 26px;
}
.officeMagShopsInfo .shopServicesLink.copyInfo strong {
	margin-bottom: 3px;
	white-space: nowrap;
}

.officeMagShopsInfo .copyCenterFeaturesBlock {
	margin: 38px 0 0
}

.CopyCenter__header .copyCenterDownloadLinks {
	display: none;
	list-style: none;
	margin: 0 0 0 80px;
	padding: 0;
}

.CopyCenter__header .copyCenterDownloadLinks.active {
	display: inline-block;
}

.CopyCenter__header .copyCenterDownloadLinks li:first-child {
	margin: 0;
}

.CopyCenter__header .copyCenterDownloadLinks li {
	display: inline-block;
	margin: 0 0 0 40px;
	padding: 0;
}
.CopyCenter__header .copyCenterDownloadLinks a:before {
	@mixin i-inline;
	background-image: resolve('interface/file--pdfBig.png');
	height: height('interface/file--pdfBig.png');
	width: width('interface/file--pdfBig.png');

	content: '';
	margin-right: .4em;
	margin-top: -.4em;
}

.citySelect.copycenters li {
	margin-bottom: 5px;
}

.citySelect.copycenters p {
	color: #000;
	margin: 5px 0 0 10px;
}

.CopyCenter__header {
	background: #FFF;
	border-bottom: 1px solid #e5e5e5;
	margin: 0 0 35px;
	padding: 18px 0 12px;
}

.CopyCenter .FlowingHeaderWrapper {
	max-width: 1180px;
	min-width: 920px;
	margin: 0 auto;
	padding: 0 30px;
}

.CopyCenter .FlowingHeader {
	background: #fff;
	background: rgba(255,255,255,.95);
	border-bottom: 1px solid #e5e5e5;
	box-shadow: 0 1px 5px rgba(0,0,0,.07);
	position: fixed;
	left: 0;
	width: 100%;
	z-index: 999;
}

.CopyCenter .FlowingHeader .CopyCenter__header {
	background: none;
	border: 0 none;
	margin: 0;
}

.CopyCenter .FlowingHeader.isFlowing {
	margin: 0;
	top: 0;
	transition: top .5s ease;
}

.CopyCenter__header .CopyCenter__headerLogo {
	margin: 0;
}

.CopyCenter__headerLinks {
	float: right;
}

.CopyCenter__addressesAnchor,
.CopyCenter__addressesAnchor:hover {
	text-decoration: none;
}

.CopyCenter__addressesAnchor {
	display: inline-block;
	border-radius: 6px;
	padding: 5px 10px;
}

.CopyCenter__addressesAnchor,
.CopyCenter__addressesAnchor .pseudoLink {
	transition: all .3s ease;
}
.CopyCenter__addressesAnchor:hover .pseudoLink {
	color: $BRAND__A;
}

.CopyCenter__addressesAnchor.here {
	background: none repeat scroll 0 0 $ATTENTION;
	border-bottom: 0 none;
	color: #000000;
}

.CopyCenter__addressesAnchor.here .pseudoLink {
	border-bottom: 0 none;
	color: #000000;
}

.CopyCenter__addressesAnchor .icon.strongPin {
	margin-right: 0.4em;
	position: relative;
}

.CopyCenter__header .CopyCenter__addressesAnchor:before {
	@mixin i-inline;
	background-image: resolve('interface/pin--big.png');
	height: height('interface/pin--big.png');
	width: width('interface/pin--big.png');

	content: '';
	margin-right: .4em;
}

.CopyCenter__about p {
	margin-bottom: 0;
}

.textNode .factsList.CopyCenter__advantagesList ul {
	margin-bottom: 0;
}

.CopyCenter__features {
	margin: 0 0 0 -1.5%;
	padding-bottom: 20px;
	height: 600px;
	width: 101.5%;
}

.CopyCenter__featuresColumn {
	vertical-align: top;
	padding-left: 1.5%;
	width: 32.5%;
}

.CopyCenter__featuresColumnContent {
	width: 100%;
	height: 100%;
}

.CopyCenter__featuresRowContent {
	height: 100%;
	padding: 0 0 4%;
}

.CopyCenter__featuresRow {
	border-radius: $BD_RAD;
	height: 100%;
}

.CopyCenter__featuresRowWrapper {
	padding: 30px 25px;
}

.CopyCenter__featuresRow.documents {
	background-color: #91e2f9;
	background-image: linear-gradient(90deg, #ccedfc, #91e2f9);
}

.CopyCenter__featuresRow.corporateIdentity {
	background-color: #e898f9;
	background-image: linear-gradient(90deg, #f7ccfb, #e898f9);
}

.CopyCenter__featuresRow.seals {
	background-color: #f9f193;
	background-image: linear-gradient(90deg, #fbfbcb, #f9f193);
}

.CopyCenter__featuresRow.laser {
	background-color: #c5c5c5;
	background-image: linear-gradient(90deg, #e1e1e1, #c5c5c5);
}

.CopyCenter__featuresRow.polygraphic {
	background-color: #f99f97;
	background-image: linear-gradient(90deg, #fcd3cb, #f99f97);
}

.CopyCenter__featuresRow.souvenirs {
	background-color: #cbff8c;
	background-image: linear-gradient(90deg, #e2ffc7, #cbff8c);
}

.CopyCenter__featuresRow.premium {
	background-color: #ffd48d;
	background-image: linear-gradient(90deg, #ffedc5, #ffd48d);
}

.CopyCenter__featuresRow.widescreen {
	background-color: #8cb6ff;
	background-image: linear-gradient(90deg, #c6d8ff, #8cb6ff);
}

.CopyCenter__featuresRow.posters {
	background-color: #ae90ff;
	background-image: linear-gradient(90deg, #d8c5ff, #ae90ff);
}

.textNode h4.CopyCenter__featuresHead {
	font-weight: bold;
	margin: 0;
}

.textNode .noBullits.CopyCenter__featuresList {
	margin: 5px 0 0;
}

.textNode .noBullits.CopyCenter__featuresList li {
	margin: 5px 0 0;
}

.textNode .noBullits.CopyCenter__featuresList li:first-child {
	margin: 0;
}

.officeMagShopsInfo.CopyCenter .interierPhotos {
	margin: 25px 0 0 -.5%;
}

.officeMagShopsInfo.CopyCenter .interierPhotos li {
	width: 19.5%;
	margin: 0 0 0 .5%;
}

.officeMagShopsInfo.CopyCenter .interierPhotos a {
	display: block;
	position: relative;
}

.officeMagShopsInfo.CopyCenter .interierPhotos a:hover:after {
		background: rgba(0,0,0,.4);
		transition: background .2s ease;
}

.officeMagShopsInfo.CopyCenter .interierPhotos a:after {
	background: rgba(0,0,0,0);
	content: '';
	display: block;
	left: 0;
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 10;
	transition: background .6s ease;
}

.officeMagShopsInfo.CopyCenter .interierPhotos img {
	border: 0 none;
	display: block;
	height: auto;
	padding: 0;
	width: 100%;
}



/* 4.20. PAGE SPECIFIC — CATALOG ORDER
------------------------------------------------------------------------------*/

.textNode img.catalogOrder__monitor {
	margin: 0;
}

.catalogOrderForm {
	position: relative;
	min-height: 386px;
}

.catalogOrderForm__set {
	background: #f2eeea url('/img/misc/catalog_order_double.png') no-repeat 540px 30px;
}

.catalogOrderForm .Form__description {
	color: #000;
}

.catalogOrderForm .Form__fieldWrapper {
	width: 462px;
}

.catalogOrder__footnoteText {
	font-style: italic;
}

.catalogOrder__intro {
	margin-bottom: 10px;
}



/* 4.21. PAGE SPECIFIC — SPECIAL OFFERS
------------------------------------------------------------------------------*/

table.specialOffers {
	background: none;
	margin: 0;
	text-align: left;
	width: 100%;
}
.specialOffers tr:hover {
	background: none;
}
.specialOffers td, .specialOffers th {
	border: none;
	border-bottom: 1px solid #ccc;
	font-weight: normal;
	line-height: 17px;
	padding: 8px;
	text-align: left;
	vertical-align: middle;
}
.specialOffers th {
	background: none;
	color: #777;
	font-size: 12px;
}

.specialOffers .photo {
	width: 40px;
}
.specialOffers .highlighted {
	background: #f1f1f1;
}
.specialOffers .price {
	border-right: 2px solid #fff;
	text-align: center;
	width: 110px;
}
.specialOffers .centered {
	border-bottom: 2px solid #fff;
	text-align: center;
}
.specialOffers .empty{
	border: none;
}

.specialOffers .btn {
	font-size: 1px;
	padding: 6px 4px 4px 7px;
}
.specialOffers .btn:before {
	background: url('/img/icons/cart2.png') no-repeat;
	content: '';
	display: inline-block;
	height: 17px;
	margin-right: 5px;
	position: relative;
	top: -1px;
	vertical-align: middle;
	width: 17px;
}

.specialOffers .price span {
	display: inline-block;
	font-size: 12px;
	margin: 0 5px 0 0;
}
.specialOffers .price strong {
	font-size: 14px;
	font-weight: bold;
}
.specialOffers .price sup {
	bottom: .66em;
}
.specialOffers .title span {
	color: #777;
	font-size: 11px;
}


.listItemBuy .specialOffer {
	background: #ffeab1;
	border-radius: $BD_RAD;
	box-sizing: border-box;
	font-size: 11px;
	padding: 5px 8px;
	margin: 0 0 6px;
}
.listItemBuy .price .specialOffer__header {
	color: #e0141d;
	display: block;
	font-weight: bold;
	margin-bottom: 1px;
}
.listItemBuy .specialOffer__content {
	display: block;
	overflow: hidden;
}
.listItemBuy .specialOffer__item + .specialOffer__item {
	margin-top: 3px;
}
.listItemBuy .price .specialOffer .Price {
	font-size: inherit;
	font-weight: normal;
}


.basket--special {
	background: $ATTENTION;
	margin: 0;
	padding-top: 6px;
	padding-bottom: 8px;
}

.ProductSpecial {
	font-size: 11px;
	margin-bottom: 0.2em;
}
.ProductSpecial__header {
	font-weight: bold;
	margin-bottom: .3em;
}
.ProductSpecial__content {
	display: table;
	width: 100%;
	margin-bottom: .3em;
}
.ProductSpecial__item {
	display: table-row;
	transition: 0.3s 0.25s;
}
.ProductSpecial__item--head {
	color: rgba(0, 0, 0, 0.5);
}
.ProductSpecial__item--active {
	background-color: #c22119;
	color: #fff;
}
.ProductSpecial__box {
	display: table-cell;
	line-height: 1;
	padding: 2px 1px 3px;
}
.ProductSpecial .Price {
	.list & {
		font: inherit;
	}
}
.ProductSpecial__condition {
	display: none;
	font-size: 9px;
	white-space: nowrap;

	.ProductSpecial__item--active & {
		display: block;
	}
}

.ProductSpecial__box--head {
	padding: .1em 0 .3em;
}


@define-mixin SPEC_PAD $n {
	.ProductSpecial__box {
		&:first-child {
			padding-left: $n;
		}
		&:last-child {
			padding-right: $n;
		}
	}
	.ProductSpecial {
		margin-left: -$n;
		margin-right: -$n;
	}
	.ProductSpecial__header{
		padding: 0 $n;
	}
	.basket--special {
		margin-left: -$n;
		margin-right: -$n;
		padding-left: $n;
		padding-right: $n;
	}
}

.details {
	@mixin SPEC_PAD 8px;

	.basket--special {
		border-radius: $BD_RAD;
		margin-right: 0;
	}
}

.grid {
	@mixin SPEC_PAD 6px;

	.basket--special {
		margin-bottom: -14px;
		padding-bottom: 14px;
		padding-top: 11px;
	}
}

@media screen and (min-width: 1020px) {
	.grid {
		@mixin SPEC_PAD 8px;
	}
}

@media screen and (min-width: 1100px) {
	.grid {
		@mixin SPEC_PAD 12px;
	}
}

.list {
	.basket {
		.ProductSpecial {
			display: none;
		}

		&--special {
			background: none;
			margin: 0;
			padding: 0;
		}
	}
}

.actionsBadgeDescription {
	@mixin SPEC_PAD 10px;
}

.itemInfoDetails {
	@mixin SPEC_PAD 10px;

	.basket--special {
		border-radius: $BD_RAD;
		margin-left: 0;
		margin-right: 0;
	}
}

.compareItems {
	@mixin SPEC_PAD 15px;

	.basket--special {
		margin-bottom: -15px;
		padding-bottom: 15px;
	}

	.ourOffer--special {
		background: $ATTENTION;
	}
}



/* 4.22. PAGE SPECIFIC — MY CABINET
------------------------------------------------------------------------------*/

.myCabinetInfo.textNode {
	overflow: visible;
}
.myCabinetInfo ul {
	margin-left: -10px;
	padding: 0;
}
.myCabinetInfo li {
	display: inline-block;
	font-weight: bold;
	margin: 0 0 15px 7px;
	text-align: center;
	vertical-align: top;
}

.myCabinetInfo li span {
	background: url('/img/my_cabinet_picts.png?v=07.06.2016') no-repeat;
	display: inline-block;
	height: 64px;
	width: 66px;
}
.myCabinetInfo li strong {
	display: block;
	margin: 10px 0 0;
}
.myCabinetInfo li a {
	display: block;
	font-size: 14px;
	padding: 11px 1px 6px;
	text-align: center;
	width: 153px;
}
.myCabinetInfo li a:hover {
	border: 1px solid #ccc;
	box-shadow: 0 1px 2px rgba(0,0,0,.15);
	padding: 10px 0 5px;
}
.myCabinetInfo li a span.mySettings {
	background-position: 0 0;
}
.myCabinetInfo li a span.myOrders {
	background-position: -66px 0;
}
.myCabinetInfo li a span.myCart {
	background-position: -132px 0;
}
.myCabinetInfo li a span.myProducts {
	background-position: -198px 0;
}
.myCabinetInfo li a span.myFeedback {
	background-position: -264px 0;
}
.myCabinetInfo li a span.myBonus {
	background-position: -329px 0;
}
.myCabinetInfo li:hover a span.mySettings {
	background-position: 0 100%;
}
.myCabinetInfo li:hover a span.myOrders {
	background-position: -66px 100%;
}
.myCabinetInfo li:hover a span.myCart {
	background-position: -132px 100%;
}
.myCabinetInfo li:hover a span.myProducts {
	background-position: -198px 100%;
}
.myCabinetInfo li:hover a span.myFeedback {
	background-position: -264px 100%;
}
.myCabinetInfo li:hover a span.myBonus {
	background-position: -329px 100%;
}



/* 4.23. PAGE SPECIFIC — INNER PAGES LINKS
------------------------------------------------------------------------------*/

.innerPagesLinksInfo ul {
	color: #b2b2b2;
	font-size: 12px;
	font-weight: bold;
}
.innerPagesLinksInfo li {
	margin-bottom: 10px;
}
.innerPagesLinksInfo ul a {
	font-size: 14px;
	margin: 0 0 0 -3px;
}



/* 4.24. PAGE SPECIFIC — JUNCTION PAGES
------------------------------------------------------------------------------*/

.junctionInfo {
	margin: 0 auto 58px;
	padding: 25px 0 0;
	text-align: center;
	width: 70%;
}
.junctionInfo h1 {
	font-size: 29px;
	line-height: 1.4em;
	margin: 0 0 15px;
}
.junctionInfo h2 {
	font-size: 24px;
	margin: 0 0 9px;
}
.junctionInfo p {
	color: #666;
	margin: 0 0 4px;
}
.junctionInfo.titled {
	padding: 0;
}



/* 4.25. PAGE SPECIFIC — NOVELTIES
------------------------------------------------------------------------------*/

.noveltiesHeader {
	clear: left;
	cursor: default;
	font-size: 20px;
	font-weight: bold;
	margin-top: 30px;
	margin-bottom: 15px;
}
.noveltiesHeader:first-child {
	margin-top: 0;
}
.noveltiesHeader .btn {
	font-weight: normal;
	margin-left: 1.5em;
}

.novelties > .more {
	display: block;
	float: right;
	font-size: 14px;
}
.novelties .listItemsWrapper {
	margin: 0 0 30px;
}

.novelties .grid .listItems > li {
	width: 20%;
}



/* 4.26. PAGE SPECIFIC — BONUSFORM
------------------------------------------------------------------------------*/

.bonusForm2012Info fieldset {
	margin: 0 0 16px;
}

.bonusForm2012Info p {
	margin-bottom:20px;
}

.bonusForm2012Info h4 {
	font-weight: bold;
	margin: 0 0 5px;
}

.bonusForm2012Info label span {
	display: block;
	font-weight: bold;
	margin: 0 10px 0 0;
	width: 153px;
}

.bonusForm2012Info .inputtext {
	width: 250px;
}

.bonusForm2012Info textarea {
	height: 125px;
	width: 413px;
}

.bonusForm2012Info .warning {
	color:#e10000;
}

.bonusForm2012Info fieldset input {
	margin-right:5px;
}

.bonusForm2012Info fieldset label {
	margin-bottom:4px;
	display:inline-block;
}



/* 4.27. PAGE SPECIFIC — UNSUBSCRIBE
------------------------------------------------------------------------------*/

.singleWindowWrapper {
	background-color: #fff;
	border-bottom: 2px solid;
	border-color: #bbb;
	border-color: rgba(0,0,0,.3);
	font-size: 14px;
	left: 50%;
	line-height: 1.4;
	margin-top: -132px;
	margin-left: -300px;
	padding: 30px;
	position: absolute;
	text-align: center;
	top: 50%;
	width: 600px;
}
.singleWindowWrapper.unsubscribeWrapper {
	left: 50%;
	margin-top: -132px;
	margin-left: -300px;
	top: 50%;
	width: 600px;
}
.singleWindowWrapper .logo,
.singleWindowWrapper h1,
.singleWindowWrapper .description {
	margin-bottom: 15px;
}
.singleWindowWrapper .logo {
	background-image: url('/img/brand/logo.png?v=25112014');
	display: inline-block;
	height: 62px;
	width: 220px;
}
.singleWindowWrapper h1 {
	font-size: 22px;
}
.singleWindowWrapper.unsubscribeWrapper .btn {
	font-size: 14px;
	font-weight: bold;
	padding: 8px 30px;
	padding-right: 30px;
	padding-left: 30px;
}
.singleWindowWrapper .done {
	color: #468a11;
	font-weight: bold;
}



/* 4.28. PAGE SPECIFIC - PROMO ICON
------------------------------------------------------------------------------*/

.details .itemPromoAction .promoIconWrapper,
.grid .itemPromoAction .promoIconWrapper {
	position: absolute;
}
.details .itemPromoAction .listItemInfo {
	padding-right: 100px;
}
.details .itemPromoAction .promoIconWrapper {
	right: 200px;
	text-align: center;
	top: 0;
	width: 95px;
}
.details .itemPromoAction .promoIconWrapper .actionsBadgeDescription {
	text-align: left;
}
.grid .itemPromoAction .promoIconWrapper {
	left: 0;
	top: -42px;
	width: 100%;
}
.list .promoIconWrapper {
	margin-top: 5px;
	margin-right: 5px;
}

.promoIcon {
	background-image: resolve('interface/promoIcon.png');
	height: height('interface/promoIcon.png');
	background-repeat: no-repeat;
	display: inline-block;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	vertical-align: middle;
}
.promoIcon.bestseller {
	width: 78px;
}
.promoIcon.noveltie {
	background-position: -83px 0;
	width: 82px;
}

.details .itemPromoAction .actionsBadge + .promoIcon {
	margin-top: 10px;
}
.details .itemPromoAction .promoIcon + .promoIcon {
	margin-top: 5px;
}

.details .itemPromoAction .listItemWrapper {
	min-height: 173px;
}



/* 4.29. PAGE SPECIFIC - CUP
------------------------------------------------------------------------------*/

.textNode p {
	line-height: 1.5;
}


.textNode.officemagCup h2 {
	font-size: 24px;
}


.textNode.thin.officemagCup {
	margin: 30px 0 50px;
	overflow: visible;
}
.textNode .officemagCupInfo {
	padding-left: 30px;
	padding-right: 30px;
	min-width: 880px;
}

.officemagCup .descriptionHeader .text,
.officemagCup .tournamentsHeader .text {
	background: url('/img/om_cup.png') no-repeat;
	display: inline-block;
}

.officemagCup .descriptionHeader {
	background-color: #c50017;
	background-image: linear-gradient(#e9001b, #a20013);
	border: 1px solid;
	border-color: #f87780 #b30014 #8c0313 #dd5460;
	box-shadow: 0 25px 25px -7px rgba(123,0,13,.65);
	height: 67px;
	margin-bottom: 40px;
	position: relative;
	text-align: center;
}
.officemagCup .descriptionHeader:before,
.officemagCup .descriptionHeader:after {
	background: url('/img/om_cup.png') no-repeat;
	content: '';
	height: 68px;
	position: absolute;
	top: 14px;
	width: 46px;
}
.officemagCup .descriptionHeader:before {
	background-position: -273px 0;
	margin-right: -13px;
	right: 100%;
}
.officemagCup .descriptionHeader:after {
	background-position: -319px 0;
	margin-left: -13px;
	left: 100%;
}
.officemagCup .descriptionHeader .text {
	background-position: 0 0;
	height: 34px;
	margin: 20px 0 8px;
	width: 273px;
}

.officemagCup .tournamentsHeader {
	text-align: center;
}
.officemagCup .tournamentsHeader .text {
	background-position: 0 -34px;
	height: 33px;
	width: 142px;
}


.officemagCup .logo {
	float: right;
	margin-right: -40px;
	margin-bottom: 30px;
	margin-left: 20px;
}
.officemagCup .descriptionContent p {
	margin-right: 262px;
}

.officemagCup .important {
	font-size: 17px;
	line-height: 22px;
}

.officemagCup .divider {
	border: none;
	border-top: 1px solid #d4d4d4;
	clear: both;
	height: 0;
	margin: 30px 0 50px;
	position: relative;
}
.officemagCup .divider:before {
	background: url('/img/om_cup.png') -145px -35px no-repeat;
	content: '';
	display: block;
	height: 7px;
	left: 50%;
	margin: -4px 0 0 -45px;
	position: absolute;
	top: 50%;
	width: 90px;
}


.officemagCup .tournamentsList {
	list-style-type: none;
	padding: 0;
}
.officemagCup .tournamentsList li {
	margin-top: 30px;
}
.officemagCup .tournamentsList li:first-child {
	margin-top: 0;
}
.officemagCup .tournamentsList .date {
	margin-bottom: 5px;
}
.officemagCup .tournamentsList .link {
	font-size: 18px;
	line-height: 26px;
}

.officemagCup .winners {
	margin: 40px 0 60px;
	box-shadow: 0 25px 26px rgba(0, 0, 0, .15);
	border: 1px solid #c4c4c4;
	width: 346px;
}

.officemagCup .winners .winnersHeader {
	margin: 10px 0 0;
	text-align: center;
}

.officemagCup .winners .winnersList {
	display: block;
	margin: 10px auto 10px;
	width: 220px;
}

.officemagCup .winners:before,
.officemagCup .winners:after {
	background: url('/img/om_cup.png') 0 -68px no-repeat;
	content: '';
	display: block;
	height: 17px;
	margin-top: -17px;
	position: absolute;
	width: 346px;
}

.officemagCup .winners:after {
	background-position: 0 -85px;
	margin-top: 0px;
}

.officemagCup .photos {
	display: block;
	list-style: none;
	margin: 17px 0 0;
	padding: 0;
}

.officemagCup .photos li {
	margin: 0 12px 10px 0;
	display: inline-block;
	border: 1px solid #d9d9d9;
	padding: 4px;
	line-height: 0;
}

.officemagCup .photos img {
	margin: 0;
}

.officemagCup .photos li:hover {
	border: 1px solid #ee1c25;
}



/* 4.30. PAGE SPECIFIC — ACTIONS
------------------------------------------------------------------------------*/

.actionsPreview .actionLink {
	font-size: 14px;
}
.actionsPreview .description {
	display: block;
	font-size: 12px;
	margin: 7px 0;
}
.actionsPreview .description:last-child {
	margin-bottom: 0;
}
.actionsPreview .lifetime {
	color: $C_ADD;
	display: block;
	font-size: 11px;
	margin-top: 5px;
}

.actionsPreview .actionsPreviewItem {
	border-top: 1px dotted #a8a8a8;
	margin-top: 10px;
	padding-top: 10px;
}
.actionsPreview .actionsPreviewItem:first-child {
	border-top: none;
	margin-top: 0;
	padding-top: 0;
}


.actionsBadgeDescription.overlayWrapper .actionLink,
.actionsBadge .actionLink,
.actionsPreviewItem .actionLink {
	color: $BRAND;
	font-size: 13px;
	font-weight: bold;
	padding: .3em 0;

	&:hover {
		color: $BRAND__A;
	}
}


.actionsConditions {
	margin-top: 7px;
}
.actionsConditions .icon.megaphone {
	color: #767676;
	float: left;
	font-size: 11px;
	font-style: italic;
	line-height: 14px;
}
.actionsConditions .lifetime {
	color: $C_ADD;
	display: block;
	font-size: 11px;
	line-height: 16px;
}



.actionsBadge.overlayWrapper__parent.active .actionsBadgeDescription {
	visibility: hidden;
}

.overlayWrapper.actionsBadgeDescription {
	margin-top: 0;
	z-index: 10;
	padding: 5px;

	&:before {
		margin-right: 0;
		right: 45px;
	}
	&--productSpecial {
		&:before {
			right: 70px;
		}
	}
}
.actionsPreview .actionsPreviewItem:first-child {
	padding-right: 10px;
}



/* 4.30.1. PAGE SPECIFIC — ACTIONS — CART
----------------------------------------------------------------------*/

.cartActions {
	margin-bottom: 20px;
}


.cartActions .actionsBanner,
.LearnActionsBanner {
	background: #fff url('/img/misc/actions_banner_bg.png') 0 50% no-repeat;
	height: 85px;
	position: relative;
}
.cartActions .actionsBanner:before,
.LearnActionsBanner:before,
.cartActions .actionsBanner:after,
.LearnActionsBanner:after {
	background: url('/img/misc/actions_banner_people.png') no-repeat;
	content: '';
	position: absolute;
	right: 3.5%;
	z-index: 1;
}
.cartActions .actionsBanner:before,
.LearnActionsBanner:before {
	bottom: 100%;
	height: 53px;
	margin-right: 125px;
	width: 255px;
}
.cartActions .actionsBanner:after,
.LearnActionsBanner:after {
	background-position: 0 -53px;
	bottom: 0;
	height: 85px;
	width: 380px;
}

.cartActions .actionsBanner .btnAction,
.LearnActionsBanner .btnAction {
	margin-top: -15px;
	right: 20px;
	position: absolute;
	top: 50%;
	z-index: 2;
}


.ddTip.active + .cartActions .actionsBanner {
	margin-top: 70px;
}


.cartActions .icon,
.actionsPreview .icon {
	margin-right: .4em;
}

.cartActionsHeader {
	border-bottom: 1px solid $B_ACTION;
	color: $BRAND;
	font-size: 15px;
	padding: 12px 15px;

	.icon.actionGift {
		background-image: resolve('interface/gift_brand.png');
	}
}
.cartActionsContent {
	border: 1px solid $BRAND;
	border-radius: $BD_RAD;
}

.pseudoTable .cartActionsItem.promocode { /* ^ DELETE THIS BLOCK (not used) */
	/*background: url('/img/promocode_background.png');*/
	border-top: 0 none;
	padding: 0 15px;
}

.pseudoTable .cartActionsItem.promocode, /* ^ DELETE THIS BLOCK (not used) */
.pseudoTable .cartActionsItem.promocode:nth-child(2n) {
	/*background: url('/img/promocode_background.png');*/
	border-top: 0 none;
	padding: 0 15px;
}

.pseudoTable .cartActionsItem.promocode + li {
	border-top: 0 none;
}

.actionsPreview .cartActionsItem.promocode .lifetime,
.actionsPreview .cartActionsItem.promocode .condition {
	color: #0e0e0e;
}

.actionsPreview .cartActionsItem.promocode .actionLink {
	color: #c70009;
}

.cartActions.orders {
	width: 940px;
}

.pseudoTable .cartActionsItem,
.pseudoTable .cartActionsItem:nth-child(2n) {
	background: #fff;
}
.pseudoTable .cartActionsItem,
.actionsPreview .actionsPreviewItem {
	border-top: 1px solid $B_ACTION;
}
.pseudoTable .cartActionsItem:first-child,
.actionsPreview .actionsPreviewItem:first-child {
	border-top: none;
}

.cartActionsContent .header,
.cartActionsItem {
	box-sizing: border-box;
	padding: 1px 15px;
	width: 100%;
}

.cartActionsContent .header {
	border-bottom: 1px solid $B_ACTION;
	color: $BRAND;
	display: block;
	font-size: 15px;
	margin-top: 3px;
	padding-top: 7px;
	padding-bottom: 7px;
	text-shadow: 1px 1px 0 #fff;

	& > .actionGift {
		background-image: resolve('interface/gift_brand.png');
		height: height('interface/gift_brand.png');
		width: width('interface/gift_brand.png');
		margin-right: .2em;
	}
}

.Page--actionsItem .cartActionsContent {
	border-color: $B_ACTION;
}
.Page--actionsItem .cartActionsContent .header,
.cartActionsItem {
	display: table;
	width: 100%;
}
.Page--actionsItem .cartActionsContent .header,
.Page--actionsItem .cartActionsItem {
	padding: 0;
}
.Page--actionsItem .cartActionsContent .header {
	color: $C_ADD;
	font-size: 13px;
}

.cartActionsList .col {
	line-height: 1.4;
	padding: 10px 15px;
	vertical-align: top;
	word-wrap: break-word;
}
.cartActionsList .col.name {
	padding-left: 0;
	width: 40%;

	.actionLink {
		padding-top: .3em;
		padding-bottom: .3em;
	}
}
.cartActionsList .col.summ {
	width: 15%;
}
.cartActionsList .col.profit {
	width: 40%;
}
.cartActionsList .col.progress,
.actionGiftMoreContent--info .col.progress {
	padding-right: 0;
	text-align: center;
	width: 20%;
}

.cartActionsItem .profitPhoto {
	float: left;
	position: relative;
}

.cartActionsItem .count {
	background-color: #429600;
	border-radius: $BD_RAD;
	color: #fff;
	display: inline-block;
	font-size: 10px;
	font-weight: bold;
	line-height: 1;
	padding: 3px 5px;
}
.cartActionsItem .countWrapper {
	display: block;
	margin-top: 3px;
}

.cartActionsItem .photo {
	height: 48px;
	width: 48px;
}
.cartActionsItem .item {
	display: block;
	font-size: 11px;
}
.cartActionsItem .itemCount {
	color: #777;
	font-size: 11px;
	display: block;
}
.cartActionsItem .profitPhoto + .item {
	margin-left: 55px;
}

.cartActionsItem .condition,
.actionGiftMoreContent--info .condition {
	color: $C_ADD;
	display: block;
	font-size: 11px;
	line-height: 13px;
}
.cartActionsItem .goal {
	display: block;
}


.cartActionsItem .actionGiftMore {
	clear: left;
	margin-top: 1em;
}
.actionGiftMore.Bubble .actionGiftMoreContent {
	background-color: $BG;
	border-radius: $BD_RAD;
	display: inline-block;
	padding: 4px;
}

.actionGiftMore.Bubble .actionGiftMoreContent .pseudoLink {
	font-weight: bold;
	display: inline-block;
}

.cartActionsItem .Bubble.bottom .BubbleContent:before {
	background-position: 30% 100%;
}
.cartActionsItem .actionGiftMore .BubbleContent {
	padding: 10px 12px;
	width: 160.75%;
}
.actionGiftMoreContent--info {
	width: 550px;
	padding: 5px 2px;
}

.cartActionsItem .actionGiftMore .BubbleContent .col,
.actionGiftMoreContent--info .col {
	padding-bottom: 0;
}
.cartActionsItem .actionGiftMore .BubbleContent .col.profit,
.actionGiftMoreContent--info .col.profit{
	width: 80%;
}
.cartActionsItem .actionGiftMore .BubbleContent .col.progress,
.actionGiftMoreContent--info .col.progress {
	width: 20%;
	display: inline-block;
	padding-top: 10px;
}

.actionGiftMoreContent--info .col.profit {
	line-height: 1.4;
	padding-top: 10px;
	vertical-align: top;
	display: inline-block;
}

.actionStageTransition {
	display: inline-block;
	margin-right: .5em;
	vertical-align: top;
}
.actionGiftMoreContent .actionStageTransition {
	vertical-align: middle;
}
.actionStageTransition .photo {
	height: 35px;
	vertical-align: middle;
	width: 35px;
}
.actionStageTransition .icon {
	margin-left: .4em;
}

.BubbleContent .item,
.actionGiftMoreContent--info .item {
	display: inline-block;
	font-size: 12px;
	width: 75%;
	word-wrap: break-word;
}

.actionGiftMore .BubbleContent .col.profit {
	padding-left: 110px;
}
.actionGiftMore .BubbleContent .actionStageTransition {
	margin-left: -110px;
}

.cartActions .col.profit .actionGiftMoreContent {
	min-width: 250px;
}

.cartActionsItem .actionGiftMore .BubbleContentHeader,
.actionGiftMoreContent--info .BubbleContentHeader {
	font-size: 13px;
	font-weight: bold;
	line-height: 1.4;
}


/* 4.30.2. PAGE SPECIFIC — ACTIONS — ITEMS LIST
----------------------------------------------------------------------*/

.actionsBadge {
	position: relative;
}

.actionsBadgeDescription {
	background-color: #fff;
	border-radius: $BD_RAD;
	padding: 5px 0;
	width: 200px;
	z-index: 3;

	.TipTip__content & {
		background-color: transparent;
	}
}

.actionsBadgeDescription.noWrapperLabel:before {
	display: none;
}
.actionsBadge.active .actionsBadgeDescription {
	display: block;
}

.details .actionsBadgeDescription,
.grid .actionsBadgeDescription {
	margin-right: 0;
	right: -10px;
}
.details .actionsBadgeDescription {
	right: -10px;
}
.grid .actionsBadgeDescription {
	right: -22px;
}
.details .actionsBadgeDescription:before,
.grid .actionsBadgeDescription:before {
	margin-right: 0;
}
.details .actionsBadgeDescription:before,
.grid .actionsBadgeDescription:before {
	right: 45px;
}


.actionsBadge {
	.badgeLink {
		border-bottom: 1px dotted;
		color: $BRAND;
		cursor: pointer;
		display: inline-block;
		font-size: 13px;
		line-height: 1.1;
		margin-right: .3em;
	}

	&:hover {
		.badgeLink {
			color: $BRAND__A;
		}
	}
}
.list .actionsBadge .badgeLink:hover {
	text-decoration: none;
}
.details .actionsBadge .badgeLink {
	display: none;
}
.grid .actionsBadge .badgeLink {
	display: none;
}

@define-mixin actionsBadgePic {
	background-image: resolve('interface/action_badge.png');
	height: height('interface/action_badge.png');
	width: width('interface/action_badge.png');

	background-repeat: no-repeat;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
}
.actionsBadgePic {
	@mixin actionsBadgePic;
}
.actionsBadgePic:hover {
	background-image: resolve('interface/action_badge_h.png');
}
@define-mixin actionsBadgePic-small {
	background-image: resolve('interface/action_badge_s.png');
	height: height('interface/action_badge_s.png');
	width: width('interface/action_badge_s.png');
}
.actionsBadgePic.small {
	@mixin actionsBadgePic-small;
}
@define-mixin actionsBadgePic-small-hover {
	background-image: resolve('interface/action_badge_s_h.png');
}
.actionsBadgePic.small:hover {
	@mixin actionsBadgePic-small-hover;
}
@define-mixin actionsBadgePic-xsmall {
	background-image: resolve('interface/gift_brand.png');
	height: height('interface/gift_brand.png');
	width: width('interface/gift_brand.png');
}
.actionsBadgePic.xsmall {
	@mixin actionsBadgePic-xsmall;
}
@define-mixin actionsBadgePic-xsmall-hover {
	background-image: resolve('interface/gift_brand_a.png');
}
.actionsBadgePic.xsmall:hover {
	@mixin actionsBadgePic-xsmall-hover;
}

@define-mixin actionsBadgePic-discount {
	background-image: resolve('interface/discount_badge.png');
	height: height('interface/discount_badge.png');
	width: width('interface/discount_badge.png');
}
.actionsBadgePic.discount {
	@mixin actionsBadgePic-discount;
}
.actionsBadgePic.discount:hover {
	background-image: resolve('interface/discount_badge_h.png');
}
@define-mixin actionsBadgePic-small-discount {
	background-image: resolve('interface/discount_badge_s.png');
	height: height('interface/discount_badge_s.png');
	width: width('interface/discount_badge_s.png');
}
.actionsBadgePic.small.discount {
	@mixin actionsBadgePic-small-discount;
}
@define-mixin actionsBadgePic-small-discount-hover {
	background-image: resolve('interface/discount_badge_s_h.png');
}
.actionsBadgePic.small.discount:hover {
	@mixin actionsBadgePic-small-discount-hover;
}
@define-mixin actionsBadgePic-xsmall-discount {
	background-image: resolve('interface/percent_brand.png');
	height: height('interface/percent_brand.png');
	width: width('interface/percent_brand.png');
}
.actionsBadgePic.xsmall.discount {
	@mixin actionsBadgePic-xsmall-discount;
}
@define-mixin actionsBadgePic-xsmall-discount-hover {
	background-image: resolve('interface/percent_brand_a.png');
}
.actionsBadgePic.xsmall.discount:hover {
	@mixin actionsBadgePic-xsmall-discount-hover;
}

@define-mixin actionsBadgePic-sale {
	background-image: resolve('interface/sale_badge.png');
	height: height('interface/sale_badge.png');
	width: width('interface/sale_badge.png');

	background-position: 50% 50%;
}
.actionsBadgePic.sale {
	@mixin actionsBadgePic-sale;
}
@define-mixin actionsBadgePic-sale-hover {
	background-image: resolve('interface/sale_badge_h.png');
}
.actionsBadgePic.sale:hover {
	@mixin actionsBadgePic-sale-hover;
}
@define-mixin actionsBadgePic-small-sale {
	background-image: resolve('interface/sale_badge_s.png');
	height: height('interface/sale_badge_s.png');
	width: width('interface/sale_badge_s.png');
}
.actionsBadgePic.small.sale {
	@mixin actionsBadgePic-small-sale;
}
@define-mixin actionsBadgePic-small-sale-hover {
	background-image: resolve('interface/sale_badge_s_h.png');
}
.actionsBadgePic.small.sale:hover {
	@mixin actionsBadgePic-small-sale-hover;
}
@define-mixin actionsBadgePic-xsmall-sale {
	background-image: resolve('interface/sale_badge_xs.png');
	height: height('interface/sale_badge_xs.png');
	width: width('interface/sale_badge_xs.png');
}
.actionsBadgePic.xsmall.sale {
	@mixin actionsBadgePic-xsmall-sale;
}
@define-mixin actionsBadgePic-xsmall-sale-hover {
	background-image: resolve('interface/sale_badge_xs_h.png');
	height: height('interface/sale_badge_xs_h.png');
	width: width('interface/sale_badge_xs_h.png');
}
.actionsBadgePic.xsmall.sale:hover {
	@mixin actionsBadgePic-xsmall-sale-hover;
}

@define-mixin actionsBadgePic-xsmall-buyMore {
	background-image: resolve('interface/buy_more_brand.png');
	height: height('interface/buy_more_brand.png');
	width: width('interface/buy_more_brand.png');
}
.actionsBadgePic.xsmall.buyMore {
	@mixin actionsBadgePic-xsmall-buyMore;
}
@define-mixin actionsBadgePic-xsmall-buyMore-hover {
	background-image: resolve('interface/buy_more_brand_a.png');
	height: height('interface/buy_more_brand_a.png');
	width: width('interface/buy_more_brand_a.png');
}
.actionsBadgePic.xsmall.buyMore:hover {
	@mixin actionsBadgePic-xsmall-buyMore-hover;
}

@define-mixin actionsBadgePic-getAGift {
	background-image: resolve('interface/get_a_gift_badge.png');
	height: height('interface/get_a_gift_badge.png');
	width: width('interface/get_a_gift_badge.png');
}
.actionsBadgePic.getAGift {
	@mixin actionsBadgePic-getAGift;
}
.actionsBadgePic.getAGift:hover {
	background-image: resolve('interface/get_a_gift_badge_h.png');
	height: height('interface/get_a_gift_badge_h.png');
	width: width('interface/get_a_gift_badge_h.png');
}
@define-mixin actionsBadgePic-small-getAGift {
	background-image: resolve('interface/get_a_gift_badge_s.png');
	height: height('interface/get_a_gift_badge_s.png');
	width: width('interface/get_a_gift_badge_s.png');
}
.actionsBadgePic.small.getAGift {
	@mixin actionsBadgePic-small-getAGift;
}
@define-mixin actionsBadgePic-small-getAGift-hover {
	background-image: resolve('interface/get_a_gift_badge_s_h.png');
	height: height('interface/get_a_gift_badge_s_h.png');
	width: width('interface/get_a_gift_badge_s_h.png');
}
.actionsBadgePic.small.getAGift:hover {
	@mixin actionsBadgePic-small-getAGift-hover;
}
@define-mixin actionsBadgePic-xsmall-getAGift {
	background-image: resolve('interface/get_a_gift_badge_xs.png');
	height: height('interface/get_a_gift_badge_xs.png');
	width: width('interface/get_a_gift_badge_xs.png');
}
.actionsBadgePic.xsmall.getAGift {
	@mixin actionsBadgePic-xsmall-getAGift;
}
@define-mixin actionsBadgePic-xsmall-getAGift-hover {
	background-image: resolve('interface/get_a_gift_badge_xs_h.png');
	height: height('interface/get_a_gift_badge_xs_h.png');
	width: width('interface/get_a_gift_badge_xs_h.png');
}
.actionsBadgePic.xsmall.getAGift:hover {
	@mixin actionsBadgePic-xsmall-getAGift-hover;
}

@define-mixin actionsBadgePic-priceWeek {
	background-image: resolve('interface/priceWeek_badge.png');
	height: height('interface/priceWeek_badge.png');
	width: width('interface/priceWeek_badge.png');
	transform: translateX(10px) translateY(-10px);
}
.actionsBadgePic.priceWeek {
	@mixin actionsBadgePic-priceWeek;
}
.actionsBadgePic.priceWeek:hover {
	background-image: resolve('interface/priceWeek_badge_h.png');

	.actionsBadgeText { color: #fe0030; }
}

@define-mixin actionsBadgePic-small-priceWeek {
	background-image: resolve('interface/priceWeek_badge_s.png');
	height: height('interface/priceWeek_badge_s.png');
	width: width('interface/priceWeek_badge_s.png');
	transform: translateX(0) translateY(0);
}
.actionsBadgePic.small.priceWeek {
	@mixin actionsBadgePic-small-priceWeek;
}
@define-mixin actionsBadgePic-small-priceWeek-hover {
	background-image: resolve('interface/priceWeek_badge_s_h.png');
}
.actionsBadgePic.small.priceWeek:hover {
	@mixin actionsBadgePic-small-priceWeek-hover;
}

@define-mixin actionsBadgePic-xsmall-priceWeek {
	background-image: resolve('interface/priceWeek_badge_xs.png');
	height: height('interface/priceWeek_badge_xs.png');
	width: width('interface/priceWeek_badge_xs.png');
	transform: translateX(0) translateY(0);
}
.actionsBadgePic.xsmall.priceWeek {
	@mixin actionsBadgePic-xsmall-priceWeek;
}
@define-mixin actionsBadgePic-xsmall-priceWeek-hover {
	background-image: resolve('interface/priceWeek_badge_xs_h.png');
}
.actionsBadgePic.xsmall.priceWeek:hover {
	@mixin actionsBadgePic-xsmall-priceWeek-hover;
}


.actionsBadgeText {
	display: block;
	line-height: 1em;
	text-align: center;
}
.actionsBadgePic.priceWeek .actionsBadgeText {
	color: #d00406;
	font-size: 17px;
	font-weight: bold;
	padding-top: 50px;
	text-indent: 1px;
	transform: rotate(-35deg) translateX(-12px) translateY(4px);
}

.actionsBadgePic.discount .actionsBadgeText {
	color: #fff;
	display: none;
	font-size: 18px;
	font-weight: bold;
	padding-top: 28px;
	text-indent: -1px;
}
.actionsBadgePic.discount .actionsBadgeText.easy {
	font-size: 35px;
	padding-top: 26px;
	text-indent: -4px;
}
.listItems .actionsBadgePic.discount .actionsBadgeText,
.itemInfoPhotos .actionsBadgePic.discount .actionsBadgeText,
.compareItems .actionsBadgePic.discount .actionsBadgeText {
	display: block;
}

.grid .actionsBadge {
	right: 0;
	position: absolute;
	top: -127px;
}
.actionsBadgeTrigger {
	display: block;
	text-align: center;
}
.actionsBadgeTrigger .pseudoLink {
	color: #666;
	border-bottom-color: #666;
	font-size: 11px;
}
.actionsBadge:hover .pseudoLink {
	color: #e12129;
	border-bottom-color: #e12129;
}

.itemInfoPhotos .main .actionsBadgePic.priceWeek,
.compareItems .actionsBadgePic.priceWeek,
.details .actionsBadgePic.priceWeek,
.grid .actionsBadgePic.priceWeek {
	.ielte8 & {
		background-image: resolve('interface/priceWeek_badge_v.png');
		height: height('interface/priceWeek_badge_v.png');
		width: width('interface/priceWeek_badge_v.png');
	}
}
.compareItems .actionsBadgePic.priceWeek,
.details .actionsBadgePic.priceWeek,
.grid .actionsBadgePic.priceWeek {
	.ielte8 &:hover {
		background-image: resolve('interface/priceWeek_badge_v_h.png');
	}
}

.grid .actionsBadgeTrigger,
.grid .actionsBadgeDescription {
	display: none;
}

/*.list .actionsBadgePic,
.list .actionsBadgeTrigger,
.list .actionsBadgeDescription {
	display: none;
}*/

.listCartItem .actionsBadgePic.discount .actionsBadgeText,
.list .actionsBadgePic.discount .actionsBadgeText {
	display: none;
}

.listCartItem .actionsBadgePic.priceWeek .actionsBadgeText,
.list .actionsBadgePic.priceWeek .actionsBadgeText {
	display: none;
}

.listCartItem .actionsBadge,
.list .actionsBadge {
	display: inline-block;
}

.listCartItem .actionsBadge {
	margin-top: 7px;
}

.listCartItem .actionsBadgePic,
.list .actionsBadgePic {
	@mixin i-inline;
	@mixin actionsBadgePic-xsmall;
	margin-top: -.4em;
	padding-right: .2em;
}
.listCartItem .actionsBadge:hover .actionsBadgePic,
.list .actionsBadge:hover .actionsBadgePic {
	@mixin actionsBadgePic-xsmall-hover;
}

.listCartItem .actionsBadgePic.discount,
.list .actionsBadgePic.discount {
	@mixin actionsBadgePic-xsmall-discount;
}
.listCartItem .actionsBadge:hover .actionsBadgePic.discount,
.list .actionsBadge:hover .actionsBadgePic.discount {
	@mixin actionsBadgePic-xsmall-discount-hover;
}

.listCartItem .actionsBadgePic.sale,
.list .actionsBadgePic.sale {
	@mixin actionsBadgePic-xsmall-sale;
}
.listCartItem .actionsBadge:hover .actionsBadgePic.sale,
.list .actionsBadge:hover .actionsBadgePic.sale {
	@mixin actionsBadgePic-xsmall-sale-hover;
}

.listCartItem .actionsBadgePic.buyMore,
.list .actionsBadgePic.buyMore {
	@mixin actionsBadgePic-xsmall-buyMore;
}
.listCartItem .actionsBadge:hover .actionsBadgePic.buyMore,
.list .actionsBadge:hover .actionsBadgePic.buyMore {
	@mixin actionsBadgePic-xsmall-buyMore-hover;
}

.listCartItem .actionsBadgePic.getAGift,
.list .actionsBadgePic.getAGift {
	@mixin actionsBadgePic-xsmall-getAGift;
}
.listCartItem .actionsBadge:hover .actionsBadgePic.getAGift,
.list .actionsBadge:hover .actionsBadgePic.getAGift {
	@mixin actionsBadgePic-xsmall-getAGift-hover;
}

.listCartItem .actionsBadgePic.priceWeek,
.list .actionsBadgePic.priceWeek {
	@mixin actionsBadgePic-xsmall-priceWeek;
}
.listCartItem .actionsBadge:hover .actionsBadgePic.priceWeek,
.list .actionsBadge:hover .actionsBadgePic.priceWeek {
	@mixin actionsBadgePic-xsmall-priceWeek-hover;
}


.listCartPrice .actionsBadgeDescription {
	margin: 0;
	right: 0;
	margin-top: 10px;
}

.listCartPrice .actionsBadgeDescription .plainText {
	display: block;
	padding: 0 10px 0 0;
}

.listCartPrice .actionsBadgeDescription:before {
	right: 105px;
}

.Actions {
	display: table;
	width: 100%;
}

.Actions__box {
	display: table-cell;
	vertical-align: top;
}

.Actions__box + .Actions__box {
	padding-left: 17px;
}

.Actions__box--filter {
	width: 220px;
}

.AccentFilter,
.ActionsFilter {
	border-radius: $BD_RAD;
	border: 1px solid $BRAND;

	&__header {
		background: $BRAND;
		color: #fff;
		display: block;
		font-size: 15px;
		line-height: 1em;
		padding: 15px;
	}
}

.rubricExplorerSide.actionsRubrics {
	border: 0 none;
	border-top: 1px solid $B_ACTION;
	font-weight: normal;
	margin: 0;
}

.rubricExplorerSide.actionsRubrics .root {
	padding: 0;
}

.EmployeeSearch {
	margin-bottom: 22px;
}

.EmployeeSearch .Form__set {
	padding-bottom: 10px;
	padding-top: 10px;
}

.EmployeeSearch .Form__input {
	display: inline-block;
	width: 190px;
}

.EmployeeSearch .btn {
	display: inline-block;
	margin-left: 10px;
}

.EmployeeSearch__error {
	color: $BRAND;
	display: block;
	margin-top: 30px;
}



/* 4.30.3. PAGE SPECIFIC — ACTIONS — ITEM CARD
----------------------------------------------------------------------*/

.info .itemInfoDetailsActions a {
	margin-top: 0;
}

.itemInfoDetailsActions {
	border: 1px solid $BRAND;
	border-radius: 5px;
	margin-bottom: 15px;
}

.itemInfoDetailsActions .actionsPreview {
	padding: 10px;
}
.itemInfoDetailsActions .actionsPreviewItem {
	box-sizing: border-box;
	display: inline-block;
	line-height: 1.5em;
	position: relative;
	width: 100%;
}
.itemInfoDetailsActions .actionsPreviewItem .preview {
	float: left;
	height: auto;
	margin-right: 2%;
	width: 20%;
}

.itemInfoDetailsActions .actionsPreview .actionLink {
	color: $BRAND;
	display: inline;
	font-size: 13px;

	&:hover {
		color: $BRAND__A;
	}
}
.itemInfoDetailsActions .actionsPreview .description {
	color: $C_ADD;
	font-size: 13px;
}

.itemInfoDetailsActions .actionsPreview .actionName,
.itemInfoDetailsActions .actionsPreview .description,
.itemInfoDetailsActions .actionsPreview .lifetime {
	display: block;
	margin-left: 22%;

	&--noMargin {
		margin-left: 0;
	}
}

.itemInfoPhotos .main .actionsBadgePic {
	right: 0;
	position: absolute;
	top: 0;

	&:hover {
		@mixin actionsBadgePic;
	}
}
.itemInfoPhotos .main .actionsBadgePic.priceWeek {
	&:hover {
		@mixin actionsBadgePic-priceWeek;
	}

	.actionsBadgeText {
		color: #d00406;
	}
}
.itemInfoPhotos .main .actionsBadgePic.discount {
	&:hover {
		@mixin actionsBadgePic-discount;
	}
}
.itemInfoPhotos .main .actionsBadgePic.sale {
	&:hover {
		@mixin actionsBadgePic-sale;
	}
}
.itemInfoPhotos .main .actionsBadgePic.getAGift {
	&:hover {
		@mixin actionsBadgePic-getAGift;
	}
}

.listCartItemChoose {
	position: relative;
}
.relatedItemsList .thumb .actionsBadge,
.listCartItemChoose .actionsBadge,
.listItemBubble.relatedItem .actionsBadge {
	position: absolute;
	top: -5px;
}
.relatedItemsList .thumb .actionsBadgeTrigger,
.listCartItemChoose .actionsBadgeTrigger,
.listItemBubble.relatedItem .actionsBadgeTrigger {
	display: none;
}
.relatedItemsList .thumb .badgeLink,
.listCartItemChoose .badgeLink,
.listItemBubble.relatedItem .badgeLink {
	display: none;
}

.relatedItemsList .thumb .actionsBadgePic,
.listCartItemChoose .actionsBadgePic,
.listItemBubble.relatedItem .actionsBadgePic {
	@mixin actionsBadgePic-small;
}

.relatedItemsList .thumb .actionsBadgePic.discount,
.listCartItemChoose .actionsBadgePic.discount,
.listItemBubble.relatedItem .actionsBadgePic.discount {
	@mixin actionsBadgePic-small-discount;
}

.relatedItemsList .thumb .actionsBadgePic.sale,
.listCartItemChoose .actionsBadgePic.sale,
.listItemBubble.relatedItem .actionsBadgePic.sale {
	@mixin actionsBadgePic-small-sale;
}

.relatedItemsList .thumb .actionsBadgePic.getAGift,
.listCartItemChoose .actionsBadgePic.getAGift,
.listItemBubble.relatedItem .actionsBadgePic.getAGift {
	@mixin actionsBadgePic-small-getAGift;
}

.relatedItemsList .thumb .actionsBadgePic.priceWeek,
.listCartItemChoose .actionsBadgePic.priceWeek,
.listItemBubble.relatedItem .actionsBadgePic.priceWeek {
	@mixin actionsBadgePic-small-priceWeek;
}

.relatedItemsList .thumb .actionsBadge,
.listItemBubble.relatedItem .actionsBadge {
	left: -5px;
}
.relatedItemsList .actionsBadgeDescription {
	margin-top: 6px;
	margin-right: -58px;
}
.relatedItemsList .actionsBadgeDescription:before {
	margin-right: 0;
	right: 26px;
}
.listCartItemChoose .actionsBadge {
	right: 22px;
	top: -9px;
}
.listCartItemChoose .actionsBadgeDescription {
	left: 0;
	margin-right: 0;
	margin-left: -27px;
	top: 31px;
}
.listCartItemChoose .actionsBadgeDescription:before {
	margin-right: 0;
	margin-left: 23px;
	right: auto;
}



/* 4.30.4. PAGE SPECIFIC — ACTIONS — LIST
----------------------------------------------------------------------*/

.actionsListWrapper {
	margin-top: 21px;
}

.actionsListWrapper .emptyHeader {
	font-size: 29px;
	color: #777;
	text-align: center;
	margin: 60px 0 100px;
}

.actionsTypeControl {
	border-bottom: 1px solid $B_ACTION;
	cursor: default;
	margin-bottom: 1.5em;
	padding-bottom: 1.5em;
}

.actionsTypeControl .CheckSet__item.gift .i-inline {
	background-image: resolve('interface/gift_action.png');
	height: height('interface/gift_action.png');
	width: width('interface/gift_action.png');
	margin-top: -.4em;
	margin-right: .4em;
}
.actionsTypeControl .CheckSet__item.gift:hover .i-inline,
.actionsTypeControl .CheckSet__item.gift:focus .i-inline {
	background-image: resolve('interface/gift_brand_a.png');
}
.actionsTypeControl .CheckSet__item.gift.active .i-inline,
.actionsTypeControl .CheckSet__item.gift.active:hover .i-inline {
	background-image: resolve('interface/gift_black.png');
}

.actionsTypeControl .CheckSet__item.sale .i-inline {
	background-image: resolve('interface/percent_action.png');
	height: height('interface/percent_action.png');
	width: width('interface/percent_action.png');
	margin-right: .4em;
}
.actionsTypeControl .CheckSet__item.sale:hover .i-inline,
.actionsTypeControl .CheckSet__item.sale:focus .i-inline {
	background-image: resolve('interface/percent_brand_a.png');
}
.actionsTypeControl .CheckSet__item.sale.active .i-inline,
.actionsTypeControl .CheckSet__item.sale.active:hover .i-inline {
	background-image: resolve('interface/percent_black.png');
}

.actionsListType {
	font-size: 16px;
	margin-bottom: 20px;
}

.actionsListType__link {
	color: inherit;

	&:hover {
		color: inherit;
		text-decoration: none;
	}
}
.actionsListType__item.active {

	&,
	.actionsListType__link {
		cursor: default;
		pointer-events: none;
	}
}

@media screen and (max-width: 1150px) {
	.actionsListType__item .icon {
		display: none !important;
	}
}

.ielte8 .actionsListType {
	font-size: 14px;
}

.actionsListType__item.priceWeek {
	.icon {
		background-image: resolve('interface/price_week.png');
		height: height('interface/price_week.png');
		width: width('interface/price_week.png');
		margin-top: -7px;
	}
	&:hover .icon,
	&.hover .icon {
		background-image: resolve('interface/price_week_a.png');
		height: height('interface/price_week_a.png');
		width: width('interface/price_week_a.png');
	}
	&.active .icon {
		background-image: resolve('interface/price_week_white.png');
		height: height('interface/price_week_white.png');
		width: width('interface/price_week_white.png');
	}
}

.actionsListType__item.hotGifts {
	.icon {
		background-image: resolve('interface/hot_presents_brand.png');
		height: height('interface/hot_presents_brand.png');
		width: width('interface/hot_presents_brand.png');
	}
	&:hover .icon,
	&.hover .icon {
		background-image: resolve('interface/hot_presents_brand_a.png');
		height: height('interface/hot_presents_brand_a.png');
		width: width('interface/hot_presents_brand_a.png');
	}
	&.active .icon {
		background-image: resolve('interface/hot_presents_white.png');
		height: height('interface/hot_presents_white.png');
		width: width('interface/hot_presents_white.png');
	}
}

.actionsListType__item.hundredPresents {
	.icon {
		background-image: resolve('interface/two_gifts_brand.png');
		height: height('interface/two_gifts_brand.png');
		width: width('interface/two_gifts_brand.png');
	}
	&:hover .icon,
	&.hover .icon {
		background-image: resolve('interface/two_gifts_brand_a.png');
		height: height('interface/two_gifts_brand_a.png');
		width: width('interface/two_gifts_brand_a.png');
	}
	&.active .icon {
		background-image: resolve('interface/two_gifts_white.png');
		height: height('interface/two_gifts_white.png');
		width: width('interface/two_gifts_white.png');
	}
}

.actionsListType__item.benefitBuy {
	.icon {
		background-image: resolve('interface/percent_brand.png');
		height: height('interface/percent_brand.png');
		width: width('interface/percent_brand.png');
	}
	&:hover .icon,
	&.hover .icon {
		background-image: resolve('interface/percent_brand_a.png');
		height: height('interface/percent_brand_a.png');
		width: width('interface/percent_brand_a.png');
	}
	&.active .icon {
		background-image: resolve('interface/percent_white.png');
		height: height('interface/percent_white.png');
		width: width('interface/percent_white.png');
	}
}

.actionsListType__item.buyMore {
	.icon {
		background-image: resolve('interface/buy_more_brand.png');
		height: height('interface/buy_more_brand.png');
		width: width('interface/buy_more_brand.png');
	}
	&:hover .icon,
	&.hover .icon {
		background-image: resolve('interface/buy_more_brand_a.png');
		height: height('interface/buy_more_brand_a.png');
		width: width('interface/buy_more_brand_a.png');
	}
	&.active .icon {
		background-image: resolve('interface/buy_more_white.png');
		height: height('interface/buy_more_white.png');
		width: width('interface/buy_more_white.png');
	}
}

.actionsPreview + .listItemsPageControl {
	border-bottom: none;
	clear: both;
	padding-bottom: 0;
}

.actionsList {
	margin-bottom: 10px;
}

.actionsList .actionsPreviewItem {
	@mixin groupIB;
	border-top: 1px solid;
	border-bottom: 1px solid;
	border-color: $B_ACTION;
	margin: -1px 0 0;
	padding: 15px 0;
	width: 100%;
}
.actionsList .actionsPreviewItem:first-child {
	border-top-width: 0;
	padding-top: 15px;
}
.actionsList .actionsPreviewItem:last-child {
	border-bottom-width: 0;
}

.actionsList .actionsPreviewItemContent,
.actionsList .actionName {
	margin-left: 210px;
	width: 70%;
}
.actionsList .actionsPreviewItemContent {
	margin-top: 7px;
}

.actionsList .actionLink {
	font-size: 17px;
}
.actionsList .actionName {
	display: block;
	margin-top: 10px;
}
.actionsList .preview {
	float: left;
	height: 180px;
	margin-right: 30px;
	width: 180px;
}
.actionsList .description {
	color: #333;
	font-size: 15px;
}

.actionsList .btnAction {
	margin-top: 15px;
}

.actionGoodsListWrapper {
	float: left;
	margin-top: 15px;
	margin-bottom: 15px;
	width: 100%;
}
.actionGoodsListHeader {
	background-color: $BG;
	display: table;
	font-size: 15px;
	padding: 15px;
	width: 100%;
}
.actionGoodsListHeader__box {
	display: table-cell;
}
.actionGoodsListHeader__box.title span {
	display: none;
}
.actionGoodsListHeader__box.seeAll {
	font-size: 13px;
	text-align: right;
}
.actionGoodsListHeader span {
	font-weight: normal;
}
.actionGoodsList {
	float: left;
	margin-bottom: 10px;
	width: 100%;
}
.listItems.actionGoodsList {
	margin-bottom: 10px;
}
.listItems.actionGoodsList .listItemInfo .name {
	font-size: 13px;
}
.listItems.actionGoodsList .listItemInfo .code {
	color: #000;
}
.actionGoodsList .listItemInfo {
	margin-right: 10px;
}


.UnauthorizedUser {
	box-sizing: border-box;
	font-size: 12px;
	padding: 15px;
}

.UnauthorizedUser__links {
	margin: 8px 0 0;
}

.UnauthorizedUser__links a {
	font-weight: bold;
}


.NotExistedAction {
	margin-bottom: 30px;
}

.NotExistedAction .LearnActionsBanner {
	margin-top: 53px;
}

.NotExistedAction__name {
	font-weight: bold;
}



/* 4.30.5. PAGE SPECIFIC — ACTIONS — ITEM
----------------------------------------------------------------------*/

.Page--actionsItem .contentDescription {
	color: #363535;
}

.Page--actionsItem .actionsItemWrapper {
	padding-top: 10px;
}


.actionsItem {
	margin-bottom: 30px;
}

.actionsItem .actionsConditions {
	padding-top: 10px;
}

.actionsItemPhoto {
	float: left;
	height: 219px;
	margin-right: 20px;
	margin-bottom: 30px;
	width: 219px;
}

.actionsItemDescription {
	margin-left: 239px;
	width: 70%;
}
.actionsItemDescription .textNode {
	font-size: 14px;
}

.actionsItem__header {
	font-size: 21px;
	margin-bottom: 9px;
}

.actionsItemWrapper .actionGoodsListHeader {
	clear: left;
	margin-bottom: 20px;
}

.actionsItemDescription .lifetime {
	color: $C_ADD;
	display: block;
	font-size: 11px;
	margin-bottom: 15px;
}

.actionsItemDescription .cartActionsContent {
	margin: 15px 0;
}

.actionsItemDescription .cartActionsList {
	min-height: 95px;
	position: relative;
}

.actionsItemDescription .cartActionsList .col.summ {
	width: 30%;
}
.actionsItemDescription .cartActionsList .col.profit {
	width: 70%;
}

.actionsItemDescription .cartActionsList .actionsBadge {
	background-color: #f6f6f6;
	box-shadow: inset 0 5px 3px -3px rgba(0,0,0,.25);
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 125px;
}
.actionsItemDescription .cartActionsList .actionsBadgePic {
	background-position: 0 0;
	cursor: default;
	left: 50%;
	margin: -45px 0 0 -45px;
	position: absolute;
	top: 50%;
}



/* 4.30.6. PAGE SPECIFIC — ACTIONS — COMPARING
----------------------------------------------------------------------*/

.compareItems .actionsBadge {
	right: 0;
	position: absolute;
	top: 0;
}

.compareItems .badgeLink,
.compareItems .actionsBadgeTrigger {
	display: none;
}

.compareItems .item.last .actionsBadgeDescription {
	left: auto;
	margin-left: 0;
	right: 115px;
}
.compareItems .item.last .actionsBadgeDescription:before {
	left: auto;
	margin-left: 0;
	right: 52px;
}



/* 4.30.7. PAGE SPECIFIC — ACTIONS — ORDER BY CODE
----------------------------------------------------------------------*/

.orderByCodeList .itemIcon .actionsBadge {
	left: -10px;
	position: absolute;
	top: -10px;
}
.orderByCodeList .itemIcon .actionsBadgeTrigger,
.orderByCodeList .badgeLink {
	display: none;
}

.orderByCodeList .actionsBadgePic {
	@mixin actionsBadgePic-small;

	&:hover {
		@mixin actionsBadgePic-small-hover;
	}
}

.orderByCodeList .actionsBadgePic.discount {
	@mixin actionsBadgePic-small-discount;

	&:hover {
		@mixin actionsBadgePic-small-discount-hover;
	}
}

.orderByCodeList .actionsBadgePic.sale {
	@mixin actionsBadgePic-small-sale;

	&:hover {
		@mixin actionsBadgePic-small-sale-hover;
	}
}

.orderByCodeList .actionsBadgePic.priceWeek {
	@mixin actionsBadgePic-small-priceWeek;

	&:hover {
		@mixin actionsBadgePic-small-priceWeek-hover;
	}
}

.orderByCodeList .actionsBadgePic.getAGift {
	@mixin actionsBadgePic-small-getAGift;

	&:hover {
		@mixin actionsBadgePic-small-getAGift-hover;
	}
}

.orderByCodeList .actionsBadgeDescription {
	margin-top: 8px;
	right: 35px;
}



/* 4.30.8. PAGE SPECIFIC — ACTIONS — NOVELTIES
----------------------------------------------------------------------*/

/* должна выводиться только картинка */
.novelties .grid .actionsBadgePic.sale {
	left: auto;
	position: absolute;
	right: 0;
	top: -125px;
	z-index: 1;

	&:hover {
		@mixin actionsBadgePic-sale;
	}
}
/* если выводится не только она */
.novelties .grid .actionsBadge .actionsBadgePic.sale {
	position: relative;
	left: auto;
	top: auto;
	right: auto;

	&:hover {
		@mixin actionsBadgePic-sale-hover;
	}
}



/* 4.30.3. PAGE SPECIFIC — ACTIONS — TITLE
----------------------------------------------------------------------*/

.ActionsTypes {
	margin: 0 0 0 -14px;
	display: block;
}

.ActionsTypes__item {
	box-sizing: border-box;
	display: inline-block;
	margin: 0 0 14px 0;
	vertical-align: top;
	width: 50%;
}

.ActionsTypes__item.priceWeek {
	width: 100%;
}

.ActionsTypes__link {
	background-position: 103% 103%;
	background-repeat: no-repeat;
	border-radius: 2px;
	box-sizing: border-box;
	color: #000;
	display: block;
	height: 150px;
	margin-left: 14px;
	padding: 16px 12px 150px;
	position: relative;
	transition: all .3s cubic-bezier(0.42, 0, 0.08, 1);
	vertical-align: top;

	&:before {
		box-sizing: border-box;
		border-radius: 2px;
		display: block;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 1px solid #d1d1d1;
	}
}

.ActionsTypes__link:hover {
	background-position: 100% 100%;
	border-color: #cebbbd;
	box-shadow: 0 0 10px rgba(0,0,0,0.3);
	color: #fff;
	text-decoration: none;
	transition: all .1s cubic-bezier(0.42, 0, 0.08, 1);

	.ActionsTypes__header {
		color: #fff;
		text-decoration: underline;
	}

	.ActionsTypes__description {
		opacity: .8;
	}
}
.ActionsTypes__link:hover:before {
	display: none;
}

.ActionsTypes__header {
	color: $ACTION_MAIN;
	display: block;
	font-size: 21px;
	font-weight: bold;
	margin-bottom: 6px;
}

.ActionsTypes__description {
	display: block;
	line-height: 1.5;
	width: 60%;
}

.ActionsTypes__item.priceWeek .ActionsTypes__link {
	background-image: resolve('interface/action_price_of_week.png');

	&:hover {
		background-image: resolve('interface/action_price_of_week_white.png');
		background-color: #c60219;
		box-shadow: 0 0 10px rgba(88,0,10,0.3);
	}
}

.ActionsTypes__item.hotGifts .ActionsTypes__link {
	background-image: resolve('interface/action_hot_presents.png');

	&:hover {
		background-image: resolve('interface/action_hot_presents_white.png');
		background-color: #ef6b1b;
		box-shadow: 0 0 10px rgba(108,43,3,0.3);
	}
}

.ActionsTypes__item.hundredPresents .ActionsTypes__link {
	background-image: resolve('interface/action_hundred_presents.png');

	&:hover {
		background-image: resolve('interface/action_hundred_presents_white.png');
		background-color: #992fc5;
		box-shadow: 0 0 10px rgba(42,0,60,0.3);
	}
}

.ActionsTypes__item.benefitBuy .ActionsTypes__link {
	background-image: resolve('interface/action_benefit_buy.png');

	&:hover {
		background-image: resolve('interface/action_benefit_buy_white.png');
		background-color: #399314;
		box-shadow: 0 0 10px rgba(15,53,0,0.3);
	}
}

.ActionsTypes__item.buyMore .ActionsTypes__link {
	background-image: resolve('interface/action_buy_more.png');

	&:hover {
		background-image: resolve('interface/action_buy_more_white.png');
		background-color: #006cb5;
		box-shadow: 0 0 10px rgba(0,37,62,0.3);
	}
}



/* 4.31. PAGE SPECIFIC - GUIDE BLOCK
----------------------------------------------------------------------*/

/*
<div class="guideBlock suggest">
	<h4 class="guideHeader">
		<strong class="guideHeaderMain specTitle">Нужна помощь в выборе?</strong>
		<em class="guideHeaderOffer">Воспользуйтесь гидом по подбору товаров</em>
	</h4>
	<ul class="guideList list">
		<li class="guideListItem">
			<a href="#"><i class="icon guide"></i>Сахарок</a>
		</li>
		<li class="guideListItem">
			<a href="#"><i class="icon guide"></i>Кофеёк</a>
		</li>
		<li class="guideListItem">
			<a href="#"><i class="icon guide"></i>Чаёк</a>
		</li>
	</ul>
</div>
*/

.guideBlock {}

.guideList.list .icon.guide {
	background-position: -84px 0;
	height: 11px;
	width: 9px;
}
.guideList.list .guideListItem {
	padding-left: 1.25em;
}
.guideList.list .guideListItem a {
	padding: .3em 0;
}
.guideList.list .icon.guide {
	margin-top: -.16em;
	margin-right: .55em;
	margin-left: -1.2em;
}


.guideList.single .guideListItem {
	display: inline-block;
	margin-top: 20px;
	text-align: center;
	vertical-align: top;
}
.guideList.single .guideItemName {
	display: inline-block;
}

.guideList.single .icon.guide {
	background-position: -26px -40px;
	height: 36px;
	width: 28px;
}
.guideList.single .guideItemName .icon.guide {
	display: block;
	margin-bottom: 3px;
}


.guideBlock .photoFrame {
	margin-bottom: 7px;
	margin-right: auto;
	margin-left: auto;
}
.guideBlock .photoFrameContent .icon {
	margin: 22px auto 0;
}



/* 4.31.1. PAGE SPECIFIC - GUIDE BLOCK — SUGGEST
----------------------------------------------------------------------*/

.guideBlock.suggest .guideHeader {
	font-weight: bold;
}

.guideBlock.suggest .guideHeaderOffer {
	font-style: italic;
	font-size: 11px;
	font-weight: normal;
}



/* 4.31.2. PAGE SPECIFIC - GUIDE BLOCK — GUIDES LIST SUPERCATEGORY
--------------------------------------------------------------------*/

.Page__main.mainContentWide .contentDescription + .Page__nav.guidePage + .Content.guidePage {
	margin-top: 40px;
}
.Page__main.mainContentWide .contentDescription + .Page__nav.guidePage {
	margin-top: 15px;
}

.guideList.category {
	margin: -3%;
}
.guideList.category .guideListItem {
	margin: 3%;
}

.guideList.category .photoFrame {
	background-color: #f7f7f7;
	box-shadow: 0 0 5px rgba(0,0,0,.15);
	height: 160px;
	width: 160px;
}
.guideList.category .photoFrameHover:hover .photoFrame {
	background-color: #3b9add;
}
.guideList.category .photoFrameContent {
	box-shadow: inset 0 0 3px rgba(0,0,0,.3);
	top: 9%;
	right: 9%;
	bottom: 9%;
	left: 9%;
}
.guideList.category .photoFrameContent .photo {
	height: 70px;
	margin-top: 30px;
	width: 70px;
}
.guideList.category .guideListItem .guideItemName {
	color: #2d2d2d;
	display: block;
	font-size: 15px;
	width: 170px;
	word-wrap: break-word;
}
.guideList.category .guideListItem a.guideItemName:hover {
	color: $ACTION_MAIN;
}
.guideList.category .photoFrame {
	margin-bottom: 17px;
}



/* 4.31.3. PAGE SPECIFIC - GUIDE BLOCK — GUIDES LIST CATEGORY
--------------------------------------------------------------------*/

.guidePage.Page__nav .guideList.single.category {
	text-align: center;
}

.Content.guidePage .listColumns {
	padding-left: 90px;
}
.guidePage .listColumnsItem .guideBlock {
	margin-top: 45px;
}
.guidePage .listColumnsItem .guideBlock:first-child {
	margin-top: 0;
}

.guidePage .listColumnsItem .guideBlock .guideHeader {
	color: #6b6b6b;
	font-size: 14px;
}
.guidePage .listColumnsItem .guideBlock .guideList {
	margin-top: 15px;
}

.Page__nav.guidePage .sidebarBlock .sidebarBlockHeader {
	font-size: 13px;
}
.Page__nav.guidePage .sidebarBlock {
	font-size: 12px;
}
.Page__nav.guidePage .guideList.list.otherLinks {
	margin-bottom: 1em;
	margin-left: 1em;
}
.Page__nav.guidePage .guideList.list.otherLinks a {
	color: #000;
}
.Page__nav.guidePage .guideList.list.otherLinks a:hover {
	color: $ACTION_MAIN;
}

.Page__nav.guidePage .btnDefault.sidebarBlock {
	font-size: 14px;
	font-weight: bold;
	text-align: center;
}



/* 4.31.4. PAGE SPECIFIC - GUIDE BLOCK — ITEM CARD
--------------------------------------------------------------------*/

.tabsContent .info ul + .guideBlock,
.tabsContent .info p + .guideBlock {
	margin-top: 25px;
}
.tabsContent .info .guideHeader {
	margin-bottom: 7px;
}
.tabsContent .info .guideHeaderOffer {
	color: #606060;
	display: block;
}

.guideList.list .guideListItem {
	list-style-type: none;
	margin-top: 6px;
	margin-left: 0;
}
.guideList.list .guideListItem:first-child {
	margin-top: 0;
}
.guideList.list .guideListItem {
	list-style-type: none;
	margin-top: 6px;
}
.guideList.list .guideListItem:first-child {
	margin-top: 0;
}



/* 4.31.5. PAGE SPECIFIC - GUIDE BLOCK — ITEM LIST
--------------------------------------------------------------------*/

.guideBlock.sidebarBlock {
	text-align: center;
}

.sidebarBlock.guideBlock.suggest .guideHeader {
	color: #606060;
	padding: 0 30px;
}
.sidebarBlock.guideBlock.suggest .guideHeaderMain {
	display: block;
	font-size: 12px;
	line-height: 1.6;
	margin-bottom: 3px;
}
.sidebarBlock.guideBlock.suggest .guideList {
	margin-top: 20px;
}
.sidebarBlock.guideBlock.suggest .guideListItem:first-child {
	margin-top: 0;
}



/*4.32 PAGE SPECIFIC - HOW TO BE OFFICEMAG CLIENT
------------------------------------------------------------------------------*/

.toBeClientInfo .textNode {
	overflow: visible;
}

.toBeClientInfo .serviceRegions {
		padding: 25px 0;
		position: relative;
	}

.toBeClientInfo .serviceRegions img {
	position: absolute;
	right: -29px;
	top: 40px;
}

.toBeClientInfo .serviceRegions ul {
	background: rgba(255,255,255,.7);
	position: relative;
	width: 540px;
	z-index: 1;
}

.toBeClientInfo .getMoreInformation {
	position: relative;
}

.toBeClientInfo .getMoreInformation .citySelect {
	border: 1px solid #847C77;
	box-shadow: 0 7px 12px rgba(0, 0, 0, 0.3);
	display: none;
	position: absolute;
	right: 215px;
	top: -415px;
	z-index: 50;
}

.toBeClientInfo .getMoreInformation .citySelect.active {
	display: block;
}

.toBeClientInfo .getMoreInformation .citySelect ul {
	margin: 0;
	padding: 0;
}

.toBeClientInfo .getMoreInformation .citySelect:before {
	background: url('/img/lug_big.png') no-repeat scroll 0 100% transparent;
	content: '';
	height: 8px;
	left: 155px;
	margin: 0 0 0 -7px;
	position: absolute;
	bottom: -8px;
	width: 13px;
}

.toBeClientInfo .getMoreInformation .citySelect .btnClose {
	right: 7px;
	top: 7px;
}

.toBeClientInfo .buttonsBlock:after {
	content: '';
	display: inline-block;
	width: 100%;
}

.toBeClientInfo .buttonsBlock {
	margin: 63px 0 0;
	padding: 0;
	text-align: justify;
}

.toBeClientInfo .buttonsBlock li {
	display: inline;
}

.toBeClientInfo .buttonsBlock .btn {
	font-size: 16px;
	height: auto;
	padding: 9px 25px 10px;
}

.toBeClientInfo .vipService {
	position: relative;
}

.toBeClientInfo .vipService em {
	color: #636363;
	font-size: 11px;
	font-style: normal;
	left: 60px;
	position: absolute;
	top: -34px;
	width: 190px;
}



/* 4.33. PAGE SPECIFIC - UNSUBSCRIBING
------------------------------------------------------------------------------*/

.unsubscribingContent .description {
	font-size: 14px;
}
.unsubscribingContent .info {
	color: #5a5a5a;
	font-size: 13px;
	margin-bottom: -1em;
}
.unsubscribingContent .closer { /* ^ added from z.1129 */
	margin-bottom: -1em;
}
.unsubscribingContent .fieldsReasons {
	background-color: #f3f3f3;
	margin: 1.5em 0;
	padding: 20px;
}
.unsubscribingContent .fieldsReasons input[type="checkbox"] { /* ^ added from z.1129 */
	vertical-align: middle;
	margin-top: -.25em;
}
.unsubscribingContent .fieldReason {
	padding-left: 15px;
}



/* 4.34. PAGE SPECIFIC - EAIST
------------------------------------------------------------------------------*/

.EAISTInfo {
	margin: 0 0 30px;
}

.EAISTInfo .EAISTIntro {
	text-align: center;
}

.EAISTInfo .EAISTDifficults {
	font-weight: bold;
}

.EAISTInfo .EAISTState {
	font-style: italic;
}

.instructionList .instructionIllustrations {
	padding: 0 0 30px;
	text-align: center;
}

.instructionList .instructionIllustrations img {
	 display: block;
	 margin: 0 auto 30px;
}

.icon.eaist {
	background: url('/img/EAIST/icons.png') no-repeat;
	margin-left: 2px;
	margin-right: 4px;
}

.icon.eaist.attach {
	background-position: -36px 0;
	height: 22px;
	width: 27px;
}

.icon.eaist.select {
	background-position: -68px 0;
	height: 22px;
	width: 18px;
}

.icon.eaist.calendar {
	background-position: -91px 0;
	height: 22px;
	width: 18px;
}


.EAIST__status .icon.EAIST {
	margin-right: 3px;
}
.EAIST__tip {
	.icon {
		background-image: resolve('interface/info_black.png');
		height: height('interface/info_black.png');
		width: width('interface/info_black.png');
		margin-right: 0;
	}

	&:hover .icon {
		background-image: resolve('interface/info_brand_a.png');
	}
}

.EAIST__code,
.EAIST__check {
	font-weight: bold;
}
.icon.EAIST + .EAIST__check {
	margin-left: 5px;
}


.code .EAIST__status,
.itemCode .EAIST__status {
	margin-left: 10px;
}

.specialBar .EAIST__status {
	color: #666;
	font-size: 12px;
	margin-top: 7px;
}



/* 4.35. PAGE SPECIFIC — SALE
------------------------------------------------------------------------------*/

.Sale__content h2 {
	cursor: default;
	font-size: 34px;
	font-weight: normal;
	vertical-align: middle;
}
.Sale__content h2 .headerNarrow {
	color: #4b4440;
	margin-right: .5em;
	text-transform: uppercase;
}
.Sale__content h2 .btnBrown.btnArrow {
	vertical-align: 8px;
}

.Sale__content > .more {
	display: block;
	float: right;
	font-size: 14px;
}
.Sale__content .listItemsWrapper {
	border-bottom: 1px solid #e5e5e5;
	margin: 0 0 30px;
}
.Sale__content .grid .listItemWrapper {
	padding: 20px 12px 0;
	width: auto;
}
.Sale__content .grid .listItems {
	margin-top: 0;
}
.Sale__content .grid .listItems > li {
	width: 20%;
}



/* 4.36. PAGE SPECIFIC — CONSIGNEE
-----------------------------------------------------------------------------*/

.ConsigneeSelect {
	margin: 80px 0 65px 320px;
	width: 470px;
}

.ConsigneeSelect__item {
	display: block;
	font-size: 15px;
	line-height: 1em;
	padding-left: 18px;
}
.ConsigneeSelect__item + .ConsigneeSelect__item {
	margin-top: 14px;
}
.ConsigneeSelect__item.buyerSelect .ConsigneeSelect__radio,
.ConsigneeSelect__item.new .ConsigneeSelect__radio {
	vertical-align: baseline;
}

.ConsigneeSelect__radio {
	vertical-align: top;
	margin: 1px 0 0 -18px;
}
.ConsigneeSelect__caption {
	display: inline-block;
	margin-left: 1px;
}
.ConsigneeSelect__name {
	color: #666;
	font-size: 14px;
}
.ConsigneeSelect__contract {
	color: #999;
	display: block;
	font-size: 12px;
	margin-top: 4px;
}
.ConsigneeSelect__contract.error {
	color: #e70202;
}
.ConsigneeSelect__contract .Select {
	width: 100%;
}
.ConsigneeSelect__notOperatingContract {
	color: #ac0000;
	font-size: 10px;
}
.ConsigneeSelect__notOperatingContract .icon {
	cursor: pointer;
}
.ConsigneeSelect__input {
	width: 100%;
}

.ConsigneeSelectFancyBox {
	width: 540px;
}
.ConsigneeSelectFancyBox.buyerSelect {
	width: 340px;
}
.ConsigneeSelectFancyBox.buyerSelect .ConsigneeSelect__radio {
	vertical-align: baseline;
}

.ConsigneeSelectFancyBox .ConsigneeSelect__name {
	color: #000;
}
.ConsigneeSelectFancyBox img {
	display: block;
	margin: 0 auto;
}

.ConsigneeError {
	border: 1px solid #e51b22;
	padding: 12px 15px;
}
.ConsigneeError__header,
.ConsigneeError__item {
	color: #e51b22;
}
.ConsigneeError__header {
	font-size: 16px;
	margin-bottom: .4em;
}
.ConsigneeError__info {
	color: #777;
	line-height: 1.5;
}
.ConsigneeError__info + .ConsigneeError__info {
	margin-top: .8em;
}
.ConsigneeError__info .phone {
	color: #000;
	display: inline-block;
	font-weight: bold;
}
.ConsigneeError__item {
	display: block;
	font-weight: bold;
}



/* 4.37. PAGE SPECIFIC - BONUS
-----------------------------------------------------------------------------*/

.bonusLogo.s {
	/*background-image: url(/img/misc/bonus_logo_s.png?v=02102013);*/
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAALCAMAAADsveWiAAAAilBMVEX53Yj++u5m2pLM89vriIjgRETjVVXbIiLYqvKwVebQmfC4Zuj1yUT87LvzuxH99d342HcRxVX2zlXxqqqZ4/P0u7v3zMzumZlm1u7v3frgu/VV1ob1xTP37vzu+/N33p6I4qrAd+uZIt7756rpd3eq6PV32vCq6sIAwUnytgDVAAAAuuKJANn///+V11IBAAAArUlEQVR42r3R2Q6CMBCFYdz3BUVA1lJkKXje//Wctpo2qXcm/vdfZqb1BpMw9abR9DT94B6gbtrVwOmiXAfWl+ika9COLRrl1sB9YzksyRGTZdoVvusqgODHXYcQC3JHYC8OOGs3Y99cwt09M0yE8FBrNy0cRzAHKjNvjsiZl7JCuwAN2YCcKge37ouc+1L/7WLIYum2oGwXrpz3THumHS0JWlU6ngD5job++99fBmNd/9aBkqcAAAAASUVORK5CYII=');
	background-repeat: no-repeat;
	display: inline-block;
	height: 11px;
	width: 55px;
}
.Page__header .user .bonusLogo.s {
	margin-right: .4em;
}

.bonusInformer {
	float: left;
	margin-bottom: 15px;
}
.bonusInformer strong {
	font-weight: bold;
}
.bonusInformerText {
	color: #6a6a6a;
}
.bonusInformerText a:hover {
	border-bottom: 1px solid;
	text-decoration: none;
}

.bonusInformer .bonusLogo.s {
	margin-right: .3em;
}

.bonusInfo {
	background-color: #f3f3f3;
	padding: 15px;
	width: 540px;
}
.bonusInfo .bonusText {
	margin-bottom: .5em;
}
.bonusInfo .bonusSumm {
	font-size: 18px;
	font-weight: bold;
	margin-top: .5em;
	margin-bottom: 0;
}


.Bonus--card {}
.BonusRules {
	margin-bottom: 2em;
	padding: 10px;
	width: 400px;
}
.BonusRules__header {
	font-size: 14px;
	font-weight: bold;
	margin-top: 2em;
	margin-bottom: .75em;
}
.BonusRules__list {
	list-style-type: none;
	padding-left: 0;
}
.BonusRules__info {
	color: #888;
	font-size: 11px;
}
.BonusRules .col.summ {
	padding-left: 0;
	text-align: left;
	width: 50%;
}
.BonusRules .col.profit {
	padding-right: 0;
	text-align: right;
	width: 50%;
}
.BonusRules .header {
	margin-top: 0;
}
.BonusRules .header .col {
	padding: 0 15px 3px;
}
.BonusRules__list {
	background: #fff;
	padding-right: 15px;
	padding-left: 15px;
}

.Bonus__logo {
	margin-top: -.17em;
	vertical-align: middle;
}

.BonusStats {
	width: 645px;
}
.BonusStatsInfo {
	background-color: #f3f3f3;
	padding: 10px 15px;
}
.BonusStatsInfo .BonusStatsInfo__header {
	font-weight: bold;
	margin-top: 0;
}
.BonusStatsInfo .BonusStatsInfo__list {
	margin-bottom: 0;
}
.BonusStatsInfo__list li:last-child {
	margin-bottom: 0;
}

.BonusStats__group {
	display: table;
	width: 100%;
}
.BonusStats__box {
	box-sizing: border-box;
	background-color: #f8f8f8;
	border: 1px solid #e5e5e5;
	display: table-cell;
	padding: 15px;
	text-align: center;
	vertical-align: middle;
	width: 215px;
}
.BonusStats__box + .BonusStats__box {
	border-left: 0;
}
.BonusStats__value {
	margin-top: 5px;
}
.BonusStats__value .Price {
	font-size: 17px;
}
.BonusStats__value .Price__penny {
	font-size: 12px;
}

.BonusStats .Bubble .Price {
	border-bottom: 1px dotted;
	color: #0dabd3;
	cursor: pointer;
}
.BonusStats .BubbleTrigger {
	display: inline-block;
}
.BonusStats .BubbleContent {
	left: 50%;
	line-height: 1.5;
	margin-left: -125px;
	padding: 5px 8px;
	text-align: left;
	width: 250px;
}
.BonusStats .BonusManager {
	margin-top: 5px;
}
.BonusStats .BonusManager__header {
	display: block;
	font-weight: bold;
}



/* 4.38. PAGE SPECIFIC — EMPLOYEES
-----------------------------------------------------------------------------*/

.employeeManagementInfo {
	position: relative;
}
.employeeManagementInfo__form {
	width: $FORM_WIDTH;
}
.employeeManagementInfo strong {
	font-weight: bold;
}

.employeeManagementInfo h3 {
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 12px;
}


.employeeManagementInfo .contractInfo {
	display: inline-block;
	position: relative;
	text-align: left;
	vertical-align: top;
}
.employeeManagementInfo .contractInfo .name {
	border-color: #0dabd3;
	font-weight: normal;
}
.employeeManagementInfo .contractInfo.noContract .name {
	border-color: #ee1c25;
	color: #ee1c25;
	font-weight: normal;
}
.employeeManagementInfo .contractInfo.noContract .name:hover {
	border-color: #0dabd3;
	color: #0dabd3;
}
.contentWrapper .contractInfo .description {
	left: 0;
	right: auto;
}
.contentWrapper .contractInfo .description:before {
	left: 20px;
	right: auto;
}
.contractInfo strong.validity {
	font-weight: bold;
}

.contractInfo .description dl {
	display: block;
	font-size: 11px;
	line-height: 17px;
	margin: 5px 0 0;
	overflow: hidden;
}
.contractInfo .description dt {
	clear: left;
	color: #666;
	display: block;
	float: left;
	text-align: right;
	width: 175px;
}
.contractInfo .description dd {
	clear: right;
	display: block;
	float: left;
	margin: 0 0 0 4px;
}

.employeeManagementInfo .rights label {
	margin: 0 15px 0 0;
}
.employeeManagementInfo .rights label input {
	margin: 0 2px 2px 0;
}

.employeeManagementInfo .delivery .checkAddresses li:first-child{
	margin: 0;
}
.employeeManagementInfo .delivery .checkAddresses li{
	margin: 10px 0 0;
}
.employeeManagementInfo .delivery label input {
	margin: 0 3px 1px 0;
}
.employeeManagementInfo .delivery label span {
	font-size: 13px;
}
.employeeManagementInfo .delivery label em {
	color: #666;
	display: block;
	margin: 0 0 0 19px;
}
.employeeManagementInfo .delivery .addressInput {
	display: inline-block;
	float: left;
	margin: 3px 0 0;
	width: 500px;
}
.employeeManagementInfo .delivery .checkAddresses li:first-child {
	margin: 0;
}
.employeeManagementInfo .delivery .checkAddresses li {
	margin: 10px 0 0;
}

.employeeManagementInfo .pseudoLink.changePassword {
	display: inline-block;
}
.emloyeeManagmentDescription .btn {
	float: right;
	margin: 14px 0 0;
}

.employeeManagementInfo .contacts .errorInput {
	border: 1px solid #ee1c25;
	width: 350px;
}
.employeeManagementInfo .contacts .code.errorInput {
	width: 45px;
}
.employeeManagementInfo .contacts .phone.errorInput {
	width: 100px;
}
.employeeManagementInfo .contacts .errorInput .errorMessage {
	display: block;
	color: #ee1c25;
	font-size: 11px;
	left: 167px;
	position: relative;
	text-align: left;
	top:1px;
}
.employeeManagementInfo .contacts span.errorMessage,
.employeeManagementInfo .delivery span.errorMessage {
	color: #ee1c25;
	display: block;
	font-size: 11px;
	margin: 0;
	text-align: left;
	width: 350px;
}

.employeeManagementInfo .addressControls span.edit:hover,
.employeeManagementInfo .addressControls span.cansel:hover {
	border-color: $ACTION_MAIN;
	color: $ACTION_MAIN;
}


.employeeManagementInfo .infoGroup {
	position: absolute;
	top: 0;
	left: 520px;
	width: 300px;
}
.employeeManagementInfo .infoBox {
	background-color: $BG;
	border-radius: $BD_RAD;
	padding: 12px 15px;
}
.employeeManagementInfo .infoBox.Contract {
	padding: 0 15px;
}
.employeeManagementInfo .infoBox + .infoBox {
	margin-top: 20px;
}
.employeeManagementInfo .infoBox__header {
	margin-bottom: 10px;
}
.employeeManagementInfo .infoBox p {
	margin-top: 12px;
}
.employeeManagementInfo .infoBox p:first-child {
	margin-top: 0;
}
.employeeManagementInfo .infoBox strong {
	font-weight: bold;
}
.employeeManagementInfo .infoBox.agent {
	font-size: 14px;
}



.employeeManagementInfo__delivery { width: 750px; }

/*
.employeeManagementInfo .Form__set {
	border-bottom: 0;
	padding-bottom: 0;
}
*/
.employeeManagementInfo .Form .delivery .addressesList {
	width: auto;
}
.employeeManagementInfo__account.Form__group .Form__field {
	padding-left: 19px;
}
.employeeManagementInfo__account.Form__group .radio {
	margin-right: 5px;
	margin-left: -18px;
}
.employeeManagementInfo__account.Form__group .info {
	color: #857b76;
	display: block;
	font-size: 12px;
	margin-top: 3px;
}

.Select--individualList {
	text-align: left;
	margin-top: -.3em;
	vertical-align: middle;
	width: 220px;
}



/* 4.38.1. PAGE SPECIFIC — EMPLOYEES LIST
-----------------------------------------------------------------------------*/

.employeeAdministrationInfo{
	position: relative;
}

.employeeAdministrationInfo .tabsContainer.tabsLarge {
	margin: 0 0 25px;
}
.employeeAdministrationInfo .tabsContainer li {
	color: #666;
	padding: 7px 10px 6px;
}
.employeeAdministrationInfo .tabsContainer li.active {
	padding: 7px 10px;
}
.employeeAdministrationInfo .tabsContent .employeeList table {
	background: #fff;
	font-size: 13px;
	width: 100%;
}
.employeeAdministrationInfo .employeeCompany .Bubble.Contract,
.employeeAdministrationInfo .employeeContainer .Bubble.Contract {
	display: inline-block;
}
.employeeAdministrationInfo .employeeList tr {
	border-bottom: 1px solid #e5e5e5;
}
.employeeAdministrationInfo .employeeList tr:first-child:hover{
	background: none;
}

.employeeAdministrationInfo .employeeList th {
	color: #666;
	font-size: 12px;
	margin: 0;
	padding: 0 2% 15px;
	text-align: left;
}
.employeeAdministrationInfo .employeeList th:first-child {
	padding: 0 2% 15px 10px;
}
.employeeAdministrationInfo .employeeList td {
	margin: 0;
	padding: 15px 2%;
	vertical-align: top;
	text-align: left;
}
.employeeAdministrationInfo .employeeList td.negative{
	color: #888;
}
.employeeAdministrationInfo .employeeList td:first-child {
	padding: 15px 2% 15px 10px;
}
.c-administrator,
.employeeAdministrationInfo .employeeList .administrator .employeeType {
	color: #e88825;
}
.c-manager,
.employeeAdministrationInfo .employeeList .manager .employeeType {
	color: #4c9512;
}
.c-subordinate,
.employeeAdministrationInfo .employeeList .subordinate .employeeType {
	color: #8c8c8c;
}
.employeeAdministrationInfo .employeeList .employeeName {
	padding-left: 23px;
	width: 25%;
}
.employeeAdministrationInfo .employeeList .employeeLogin,
.employeeAdministrationInfo .employeeList .employeeContract {
	color: #777;
	font-size: 11px;
	margin-top: 2px;
}
.employeeAdministrationInfo .employeeList .employeeLogin {
	display: block;
}
.employeeAdministrationInfo .employeeList .employeeContract {
	display: inline-block;
}

.employeeAdministrationInfo .employeeList .employeeContract.error {
	color: #AC0000;
}

.employeeAdministrationInfo .employeeList .employeeType {
	text-align: center;
	width: 12%;
}
.employeeAdministrationInfo .employeeList .employeeIndividual {
	color: #989898;
	width:15%;

	.employeeIndividual__box + .employeeIndividual__box {
		margin-top: 6px;
	}

	.employeeIndividual__title {
		font-size: 11px;
	}

	.employeeIndividual__value {
		color: #000;
		margin-top: 2px;
	}
}
.employeeAdministrationInfo .employeeList .employeeRightsOrder {
	text-align: center;
	width: 18%;
}
.employeeAdministrationInfo .employeeList .employeeRightsDelivery {
	width: 17%;
}
.employeeAdministrationInfo .employeeList .employeeCompany {
	width: 19%;
}
.employeeAdministrationInfo .employeeList .employeeDelete {
	padding: 0 10px 0 2%;
	vertical-align: middle;
	text-align: right;
}
.employeeAdministrationInfo .employeeList .employeeDelete a {
	border-bottom: 1px dotted;
	color: $C_ACTION;
	cursor: pointer;
}
.employeeAdministrationInfo .employeeList .employeeDelete a:hover {
	color: $BRAND__A;
	text-decoration: none;
}

.employeeAdministrationInfo .tabsContent .employeeHierarchy {
	border-bottom: 1px solid #e5e5e5;
	margin: 0 0 0 -6px;
	padding: 0 0 20px 6px;
}

.employeeHierarchy td {
	padding: 5px 20px;
	vertical-align: top;
}
.employeeAdministrationInfo .employeeHierarchy tr:first-child td {
	padding-top: 6px;
}
.employeeAdministrationInfo .employeeHierarchy .administratorLine td {
	padding-top: 50px;
}
.employeeHierarchy .managerLine td {
	padding-top: 25px;
}
.employeeHierarchy .administratorRow {
	padding-left: 0;
}
.employeeHierarchy .administratorRow:first-child .BubbleContent {
	left: 0;
	margin-left: 0;
}
.employeeHierarchy .administratorRow:first-child .Bubble.bottom .BubbleContent:before {
	background-position: 50px 100%;
}
.employeeHierarchy .subordinateRow {
	padding-right: 0;
}
.employeeHierarchy tr:first-child td:first-child {
	padding-left: 0;
}
.employeeHierarchy tr:first-child td:first-child div:after {
	display: none;
}

.employeeHierarchy tr.administratorLine .employeeContainer.manager:after {
	border-left: 0;
	border-bottom: 1px solid #c2c2c2;
	bottom: 90%;
	content: '';
	display: block;
	height: 1px;
	left: -40px;
	position: absolute;
	width: 40px;
}
.employeeHierarchy tr.administratorLine .employeeContainer.manager:before {
	border-bottom: 1px solid #c2c2c2;
	background: #fff;
	content: '';
	display: block;
	height: 1000px;
	position: absolute;
	left: -21px;
	bottom: 90%;
	width: 3px;
	z-index: -1;
}
.employeeHierarchy .employeeContainer.manager:after {
	border-bottom: 1px solid #c2c2c2;
	border-left: 1px solid #c2c2c2;
	bottom: 90%;
	content: '';
	display: block;
	height: 1000px;
	left: -20px;
	position: absolute;
	width: 19px;
	z-index: -2;
}

.employeeHierarchy tr.managerLine .employeeContainer.subordinate:after {
	border-left: 0;
	border-bottom: 1px solid #c2c2c2;
	bottom: 90%;
	content: '';
	display: block;
	height: 1px;
	left: -40px;
	position: absolute;
	width: 40px;
}
.employeeHierarchy tr.managerLine .employeeContainer.subordinate:before,
.employeeHierarchy .employeeContainer.subordinate:before {
	border-left: 1px solid #c2c2c2;
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	left: -21px;
	top: 90%;
	width: 19px;
	z-index: -2;
}
.employeeHierarchy .employeeContainer.subordinate:after {
	border-bottom: 1px solid #c2c2c2;
	border-left: 1px solid #c2c2c2;
	bottom: 90%;
	content: '';
	display: block;
	height: 100%;
	left: -21px;
	position: absolute;
	width: 20px;
	z-index: -1;
}
.employeeHierarchy .subordinateRow.last .employeeContainer.subordinate:before {
	border: 0;
	height: 0;
	width: 0;
}

.employeeHierarchy .employeeContainer {
	background: #fff;
	box-sizing: border-box;
	border: 1px solid #c9cfc5;
	font-size: 13px;
	position: relative;
	width: 255px;
}

.employeeContainer__name {
	display: block;
	font-size: 14px;
	font-weight: bold;
}

.employeeContainer__login {
	display: block;
	color: #777;
	font-size: 11px;
	margin-top: 3px;
}

.employeeContainer.administrator .employeeContainer__appointment {
	color: #E88825;
}

.employeeContainer__appointment {
	display: block;
	color: #777;
	margin-top: 7px;
}

.employeeContainer__content {
	display: block;
	vertical-align: top;
	padding: 13px 12px;
}

.employeeContainer__content.head {
	background-color: #f3f5f0;
}

.employeeContainer__organization {
	display: block;
}

.employeeContainer__contract {
	display: inline-block;
	color: #777;
	font-size: 11px;
	margin-top: 3px;
}

.employeeContainer .contractInfo {
	color: #777;
	font-size: 11px;
	margin-top: 3px;
}

.employeeContainer .contractInfo .name:hover {
	color: #0DABD3;
}

.employeeContainer .contractInfo .name {
	color: #777;
	font-weight: normal;
}

.employeeContainer__contract.error {
	color: #AC0000;
}

.employeeContainer__contract .icon.tipTip {
	cursor: pointer;
}

.employeeContainer__employeeListContainer {
	margin-top: 17px;
}

.employeeContainer__employeeListContainer:first-child {
	margin-top: 0;
}

.employeeContainer__employeeListCaption {
	display: block;
	color: #777;
	font-weight: bold;
	margin-bottom: 7px;
}

.employeeContainer__employeeItem {
	word-wrap: break-word;
}
.employeeContainer__employeeItem + .employeeContainer__employeeItem {
	margin-top: 7px;
}

.employeeContainer__contract.pseudoLink:hover {
	color: #0DABD3;
}

.employeeContainer.administrator .employeeContainer__content.head {
	background: #fbeee1;
}

.employeeContainer__bubble .BubbleContent {
	padding: 15px;
	left: -72px
}

.employeeContainer__bubbleDate {
	display: block;
	font-weight: bold;
	margin-bottom: 3px;
}

.employeeAdministrationInfo .employeeList .employeeActivation {
	text-align: right;
	padding: 0 10px 0 2%;
	vertical-align: middle;
}
.employeeList .deactivated {
	background-color: #f3f3f3;
	color: #989898;
}
.employeeList .deactivated a,
.employeeList .deactivated .employeeLogin,
.employeeList .deactivated .employeeContract {
	color: #989898;
}

.employeeList .deactivated .employeeContract.error {
	color: #AC0000;
}

.employeeList .deactivated a:hover {
	color: #0dabd3;
}
.employeeList .employeeActivation a {
	border-bottom: 1px dotted;
	color: $C_ACTION;
	cursor: pointer;
}
.employeeAdministrationInfo .employeeList .employeeActivation a:hover {
	color: $BRAND__A;
	text-decoration: none;
}


.employeeHierarchy .employeeContainer.subordinate.deactivated a {
	background: #eee;
	color: #bbb;
	border-color: #ddd;
}
.employeeHierarchy .employeeContainer.subordinate.deactivated a span {
	color: #999;
}
.employeeHierarchy .employeeContainer.subordinate.deactivated a:hover span {
	color: $ACTION_MAIN;
}

.ie8 {
	.employeeHierarchy tr.administratorLine .employeeContainer.manager:after,
	.employeeHierarchy tr.administratorLine .employeeContainer.manager:before,
	.employeeHierarchy .employeeContainer.manager:after,
	.employeeHierarchy tr.managerLine .employeeContainer.subordinate:after,
	.employeeHierarchy tr.managerLine .employeeContainer.subordinate:before,
	.employeeHierarchy .employeeContainer.subordinate:before,
	.employeeHierarchy .employeeContainer.subordinate:after {
		display: none;
	}
}

.employeeAdministrationInfo .ToolBar {
	margin-bottom: 25px;
}
.employeeAdministrationInfo .badgeMarker {
	border: 0;
	border-radius: 5px;
	box-shadow: none;
	padding: 2px 4px;
	position: relative;
	top: -2px;
}
.employeeAdministrationInfo .badgeMarker.self {
	background-color: #d2666b;
	color: #fff;
}
.employeeClientCode {
	color: #777;
	display: block;
	font-size: 11px;
	margin-top: .3em;
}



/* 4.38.2. PAGE SPECIFIC — EMPLOYEES ADD
-----------------------------------------------------------------------------*/

.employeeManagementInfo .contacts .unActiveField.contract {
	position: relative;
}
.employeeManagementInfo .contacts .oldPassword {
	margin: 15px 0 10px;
}
.employeeManagementInfo .contractBubble:before { /* ^ DELETE THIS BLOCK (not used) */
	/*background: url('/img/arrow-top-green.png') no-repeat;*/
	content: '';
	display: block;
	height: 8px;
	left: 70px;
	margin: 0 0 0 -6px;
	position: absolute;
	top: -8px;
	width: 13px;
}
.employeeManagementInfo .contractBubble {
	background-color: #fff;
	border: 1px solid #4e9a13;
	box-shadow: 0 0 8px rgba(0,0,0,.3);
	display: none;
	font-size: 11px;
	left: -1px;
	padding: 15px;
	position: absolute;
	top: 24px;
	z-index: 10;
}
.employeeManagementInfo .contractBubble h4 {
	font-size: 13px;
	margin: 0 0 7px;
}
.employeeManagementInfo .contractBubble li:first-child {
	margin: 0;
}
.employeeManagementInfo .contractBubble li {
	margin: 2px 0 0;
}
.employeeManagementInfo .contacts .unActiveField.contract .contractBubble span {
	margin: 0 7px 0 0;
	width: 143px;
}
.employeeManagementInfo .contacts .unActiveField.contract .contractBubble strong {
	color: #4e9a13;
	font-weight: bold;
}
.employeeManagementInfo .errorInput {
	border: 1px solid #ee1c25;
	color: #e10000;
}
.employeeManagementInfo .errorNotice {
	color: #ee1c25;
	display: block;
	font-size: 11px;
	margin: 4px 0 0;
	text-align: left;
	width: auto;
}
.employeeManagementInfo .delivery .addressesList {
	margin-bottom: 1em;
	width: 820px;
}
.employeeManagementInfo .delivery .addressesList > li {
	display: block;
	padding: 14px 12px;
}
.employeeManagementInfo .delivery .addressesList > li + li {
	border-top: 1px solid $B_ACTION;
}
.employeeManagementInfo .delivery .addressesList li.isEditing {
	background-color: $BG;
	padding-top: 9px;
	padding-bottom: 9px;
}
.employeeManagementInfo .delivery .addressesList li.isEditing .addressInput {
	margin: 0;
}
.employeeManagementInfo .delivery .addressInput.hide,
.employeeManagementInfo .delivery .addressesList li.hide {
	display: none;
}
.employeeManagementInfo .delivery .address {
	display: inline-block;
	float: left;
	width: 460px;
}
.employeeManagementInfo .delivery .addressControls {
	display: inline-block;
	float: right;
	padding: 0 0 0 20px;
}
.employeeManagementInfo .delivery .addressControls li.first {
	margin: 0;
}
.employeeManagementInfo .delivery .addressControls li {
	border-bottom: 0;
	display: inline-block;
	margin: 0 0 0 17px;
	padding: 0;
}
.employeeManagementInfo .addressControls .control {
	border-bottom: 1px dotted;
	color: $C_ACTION;
	cursor: pointer;
}
.employeeManagementInfo .addressControls .control.edit:hover {
	border-color: $ACTION_MAIN;
	color: $ACTION_MAIN;
}
.employeeManagementInfo .addressControls .control.remove:hover {
	border-color: #e10000;
	color: #e10000;
}
.employeeManagementInfo .delivery .addressInput {
	display: inline-block;
	float: left;
	margin: 3px 0 0;
	width: 500px;
}

.employeeManagementInfo > .btn.remove,
.employeeAdministrationInfo > .btn.remove {
	position: absolute;
	right: 0;
	top: -53px;
}


.employeeManagementInfo .statusPanel.problem,
.employeeManagementInfo .contacts label span,
.employeeManagementInfo .contacts .unActiveField span {
	margin-left: -140px;
}
.employeeManagementInfo .contacts .unActiveField .errorMessage {
	margin-left: 0;
}
.employeeManagementInfo .statusPanel.problem {
	background-color: #f9e7e7;
	margin-bottom: 20px;
	padding: 14px 12px;
	width: 739px;
}
.employeeManagementInfo .statusPanel.problem .header {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 2px;
}
.employeeManagementInfo .statusPanel.problem .btnGreen {
	float: right;
	margin-top: 3px;
}


.employeeManagementInfo .Bubble.Contract {
	display: inline;
	vertical-align: baseline;
}



/* 4.39. PAGE SPECIFIC — FAVORITES
------------------------------------------------------------------------------*/

/* 4.39.1. PAGE SPECIFIC — FAVORITES LISTS
------------------------------------------------------------------------------*/

.favoritesInfo h4 {
	border-bottom: 1px solid #ccc;
	color: #666;
	font-size: 13px;
	font-weight: normal;
	padding: 0 0 3px;
}
.favoritesInfo .favoritesLists {
	margin: 0 0 20px;
	width: 60%;
}
.favoritesLists li {
	border-bottom: 1px solid #e5e5e5;
	padding: 15px 0 15px 19px;
	position: relative;
}
.favoritesLists li:before {
	background: url('/img/favorite.png') no-repeat 0 -15px;
	content: '';
	display: block;
	height: 17px;
	left: 0;
	position: absolute;
	top: 15px;
	width: 12px;
}
.favoritesLists .name,
.favoritesLists .control {
	display: inline-block;
	vertical-align: middle;
}
.favoritesLists .name {
	margin: 0 5% 0 0;
	width: 70%;
}
.favoritesLists .control {
	-moz-opacity: 0;
	     opacity: 0;
	text-align: right;
	width: 25%;
}
.favoritesLists li:hover .control {
	opacity: 1;
	text-align: right;
	transition: opacity .15s ease-in;
	width: 25%
}
.favoritesLists .name a {
	font-size: 18px;
	margin-right: 5px;
	vertical-align: middle;
}
.favoritesLists .control .pseudoLink {
	border-color: #666;
	color: #666;
	margin: 0 0 0 15px;
}
.favoritesLists .control .pseudoLink:first-child {
	margin: 0;
}
.favoritesLists .control .pseudoLink:hover {
	border-color: #0dabd3;
	color: #0dabd3;
}
.favoritesLists .control .pseudoLink.red:hover {
	border-color: #e0141d;
	color: #e0141d;
}

.favoritesInfo.blank {
	margin: 0 0 90px;
	text-align: center;
}
.favoritesInfo.blank h4 {
	border-bottom: none;
	color: #666;
	font-size: 22px;
	font-weight: normal;
	margin: 50px 0 10px;
}

.favoritesList__badge {
	&--common {
		background-color: $ATTENTION;
		color: #222;
	}

	&--private {
		background-color: #d9d2ff;
		color: #222;
	}
}

.FancyModal--favoritesPrivateRemove {
	width: 550px;
}

.FancyModal--orderNotInCatalog {
	width: 550px;
}

.ToolBar--favorites {
	margin-bottom: 1em;

	.btn--AddToCart {
		margin-right: 1em;
	}
}



/* 4.39.2. PAGE SPECIFIC — FAVORITES POPUP
------------------------------------------------------------------------------*/

.favoritesPopup {
	background: #fff;
	box-shadow: $BX_SH;
	display: none;
	left: -25px;
	padding: 10px;
	position: absolute;
	top: -10px;
	min-width: 152px;
	z-index: 20;
}
.active .favoritesPopup {
	display: block;
}
.favoritesPopup .closePopup {
	/*background: url('/img/close-popup.png') no-repeat;*/ /* ^ not existing */
	cursor: pointer;
	display: block;
	height: 7px;
	margin: 4px 0 0;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 8px;
}
.favoritesPopup .closePopup:hover {
	background-position: 0 100%;
}
.favoritesPopup .listsList {
	margin: 0 0 10px;
}
.favoritesPopup .listsList li {
	display: block;
	margin: 10px 0 0;
}
.favoritesPopup .listsList li:first-child {
	margin: 0 20px 0 0;
}
.favoritesPopup .listsList label {
	white-space: nowrap;
}
.favoritesPopup .listsList em {
	font-style: normal;
}
.favoritesPopup .listsList label em {
	display: inline-block;
	font-size: 13px;
	margin-right: 5px;
	text-overflow: ellipsis;
	vertical-align: text-bottom;
	max-width: 460px;
}
.favoritesPopup .listsList label input {
	margin: 0 0 1px;
}
.favoritesPopup .listsList label .badgeMarker {
	vertical-align: text-bottom;
}
.favoritesPopup .listsList .listName {
	font-size: 13px;
	width: 135px;
}
.favoritesPopup .listsList .pseudoLink {
	font-size: 11px;
}
.favoritesPopup h4 {
	background-image: resolve('interface/star_grey.png');
	min-height: height('interface/star_grey.png');
	background-position: -1px 0;
	background-repeat: no-repeat;
	cursor: default;
	font-weight: normal;
	margin: 0 0 12px;
	padding: 0 0 0 15px;
}
.favoritesPopup .listsInteraction {
	height: 32px;
}
.favoritesPopup .blank {
	text-align: center;
}
.favoritesPopup .blank h4 {
	text-align: left;
}
.favoritesPopup .blank .description {
	color: #777;
	font-size: 15px;
	margin-bottom: 15px;
}



/* 4.40. PAGE SPECIFIC - FURNITURE PLACEMENT DESIGN-PROJECT
------------------------------------------------------------------------------*/

.designProjectsInfo .sendRequest {
	cursor: pointer;
}

.designProjectsInfo .sendRequest:hover {
	text-decoration: none;
}

.designProjectsExamples {
	background: $BG;
	margin: 8px -30px 25px;
	padding: 25px 30px 30px;
}

.designProjectsExamples h2 {
	font-size: 18px;
	font-weight: normal;
}

.designProjectsExamplesList {
	list-style-type: none;
	margin: 20px 0 0 -10px;
}

.designProjectsExamplesList li {
	display: inline-block;
	margin: 0 0 0 10px;
}

.designProjectsExamplesForm {
	margin: 30px 0 0;
	width: $FORM_WIDTH;
}

.designProjectsExamplesForm fieldset {
	margin: 30px 0 0;
}

.designProjectsExamplesForm fieldset:first-child {
	margin: 0
}

.designProjectsExamplesForm ul{
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.designProjectsExamplesForm li {
	margin: 5px 0 0;
}

.designProjectsExamplesForm li:first-child {
	margin: 0;
}

.designProjectsExamplesForm .caption,
.designProjectsExamplesForm .explanationNote {
	display: inline-block;
	margin-right: 10px;
	text-align: right;
	width: 265px;
}

.designProjectsExamplesForm .caption {
	font-size: 17px;
}

.designProjectsExamplesForm input,
.designProjectsExamplesForm textarea {
	width: 350px;
}

.designProjectsExamplesForm .explanationNote {
	color: #717171;
}

.designProjectsExamplesForm .btnGreen {
	font-size: 15px;
	font-weight: bold;
	height: 36px;
}

.designProjectsExamplesForm .errorNotice {
	margin-left: 275px;
}



/* 4.41. PAGE SPECIFIC - BUSINESSCLASS
------------------------------------------------------------------------------*/


.BusinessClass {
	margin-left: -20px;
	margin-top: -25px;
}

.BusinessClass__box {
	box-sizing: border-box;
	display: inline-block;
	padding: 25px 0 0 20px;
	vertical-align: top;
	width: 33.3333333%;
}

.BusinessClass__item {
	border: 1px solid $B_ACTION;
	border-radius: 2px;
}

.BusinessClass__logoLink {
	display: block;
}

.BusinessClass__logoLink:hover + .BusinessClass__description .BusinessClass__name {
	color: $BRAND__A;
	text-decoration: underline;
}

.BusinessClass__logo {
	display: block;
	height: 50px;
	margin: 15px auto;
}

.BusinessClass__description {
	background: $BLOCK_BG;
	box-sizing: border-box;
	height: 215px;
	padding: 13px 15px 0;
}

.BusinessClass__name {
	display: inline-block;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: .05em;
	margin-bottom: 5px;
	text-transform: uppercase;
}

.BusinessClass__text {
	line-height: 21px;
	margin: 0;
	padding: 0;
}



/* 4.42. PAGE SPECIFIC - FURNITURE LIST
------------------------------------------------------------------------------*/

.RubricDescription {
	display: table;
	font-size: 13px;
	width: 100%;

	.Rubric--furniture & {
		margin-bottom: 35px;
	}
}

.Rubric__box {
	box-sizing: border-box;
	display: table-cell;
	vertical-align: top;
}

.Rubric__box + .Rubric__box {
	padding-left: 30px;
}

.Rubric__box--photoWide {
	width: 52.8%;
}

.Rubric__box--photoWide .ProductPhotoThumbs {
	margin-left: -10px;
}

.Rubric__box--photoWide .ProductPhotoThumb {
	margin-left: 10px;
}

.Rubric__box--photoWide .ProductPhotoThumb__image {
	height: 65px;
	width: 88px;
}

.Complectation {
	display: table;
	width: 100%;
}

.Complectation__row {
	display: table-row;
}

.Complectation__box {
	display: table-cell;
	border-bottom: 1px solid $B_ACTION;
	padding-bottom: 8px;
	padding-top: 8px;
}

.Complectation__box + .Complectation__box {
	padding-left: 16px;
}

.Complectation__row--header {
	font-size: 15px;
	font-weight: bold;
}

.Complectation__box--header .Complectation__box {
	padding-top: 0;
}

.Complectation__box--price {
	text-align: right;
}

.Complectation__link {
	color: $C_ACTION;
	padding-top: 5px;
}

.RubricDescription__info {
	margin-bottom: 8px;
}

.Complectation {
	margin-bottom: 22px;
}

.RubricDescription__info > table {
	width: 100%;
}

.RubricList__header {
	font-size: 20px;
	margin-bottom: 22px;
}

.Complectation .code {
	background: none;
	font-size: 13px;
	font-weight: normal;
	padding: 2px 4px;
	margin: -2px -4px;
}

.Page.code--is-noticeable .Complectation .code {
	background-color: #ffeab1;
	font-size: 13px;
}

@media screen and (max-width: 1105px) {
	.Rubric__box--photoWide {
		width: 390px;
	}
}



/* 4.43. PAGE SPECIFIC - SIDE EVENT
------------------------------------------------------------------------------*/

.SideEvent {
	position: fixed;
	z-index: 800;

	&__close {
		top: 3px;
		opacity: 0;
		visibility: hidden;
		transition: all .3s cubic-bezier(0.42, 0, 0.08, 1);
	}
}



/* 4.43.1. PAGE SPECIFIC - SIDE EVENT - MORNING
------------------------------------------------------------------------------*/

.SideEvent--morning {
	top: 144px;
	right: 0;

	background: #f7f4f1;
	border-radius: 2px;
	box-sizing: border-box;
	margin-right: 25px;
	width: 170px;
	z-index: 800;

	.SideEvent__illustration {
		position: relative;
		padding-top: 85px;
		user-select: none;
	}

	.SideEvent__picture {
		position: absolute;

		&--coffee {
			background-image: url('/img/brand/coffee_cup_big.png?v=20012017');
			height: 110px;
			width: 111px;
			left: 0;
			top: -21px;
		}

		&--cookie_1 {
			background-image: url('/img/brand/cookie_1.png?v=20012017');
			height: 38px;
			width: 36px;
			left: 75px;
			top: 42px;
			opacity: 1;
			visibility: visible;
		}

		&--cookie_2 {
			background-image: url('/img/brand/cookie_2.png?v=20012017');
			height: 42px;
			width: 43px;
			left: 96px;
			top: 12px;
			opacity: 1;
			visibility: visible;
		}
	}

	.SideEvent__content {
		padding: 0 16px 18px;
		opacity: 1;
		visibility: visible;
		overflow: hidden;
		white-space: nowrap;
		vertical-align: top;
	}

	.SideEvent__header {
		color: $BRAND;
		font-size: 15px;
		font-weight: bold;
		margin-bottom: 3px;
	}

	.SideEvent__anounce {
		line-height: 18px;
		margin-bottom: 10px;
	}

	.SideEvent__vertical {
		cursor: pointer;
		display: block;
		padding: 0;
		opacity: 0;
		visibility: hidden;
		position: relative;
		z-index: 10;

		&:hover .SideEvent__verticalText {
			background-image: url('/img/brand/morning_text_hover.png?v=20012017');
		}
	}

	.SideEvent__verticalText {
		background-image: url('/img/brand/morning_text.png?v=20012017');
		display: block;
		height: 0;
		width: 0;
	}


	&.SideEvent--closed {
		margin: 0;
		width: 25px;

		.SideEvent__vertical {
			padding: 40px 0 16px 6px;
			opacity: 1;
			visibility: visible;
		}

		.SideEvent__verticalText {
			height: 116px;
			width: 13px;
		}

		.SideEvent__picture--coffee {
			animation: morning-jump .5s cubic-bezier(0.42, 0, 0.08, 1);
			background-size: contain;
			top: -9px;
			left: -3px;
			height: 48px;
			width: 48px;

			.ielte8 & {
				display: none;
			}
		}
		.SideEvent__picture--cookie_1,
		.SideEvent__picture--cookie_2 {
			visibility: hidden;
		}
		.SideEvent__picture--cookie_1 {
			left: 120px;
		}
		.SideEvent__picture--cookie_2 {
			left: 180px;
		}
		.SideEvent__illustration {
			padding-top: 0;
		}

		.SideEvent__content,
		.SideEvent__close {
			height: 0;
			width: 0;
			opacity: 0;
			visibility: hidden;
			padding: 0;
		}
	}

	&.SideEvent--narrow {
		&.SideEvent--morning {
			box-shadow: 0 2px 15px rgba(0,0,0,.3);
		}

		&.SideEvent--closed {
			box-shadow: none;
		}

		.SideEvent__close {
			opacity: 1;
			visibility: visible;
		}
	}

	&.SideEvent--wide {
		&.SideEvent--morning {
			margin-right: 0;
		}
	}

	&.SideEvent--thin {
		&.SideEvent--morning,
		.SideEvent__vertical,
		.SideEvent__verticalText,
		.SideEvent__picture--coffee,
		.SideEvent__illustration,
		.SideEvent__content {
			transition: all .3s cubic-bezier(0.42, 0, 0.08, 1);
		}

		.SideEvent__picture--cookie_1,
		.SideEvent__picture--cookie_2 {
			transition: all .5s cubic-bezier(0.42, 0, 0.08, 1) .1s;
				z-index: 12;
		}
	}
}


@keyframes morning-jump {
	0% {
		left: -3px;
	}
	50% {
		left: 3px;
	}
	75% {
		left: -4px;
	}
	100% {
		left: -3px;
	}
}



/* 4.44. PAGE SPECIFIC - LOGO EVENT
------------------------------------------------------------------------------*/

/* 4.44.1. PAGE SPECIFIC - LOGO EVENT - PAPER
------------------------------------------------------------------------------*/

.LogoEvent--paper {
	display: inline-block;
	vertical-align: top;
	padding-left: 0px;
	position: relative;
	z-index: 1;

	.LogoEvent__content {
		background-color: $BTN_BG;
		border-radius: 66px;
		display: inline-block;
		position: relative;
		vertical-align: top;
		height: 0;
		width: 0;
		opacity: 0;
		visibility: hidden;
		transition: all .3s cubic-bezier(0.42, 0, 0.08, 1);
		margin: -2px 0;

		&:hover {
			background-color: $BRAND__A;
			transition-duration: .1s;

			.LogoEvent__picture {
				transition-duration: .2s;

				&--omOne {
					transform: translateY(-3px);
				}

				&--omBox {
					transform: translateY(-1px);
				}
			}
		}

		.ielte8 & {
			background-color: transparent;

			&:hover {
				background-color: $BRAND__A;
			}
		}
	}

	.LogoEvent__picture {
		position: absolute;
		visibility: hidden;
		transition: all .3s cubic-bezier(0.42, 0, 0.08, 1) 0s;

		&--omOne {
			background-image: url('/img/brand/paper_om_one.png?v=20012017');
			height: 51px;
			width: 35px;
			top: 11px;
			left: -50px;
		}

		&--omBox {
			background-image: url('/img/brand/paper_om_box.png?v=20012017');
			height: 54px;
			width: 59px;
			top: 5px;
			left: -50px;
		}
	}


	&.LogoEvent--active {
		padding-left: 20px;

		.LogoEvent__content {
			height: 66px;
			width: 66px;
			opacity: 1;
			visibility: visible;
		}

		.LogoEvent__picture--omOne {
			top: 11px;
			left: 0;
			z-index: 5;
			opacity: 1;
			visibility: visible;
			transition: all .4s cubic-bezier(0.42, 0, 0.08, 1) .1s;
		}

		.LogoEvent__picture--omBox {
			top: 5px;
			left: 9px;
			opacity: 1;
			visibility: visible;
			transition: all .4s cubic-bezier(0.42, 0, 0.08, 1) .15s;
		}
	}


	&.LogoEvent--attract {
		.LogoEvent__picture--omOne {
			animation: paperJump-omOne .8s cubic-bezier(0.42, 0, 0.08, 1) 0.07s;
		}

		.LogoEvent__picture--omBox {
			animation: paperJump-omBox .8s cubic-bezier(0.42, 0, 0.08, 1) 0s;
		}
	}
}


@keyframes paperJump-omOne {
	0% {
		transform: translateY(0);
	}
	25% {
		transform: translateY(-7px);
	}
	45% {
		transform: translateY(2px);
	}
	70% {
		transform: translateY(-4px);
	}
	85% {
		transform: translateY(1px);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes paperJump-omBox {
	0% {
		transform: translateY(0);
	}
	25% {
		transform: translateY(-7px);
	}
	45% {
		transform: translateY(2px);
	}
	70% {
		transform: translateY(-5px);
	}
	85% {
		transform: translateY(1px);
	}
	100% {
		transform: translateY(0);
	}
}



/* INVITE TENDER
------------------------------------------------------------------------------*/

.InviteTender {
	display: inline-block;

	&--main {
		padding: .6em 18px;

		&:before {
			content: '';
			display: inline-block;
			background-image: resolve('interface/tender.png');
			width: width('interface/tender.png');
			height: height('interface/tender.png');
			vertical-align: top;
			margin: -1.35em 17px 0 0;
		}

		.Page__nav & {
			width: 100%;
		}
	}
}

.fancyBoxInviteTender {
	width: 450px;

	h1 {
		margin-bottom: 16px;
	}

	.Form__field.inputFull .Form__title {
		margin-bottom: .5em;
	}

	.DatePickerBox {
		display: inline-block;

		& + .DatePickerBox {
			margin-left: 15px;
		}
	}
}



/* 4.46. ADDED TO CART MODAL
------------------------------------------------------------------------------*/

$AddedToCartBoxPaddingX: 25px;
$AddedToCartIconPaddingX: 45px;

.FancyModal--addedToCart {
	padding: 0;
	width: 875px;

	.AddedToCart__box {
		padding: 0 25px 25px;

		&--controls {
			font-size: 15px;
			padding: 0;

			.AddedToCart--rich& {
				background-color: $BG;
			}

			.AddedToCartControls {
				border-bottom: 1px solid #e2ddd9;
				display: table;
				width: 100%;

				&__box {
					display: table-cell;
					vertical-align: middle;
					padding: $AddedToCartBoxPaddingX 0;

					&:last-child {
						padding-right: $AddedToCartBoxPaddingX;
					}

					&--cartInfo {
						background-image: resolve('interface/succes-cart.png');
						background-position: $AddedToCartBoxPaddingX 50%;
						background-repeat: no-repeat;
						border-right: 1px solid $B_ACTION;
						padding-left: calc($AddedToCartBoxPaddingX + $AddedToCartIconPaddingX);
					}

					&--buttons {
						padding-left: 20px;

						.btn {
							font-size: 14px;
							font-weight: bold;
							height: 42px;
							padding: 1em 1.6em;
							line-height: 1em;
						}

						.btn + .btn {
							margin-left: 20px;
						}
					}

					&--notShow {
						font-size: 12px;
						max-width: 150px;
					}
				}

				&__line {
					&--title {
						font-weight: bold;
					}

					& + .AddedToCartControls__line {
						margin-top: .3em;
					}
				}
			}

			.AddedToCart__hint {
				font-size: 12px;
				font-style: italic;
				padding: 10px 0;
				padding-left: calc($AddedToCartBoxPaddingX + $AddedToCartIconPaddingX);
			}
		}

		&--notInCatalog {
			background-image: resolve('interface/attention_brand_a.png');
			background-repeat: no-repeat;
			background-position: 50px 11px;
			background-color: $ATTENTION;
			font-size: 12px;
			padding-top: 10px;
			padding-bottom: 10px;
			padding-left: calc($AddedToCartBoxPaddingX + $AddedToCartIconPaddingX);
		}

		&--showcase {
			padding-top: 15px;
		}
	}

	.AddedToCart__orderCount,
	.AddedToCart__orderSumm {
		font-weight: bold;
	}

	.AddedToCart__notification {
		display: inline-block;
		font-size: 12px;
		margin-top: 20px;
	}
}



/* 4.47. BRAND RUBRICS
------------------------------------------------------------------------------*/

$PaddingBrandBox: 20px 25px;

.BrandRubrics {
	margin: -25px 0 0 -5.25%;
	
	@media screen and (max-width: 1140px) {
		margin-left: -11.1%;
	}
}

.BrandRubric {
	display: inline-block;
	vertical-align: top;
	width: 20%;
	box-sizing: border-box;
	margin: 25px 0 0 5%;

	@media screen and (max-width: 1140px) {
		width: 23.3%;
		margin-left: 10%;
	}

	&__box {
		display: block;
		border-radius: 2px;
		box-sizing: border-box;
		padding: $PaddingBrandBox;
		position: relative;
	}

	&:hover {
		.BrandRubric__box {
			background: #f6f3f0;
			box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
		}

		.SubBrandRubric {
			display: block;
			opacity: 1;
			box-shadow: 10px 12px 10px -10px rgba(0, 0, 0, 0.3),
				-10px 12px 10px -10px rgba(0, 0, 0, 0.3);
		}
	}

	&__img {
		width: 200px;
		height: 200px;
	}

	&__title {
		display: block;
		font-size: 15px;
		font-weight: bold;
		padding-top: 20px;
	}
}

.SubBrandRubric {
	display: none;
	background: #f6f3f0;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	box-sizing: border-box;
	padding: $PaddingBrandBox;
	position: absolute;
	left: 0;
	z-index: 5;
	opacity: 0;
	width: 100%;

	&__item + &__item {
		margin-top: 8px;
	}

	&__link {
		color: #000;
	}
}



/* 5. PRINT STYLES
------------------------------------------------------------------------------*/

@media print {

	/* 5.1. PRINT STYLES — CART
	------------------------------------------------------------------------------*/

	body {
		padding: 0 0 0 20px;
		min-width: 0;
	}
	#cartPrintVersion {
		background: #FFF;
		font-family: Arial,Verdana;
		font-size: 12px;
	}

	#cartPrintVersion p {
		font-size: 12px;
		margin: 1.3em 0 1.3em 5px
	}

	#cartPrintVersion p.header {
		font-size: 18px;
		margin: 0 0 25px 10px;
	}

	#cartPrintVersion #summ {
		font-size: 14px;
		font-weight: bold;
		margin: 0;
		overflow: hidden;
	}
	#cartPrintVersion #summ dt, #cartPrintVersion #summ dd {
		float: left;
		font-size: 14px;
		line-height: 1.3em;
	}
	#cartPrintVersion #summ dt.delivery, #cartPrintVersion #summ dd.delivery {
		margin: 5px 5px 0 0;
	}
	#cartPrintVersion #summ dt {
		clear: left;
		width: 470px;
	}
	#cartPrintVersion #summ dd {
		font-weight: bold;
		margin: 0;
		text-align: right;
		width: 150px;
	}

	#cartPrintVersion p.agreed span {
		border-top: 1px solid #000000;
		font-size: 10px;
		margin: 0 10px;
		padding: 0 40px;
		position: relative;
		top: 12px;
	}

	#cartPrintVersion p.agreed span.fio {
		padding: 0 110px;
	}

	#cartPrintVersion h3 {
		font-size: 16px;
		font-weight: bold;
		margin: 5px 0 0 5px;
	}

	#cartPrintVersion img.logo {
		display: inline;
		float: left;
		margin: 0 60px 0 0;
		position: relative;
		top: 5px;
	}

	#cartPrintVersion p span {
		font-size: 12px;
		margin: 0 0 10px 0;
	}

	#cartPrintVersion dl {
		line-height: 1.3em;
		margin: 0 0 1.3em 10px;
	}

	#cartPrintVersion dt {
		float: left;
		font-weight: bold;
		margin: 0 5px 0 0;
		text-align: right;
		width: auto;
	}

	#cartPrintVersion dd {
		font-weight: normal;
		margin: 0 5px 0 0;
		width: auto;
	}

	#cartPrintVersion table {
		border: 1px solid #000000;
		border-collapse: collapse;
		margin: 0 0 0 10px;
		width: 630px;
	}

	#cartPrintVersion table thead {
		border: 1px solid #000000;
		border-collapse: none !important;
		margin: 3px;
		padding: 3px;
	}

	#cartPrintVersion table th,
	#cartPrintVersion table td {
		background: #ffffff;
		border: 1px solid #000000;
		border-collapse: collapse;
		padding: 3px;
		text-align: center;
		vertical-align: top;
	}

	#cartPrintVersion table th {
		font-weight: bold;
	}

	#cartPrintVersion td.itemName {
		text-align: left;
	}

	#cartPrintVersion td.itemName ul {
		display: block;
		font-size: 11px;
		margin: 0 0 0 10px;
	}
	#cartPrintVersion td.itemName li {
		display: block;
		margin: 8px 0 0;
	}
	#cartPrintVersion td.itemName li:first-child {
		margin-top: 0;
	}
	#cartPrintVersion td.itemName strong {
		display: block;
		font-weight: normal;
		margin: 12px 0 5px;
}

	#cartPrintVersion #summ {
		font-size: 16px;
		font-weight: bold;
		margin: 0;
		padding-top: 15px;
		text-align: right;
		width: 630px;
	}

	#cartPrintVersion #summ span {
		color: #555;
		font-size: 16px;
	}

	#cartPrintVersion button {
		display: none;
	}



	/* 5.2. PRINT STYLES — ITEMCARD
	------------------------------------------------------------------------------*/

	.Page--itemCard .SideEvent,
	.Page--itemCard .Page__header,
	.Page--itemCard .Page__footer,
	.Page--itemCard .Page__main.mainContentWide .breadcrums,
	.Page--itemCard .Page__nav,
	.Page--itemCard .ddMainInfoPanel,
	.Page--itemCard .breadcrums__wrapper,
	.Page--itemCard .promoPlace,
	.Page--itemCard .Feedback__panel,
	.Page--itemCard .itemInfoDetailsActions .actionsPreviewItem .preview,
	.Page--itemCard i.icon.actionTime,
	.Page--itemCard .itemInfoDetails .basket .textCounter,
	.Page--itemCard .itemInfoDetails .basket .textCounter + strong,
	.Page--itemCard .itemInfo .tabsContainer
	.Page--itemCard .itemInfoDetails .basket .btn,
	.Page--itemCard .itemInfoDetails .basketAlready,
	.Page--itemCard .ProductPhotoThumbs,
	.Page--itemCard .itemInfo .tabsContainer,
	.Page--itemCard .tabsContent div.analogues,
	.Page--itemCard .itemInfoDetails .itemInteract,
	.Page--itemCard .itemInfoDetails .priceOld,
	.Page--itemCard .relatedItemsList .priceOld,
	.Page--itemCard .deliveryNotyficationContainer,
	.Page--itemCard .needMore,
	.Page--itemCard .compareItems .promoIcon.bestseller,
	.Page--itemCard	.itemInfoDetails .itemPromoAction .promoIcon.bestseller,
	.Page--itemCard	.details .itemPromoAction .promoIcon.bestseller,
	.Page--itemCard	.grid .itemPromoAction .promoIcon.bestseller,
	.Page--itemCard .itemInfoPhotos .main .actionsBadgePic,
	.Page--itemCard #certificates,
	.Page--itemCard .tabsContent .certificates,
	.Page--itemCard .tabsContent .serviceInforation,
	.Page--itemCard .tabsContent .instructions,
	.Page--itemCard .tabsContent .reviews,
	.Page--itemCard .itemInfoDetails .info .collectionLink,
	.Page--itemCard .itemInfoDetails .itemMountingLink,
	.Page--itemCard .itemInfoDetails .info .File,
	.Page--itemCard .itemInfoDetails .promoIconWrapper,
	.Page--itemCard .itemInfoDetails .basket .furnitureKitNotice,
	.Page--itemCard .itemPhotoAttention .pseudoLink,
	.Page--itemCard .itemInfoLinks,
	.Page--itemCard .itemInfo > .tabsContent > .consumables,
	.Page--itemCard .itemInfoPhotos > .itemPhotoAttention,
	.Page--itemCard .ProductBrand,
	.Page--itemCard .fancyAddToCartContainer,
	.Page--itemCard .TipTip,
	.Page--itemCard .itemInfoPhotos .main #photosListLink:after
	{
		display: none !important;
	}

	.Page--itemCard {
		background: #fff !important;
	}

	.Page--itemCard .Page__main {
		margin: 0;
		padding-top: 0;
	}

	.Page--itemCard .Page__wrapper {
		position: absolute;
		color: #000;
		font-family: Arial, Verdana;
		font-size: 12px;
		margin-right: auto;
		margin-left: auto;
		width: 670px;
		padding: 0;
	}

	.Page--itemCard .itemInfo {
		margin: 0;
	}

	.Page--itemCard .printLink {
		display: none;
	}

	.Page--itemCard .itemInfoDetails h1,
	.Page--itemCard .itemInfoDetails .Product__name,
	.Page--itemCard .itemInfoDetails .specialBar {
		margin-left: 170px;
	}

	.Page--itemCard .tabsContent div.info {
		display: block !important;
	}

	.Page--itemCard .itemInfoDetails h1 {
		font-size: 18px;
		font-weight: bold;
		margin-top: -6px;
	}

	.Page--itemCard .itemInfoDetails .specialBar .code {
		color: #000;
		font-size: 12px;
		margin: 7px 0 0;
	}

	.Page--itemCard .itemInfoDetails .specialBar .code,
	.Page--itemCard .relatedItems .itemCode {
		box-shadow: none;
		padding: 0;
		transform: none;
	}

	.Page--itemCard .itemInfoDetails {
		background: none;
		margin: 0;
	}

	.Page--itemCard .itemInfoDetails .info {
		background: none;
		margin: 0;
	}

	.Page--itemCard .itemInfoDetails .Product__priceWrapper {
		background: none;
		float: left;
		margin-right: 1em;
	}

	.Page--itemCard .itemInfoDetails .additional {
		text-align: right;
	}

	.Page--itemCard .itemInfoDetails .order:before {
		position: absolute;
		top: 0; bottom: 0;
		left: 0; right: 0;
		content: '';
		border: solid #efefef;
		border-width: 1000px 0 0;
		z-index: -1;
	}
	.Page--itemCard .itemInfoDetails .order {
		box-sizing: border-box;
		clear: both;
		float: right;
		overflow: hidden;
		padding: 14px 18px;
		position: relative;
		width: 325px;
	}

	.Page--itemCard .itemInfoDetails .inStock,
	.Page--itemCard .itemInfoDetails .pzk,
	.Page--itemCard .itemInfoDetails .outOfStock {
		background: none;
		color: #000;
/*		font-size: 10px;
		left: 18px;
		position: absolute;
		top: 36px;*/
	}

	.Page--itemCard .itemInfoDetails .basket > em:first-child {
		background: none;
		margin-top: 0;
	}

	.Page--itemCard .itemInfoDetails .basket > em {
		background: none;
		color: #000;
		margin: 4px 0 0 30px;
		float: right;
	}

	.Page--itemCard .itemInfoDetails .basket {
		background: none;
		margin: 0;
		padding: 0;
	}

	.Page--itemCard .itemInfoDetails .basket .specialOffer .specialOffer__item {
		display: inline-block;
		margin-right: 1em;
	}

	.Page--itemCard .itemInfoDetails .basket .specialOffer__header {
		background: none;
		color: #000;
		clear: both;
		font-size: 10px;
		margin: 0;
		padding: 5px 0 0;
	}

	.Page--itemCard .itemInfoDetails .basketSpecialOffer {
		background: none;
	}

	.Page--itemCard .itemInfoPhotos .main,
	.Page--itemCard .itemInfoPhotos .main img,
	.Page--itemCard .itemInfoPhotos {
		height: 145px;
		width: 145px;
	}

	.Page--itemCard .itemInfoPhotos {
		border: 1px solid #000;
		margin-bottom: 35px;
	}

	.Page--itemCard .itemInfoDetails .info p {
		font-size: 12px;
		line-height: 1.4em;
	}

	.Page--itemCard .tabsContent .info div p {
		line-height: 1.4em;
		margin: 0;
	}

	.Page--itemCard .itemInfoDetailsActions {
		display: none;
	}

	.Page--itemCard .tabsContent div.info {
		padding: 0;
	}

	.Page--itemCard .itemInfoDetails .info {
		float: left;
		width: 325px;
	}

	.Page--itemCard .itemInfoDetails .retailLink {
		color: #000;
	}

	.Page--itemCard .itemInfoDetails .info a {
		border-width: 0;
		color: #000;
	}

	.Page--itemCard .contentWrapper .relatedItems {
		height: auto;
		margin: 0;
		position: relative;
	}

	.Page--itemCard .relatedItemsList:before,
	.Page--itemCard .relatedItemsList:after {
		display: none;
	}

	.Page--itemCard .relatedItems {
		margin: 0;
		padding: 0;
		width: auto;
		page-break-before: auto;

		-webkit-column-break-inside: avoid;
		          page-break-inside: avoid;
		               break-inside: avoid;
	}

	.Page--itemCard .relatedItems h2 {
		font-size: 13px;
		color: #000;
		font-weight: bold;
		margin: 20px 0 7px;
	}

	.Page--itemCard .relatedItemsControls {
		display: none !important;
	}

	.Page--itemCard .relatedItemsList {
		border: 0 none;
		height: auto !important;
		white-space: nowrap;
		overflow: hidden;
		page-break-before: auto;
	}

	.Page--itemCard .relatedItemsList > li:first-child {
		margin: 0;
	}

	.Page--itemCard .relatedItemsList > li {
		border: 0 none;
		display: inline-block;
		margin-left: 20px;
		padding: 0;
		vertical-align: top;
		width: 150px;
		white-space: normal;
	}

	.Page--itemCard .relatedItemsList .itemDescription {
		margin-left: 0;
	}

	.Page--itemCard .relatedItemsList .thumb {
		float: none;
	}

	.Page--itemCard .relatedItemsList .thumb img {
		height: 73px;
		width: 73px;
	}

	.Page--itemCard .relatedItemsList .itemCode {
		background-color: transparent;
		color: #000;
		display: block;
		font-size: 12px;
		margin-top: 5px;
		padding: 0;
	}

	.Page--itemCard .relatedItemsList .itemName {
		font-size: 12px;
		line-height: 1.4em;
		margin: 5px 0 0;
	}

	.Page--itemCard .relatedItemsList .price {
		margin: 5px 0 0;
	}

	.Page--itemCard .relatedItemsList .basket {
		display: none;
	}

	.Page--itemCard .tabsContent .info .specTitle {
		display: block;
		margin: 20px 0 7px;
		text-shadow: none;
	}

	.Page--itemCard .tabsContent .info ul .specTitle {
		margin-left: 1px;
	}

	.Page--itemCard .tabsContent .info .specTitle:before {
		content: '';
	}

	.Page--itemCard .itemInfo .tabsList {
		display: none;
	}

	.Page--itemCard .itemInfo .tabsContent {
		border-width: 0;
		padding: 0;
	}

	.Page--itemCard .tabsContent .infoFeatures {
		display: block;
		width: auto;
	}
	.Page--itemCard .tabsContent .infoDescription {
		display: none;
	}

	.Page--itemCard .tabsContent .infoFeatures {
		margin-left: -5px;
	}
	.Page--itemCard .tabsContent .infoFeatures a {
		color: #000;
	}
	.Page--itemCard .tabsContent .infoFeatures a:after {
		content: ": " attr(href);
	}

	.Page--itemCard .tabsContent div.info li {
		display: inline-block;
		position: relative;
		margin-left: 5px;
	}

	.Page--itemCard .tabsContent div.info li:before {
		content: "\2022";
		display: inline-block;
		margin-right: 3px;
	}

	.Page--itemCard .specialBar .EAIST__status {
		color: #000;
		display: block;
		margin-top: 5px;
	}

	.Page--itemCard .EAIST__status .icon.EAIST {
		display: none;
	}

	.Page--itemCard .EAIST__status .EAIST__tip {
		display: none;
	}

	.Page--itemCard .EAIST__code,
	.Page--itemCard .EAIST__check {
		font-weight: normal;
	}

	.Page--itemCard .EAIST__code:after,
	.EAIST__check:after {
		content: "\20\28\415\410\418\421\422\29";
	}

	.Page--itemCard #bx-panel {
		display: none !important;
	}

	.Page--itemCard .actionsBadge--productSpecial .actionsBadgeTrigger {
		border-bottom-width: 0;
	}

	.Page--itemCard .ProductSpecial {
		clear: both;

		.ProductSpecial__content {
			display: block;
		}

		.ProductSpecial__item {
			display: inline-block;
		}
		.ProductSpecial__item--active {
			background-color: transparent;
			color: #000;
		}

		.ProductSpecial__box {
			display: inline-block;
		}

		.ProductSpecial__item:not(:first-child) + .ProductSpecial__item .ProductSpecial__box--count {
			text-transform: lowercase;
		}

		.ProductSpecial__condition,
		.ProductSpecial__item--head {
			display: none;
		}
	}
}



.compareItems .listItemBuy .priceDiscount {
	background-color: #fcf9d1;
	padding: 5px 8px;
	margin-bottom: 4px;
}

.compareItems .listItemBuy .price .priceDiscountDescription {
	color: #c00;
	font-size: 11px;
}

.compareItems .listItemBuy .price .priceDiscountDescription strong {
	display: block;
	font-size: 14px;
	font-weight: bold;
	margin: 4px 0 0 0;
}

.compareItems .listItemBuy .Product__price--initial .Price {
	font-size: 15px;
}

.compareItems .listItemBuy .price .priceOld {
	color: #777;
	font-size: 12px;
	text-decoration: line-through;
}

.compareItems .listItemBuy .price .priceOld strong {
	font-size: 16px;
	font-weight: normal;
}



/*
ANKETA
------------------------------------------------------------------------------*/

.bonusForm2012 h2 {
	font-size: 29px;
	margin: 0 0 30px;
}

.bonusForm2012 .formBlock dt {
	margin: 10px 0 5px;
}

.bonusForm2012 .formBlock dt:first-child {
	margin: 0 0 5px;
}

.bonusForm2012 .formBlock {
	margin: 0 0 20px;
}

.bonusForm2012 .formBlock dd dt {
	font-style: italic;
}

.bonusForm2012 .formBlock > dt {
	font-weight: bold;
}

.bonusForm2012 .formBlock .inputtext {
	width: 250px;
}

.bonusForm2012 .formBlock dd > label {
	display: block;
	margin: 5px 0 0;
}

.bonusForm2012 .formBlock dd > label:first-child {
	margin: 0;
}

.bonusForm2012 .formBlock textarea {
	height: 125px;
	width: 65%;
}

.bonusForm2012 .formBlock.warning dd {
	color: #E10000;
}



/* 6. HELPERS
------------------------------------------------------------------------------*/

.cl-l {	clear: left; }

/* работа с отображением элемента */
.d-n { display: none; }
.d-i { display: inline; }
.d-b { display: block; }
.d-i-b { display: inline-block; }

.d-n\! { display: none !important; }
.d-i\! { display: inline !important; }
.d-b\! { display: block !important; }
.d-i-b\! { display: inline-block !important; }

.d-t { display: table; }
.d-t-c { display: table-cell; }

.f-r { float: right; }
.f-l { float: left; }


/* отступы, кратные высоте строки в текстовых блоках */
.m-t-0 { margin-top: 0; }
.m-t-025 { margin-top: .375em; }
.m-t-05 { margin-top: .75em; }
.m-t-1 { margin-top: 1.5em; }
.m-t-15 { margin-top: 2.25em; }
.m-t-2 { margin-top: 3em; }

.m-r-0 { margin-right: 0; }
.m-r-space { margin-right: .4em; }
.m-r-word-s { margin-right: .8em; }
.m-r-word-m { margin-right: 1.2em; }
.m-r-word-l { margin-right: 2em; }

.m-b-0 { margin-bottom: 0; }
.m-b-025 { margin-bottom: .375em; }
.m-b-05 { margin-bottom: .75em; }
.m-b-1 { margin-bottom: 1.5em; }
.m-b-15 { margin-bottom: 2.25em; }
.m-b-2 { margin-bottom: 3em; }

.m-l-0 { margin-left: 0; }
.m-l-space { margin-left: .4em; }
.m-l-word-s { margin-left: .8em; }
.m-l-word-m { margin-left: 1.2em; }
.m-l-word-l { margin-left: 2em; }

.w-0 { width: 1px; }
.w-30 { width: 30%; }
.w-50 { width: 50%; }
.w-full { width: 100%; }

.v-a-t { vertical-align: top; }
.v-a-m { vertical-align: middle; }
.v-a-b { vertical-align: bottom; }

.v-a-m-true {
	margin-top: -.17em;
	vertical-align: middle;
}

.t-a-r { text-align: right; }
.t-a-c { text-align: center; }
.t-a-l { text-align: left; }

.w-s-nw { white-space: nowrap; }


/* размер шрифта */
.f-s-11 { font-size: 1.1em; }

.f-st-i { font-style: italic; }

.f-w-b { font-weight: bold; }

.c-error,
.c-red {
	color: red;
}
.c-info {
	color: $C_ADD;
}
.c-grey {
	color: $C_DISABLED;
}


@media print {

	/*body*/.fancyBoxPrintContentOnly #bx-panel {
		display: none !important;
	}
	/*body*/.fancyBoxPrintContentOnly * {
		display: none !important;
	}
	/*body*/.fancyBoxPrintContentOnly #fancybox-wrap,
	/*body*/.fancyBoxPrintContentOnly #fancybox-outer,
	/*body*/.fancyBoxPrintContentOnly #fancybox-content,
	/*body*/.fancyBoxPrintContentOnly #fancybox-content * {
		display: block !important;
	}
	/*body*/.fancyBoxPrintContentOnly #fancybox-wrap {
		position: static;
	}
}



/* 6. PAGINATOR in the ListItems
------------------------------------------------------------------------------*/

.listItemsContainer {

	.paginator li {
		display: table-cell;
		border-left: 1px solid #e9e4e0;
		vertical-align: middle;
		text-align: center;
		height: 40px;
		width: 38px;
	}

	.paginator a {
		padding: 12px 0;
		width: 100%;

		&.active {
			background-color: #e2ddd9;
		}

		&:hover {
			background-color: #f9f9f9;
			text-decoration: none;
		}
	}

	.listItemsPageControl .paginator {
		display: table;
	}

	.listItemsPageControl.bottom{
		background-color: #f2eeea;
		float: left;

		.info {
			padding: 12.5px 1em;
			margin: 0;
		}
	}
}

